import { useState } from "react";
import New from "./new/New";
import Reserve from "./new/Reserve";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink } from "../../lib/Navigation";
import { useLocalStorageState, useMount } from "ahooks";
import general from "../../general";
import Active from "../../lib/Active";
import useUrlState from "@ahooksjs/use-url-state";

// 新游页面内容
const router = [
  {
    id: "new",
    title: "新游首发",
    view: New,
  },
  {
    id: "reserve",
    title: "新游预约",
    view: Reserve,
  },
];

export default (props) => {
  const [query] = useUrlState({});
  const [banner, setBanner] = useLocalStorageState("new-banner", {
    defaultValue: [],
  });
  useMount(() => {
    general.axios
      .post("/getGameBanner")
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          console.log(status.error_desc);
          return;
        }
        setBanner(data.banner_list);
      })
      .catch((err) => console.log(err));
  });
  const [current, setCurrent] = useState(query.type || props.type || 0);

  return (
    <div className="index-new">
      {banner?.length > 0 ? (
        <>
          <div className="new-banner">
            <Swiper
              loop={true}
              autoplay={true}
              pagination={{ clickable: true }}
            >
              {banner?.map((item, index) => (
                <SwiperSlide key={index}>
                  <NavLink
                    to={
                      item.banner_type === "game"
                        ? `/game?id=${item.game_id}`
                        : item.url
                    }
                    user={item.banner_type !== "game"}
                    iframe={
                      item.banner_type === "game"
                        ? undefined
                        : { title: item.text }
                    }
                  >
                    <img src={item.img} alt="" />
                  </NavLink>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      ) : null}
      <div className="new-head width">
        {router.map((item, index) => (
          <div
            key={index}
            className={`item ${index == current ? "active" : ""}`}
            onClick={() => setCurrent(index)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="new-body">
        {router.map((item, index) => (
          <div
            key={index}
            className={`new-body-item ${index == current ? "active" : ""}`}
          >
            <Active active={props.active && index == current}>
              <item.view active={props.active && index == current} />
            </Active>
          </div>
        ))}
      </div>
    </div>
  );
};
