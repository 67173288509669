import List from "./List";
import {useEffect} from "react";

export default ({query, active, setTitle}) => {
  useEffect(() => {
    setTitle(query.title);
  }, [query]);
  return <div className="voucher-lateplay">
    <List active={active} lateplay={true}/>
  </div>;
};