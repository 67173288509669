import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Image from "../../../lib/Image";

SwiperCore.use([Pagination]);

export default (props) => {
  return (
    <Swiper
      style={{
        paddingBottom:
          props.type == 1 &&
          props.banners.length > 0 &&
          props.banners.length < 2
            ? "20px"
            : (props.type == 1 && props.banners.length === 0) ||
              (props.type == 3 && props.banners.length < 2)
            ? 0
            : "36px",
      }}
      autoplay={true}
      loop={true}
      pagination={{ clickable: true }}
    >
      {props.banners.map((banner) => (
        <SwiperSlide key={banner.game_id}>
          <Link to={"/game?id=" + banner.game_id} className="banner">
            <div className="img">
              <Image src={banner.img} />
            </div>
            <div className="entity">
              <div className="icon">
                <Image src={banner.icon} />
                {banner.discount && banner.discount < 1 ? (
                  <div
                    className="index-all-discount"
                    style={{ borderRadius: "0px 12px 0px 10px" }}
                  >
                    <span style={{ fontSize: "0.24rem", fontWeight: "bold" }}>
                      {banner.discount * 10}
                    </span>
                    <span style={{ fontSize: "0.2rem" }}>折</span>
                  </div>
                ) : null}
              </div>
              <div className="data">
                <div className="name">
                  {banner.game_name_main || banner.game_name}
                  {banner.game_name_branch ? (
                    <div className="branch">{banner.game_name_branch}</div>
                  ) : null}
                </div>
                <div className="desc">
                  {banner.version}
                  <div className="line"></div>
                  {banner.played_count && banner.played_count > 0 ? (
                    <div className="info" style={{ marginTop: 0 }}>
                      <div className="circle"></div>
                      {banner.played_count}w人在玩
                    </div>
                  ) : null}
                </div>
                <>
                  {(() => {
                    let len = banner.benefits?.split("+") || [];
                    return (
                      <div
                        className="info"
                        style={{
                          color:
                            len.length === 1
                              ? "#e99157"
                              : len.length === 2
                              ? "#85bc69"
                              : "#859dd1",
                        }}
                      >
                        {len.map((item, index) => (
                          <div key={index} className="tag">
                            {item}
                          </div>
                        ))}
                      </div>
                    );
                  })()}
                </>
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
