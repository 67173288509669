import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useThrottleEffect } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post(
        props.lateplay
          ? "/base/voucher/getLatePlayGames"
          : "/base/voucher/list",
        tmp
      )
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { list, paginated } = data;
        list = data.list.map((item) => ({ ...item, ...item.game_info }));
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("voucher " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $("html,body").scrollTop(0);
      load({ page: 1 });
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="voucher-list">
      {datas.map((data) => (
        <Link
          key={data.game_id}
          to={"/game?id=" + data.game_id + "&voucher=1"}
          className="item"
        >
          <div className="icon">
            <Image src={data.game_image.thumb} />
          </div>
          <div className="info">
            <div className="name">{data.game_name_main || data.game_name}</div>
            <div className="desc">
              {data.game_name_branch ? (
                <div className="branch">{data.game_name_branch}</div>
              ) : null}
              {data.game_classify_type} ·{data.gama_size.ios_size}
            </div>
            <div className="tags">
              {data.game_desc?.split("+").map((item, index) => (
                <div key={index} className="tag">
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="operate">
            <div className="count">￥{data.total_amount * 1}</div>
            <div className="amount">共{data.count_amount}张券</div>
            <div className="btn">获取</div>
          </div>
        </Link>
      ))}
      {datas.length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
