import Image from "../../lib/Image";
import { NavLink } from "../../lib/Navigation";
import { useEffect, useState } from "react";
import { useRequest, useUpdate } from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

let alerted = false;

export default (props) => {
  const update = useUpdate();
  const [app] = useActiveState("app");
  const [user] = useActiveState("user");
  const [datas, setDatas] = useState([
    /*{
      id: '_1',
      route: '/screen-welfare',
      thumb: '/resources/index/float-first.png',
      img: '/resources/index/activity-first.png',
    },
    {
      id: '_2',
      title: '新人见面礼',
      route: 'https://sys.9917.cn/home/NewPerson2',
      thumb: '/resources/index/float-648.png',
      img: '/resources/index/alert-9917.png',
      alert: null,
    },*/
  ]);
  const [data, setData] = useState();
  useRequest(async () => {
    return general.axios
      .post("/base/activity/getActivityNew")
      .then(({ data: resData }) => {
        console.log("活动--------", resData);
        let { status, data, activity2 } = resData;
        if (status.succeed) {
          setDatas((datas) => {
            return [data, activity2]
              .map((data) => ({
                ...data,
                title: data?.name || data?.text,
                route: data?.url,
              }))
              .map((data, index) => (data.id ? data : datas[index]));
          });
        }
      });
  });

  const [tikShow] = useActiveState("tikShow");
  useEffect(() => {
    for (let data of datas.filter((data) => data)) {
      if (data.alert === undefined) {
        data.alert = data.cycle
          ? null
          : dayjs(window.localStorage.getItem(`act_${data.id}`) * 1).format(
              "YYYY-MM-DD"
            ) !== dayjs().format("YYYY-MM-DD");
      }
      if (data.id === "_1" && user.is_finish_newtask) {
        data.able = false;
      }
      if (data.id === "_2" && !data.brand && app.brand) {
        data.brand = app.brand;
        if (app.brand === "btwan") {
          data.thumb = `/resources/index/alert-${app.brand}.png`;
        }
        data.img = `/resources/index/alert-${app.brand}.png`;
      }
    }
    if (
      window.navigator.standalone ||
      !(
        mobileDetect.is("iPhone") ||
        mobileDetect.is("iPad") ||
        navigator.userAgent === "app"
      ) ||
      window.location.hostname === "localhost" ||
      window.location.hostname.startsWith("192.168")
    ) {
      if (!alerted) {
        let data = datas
          .filter((data) => data)
          .find((data) => data.able !== false && data.alert);
        if (data) {
          if (tikShow?.activity === 1) {
            setData(data);
          }
        }
      }
    }
    update();
  }, [datas, app.brand, user]);
  const tips = (data) => {
    setData(data);
    console.log("tips----", data);
    spm(data, "tips");
  };
  const alert = (data) => {
    setData(null);
    window.localStorage.setItem(`act_${data.id}`, dayjs().valueOf());
    spm(data, "alert");
    alerted = true;
  };
  const close = (data) => {
    setData(null);
    window.localStorage.setItem(`act_${data.id}`, dayjs().valueOf());
    spm(data, "close");
    alerted = true;
  };

  const spm = (data, opt) => {
    general.axios.post("/spm", {
      spm: `activity${data.id ? `.${data.id}` : ""}.${opt}`,
    });
  };

  return (
    <>
      <div
        className={
          "act-datas " +
          (props.isShow === "open" && props.advertis?.img_url
            ? "act-datas-113"
            : "act-datas-60")
        }
      >
        {datas
          .filter((data) => data && data.able !== false)
          .map((data, index) => (
            <div key={index} className="item" onClick={() => tips(data)}>
              <Image className={app.brand} src={data.thumb} alt={data.title} />
            </div>
          ))}
      </div>
      {data ? (
        <>
          <div className="act-data">
            <div className="container">
              {/* data.product != 0 ? `/game?id=${data.product}` : data.route*/}
              <NavLink
                to={
                  data.type == "outer_h5"
                    ? `${data.route}`
                    : `/game?id=${data.route}`
                }
                iframe={
                  data.route.startsWith("http")
                    ? { title: data.title }
                    : undefined
                }
                user={data.user}
                onClick={() => alert(data)}
              >
                <div className="img">
                  <Image src={data.img} alt="" />
                </div>
              </NavLink>
              <div className="close" onClick={() => close(data)}>
                X
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
