import Type from "./Type";
import Head from "../index/Head";
import Active from "../../lib/Active";

export default (props) => {
  return (
    <>
      <Active active={props.active}>
        <div className="game-index">
          <Head active={props.active} />

          <div className="game-main">
            <div className="game-main-item active">
              <Type active={props.active} />
            </div>
          </div>
        </div>
      </Active>
    </>
  );
};
