import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default (props) => {
  const [game, setGame] = useState({});

  useEffect(() => {
    let game = props.game;
    if (game.kaifu_info) {
      game.kaifu_info = game.kaifu_info.map((item) => {
        item.remind = localStorage.getItem(
          "game-" + game.game_id + "-server-remind-" + item.starttime
        );
        return item;
      });
    }
    setGame(game);
  }, [props.game]);

  const setRemind = (index) => {
    let kaifu_info = game.kaifu_info;
    kaifu_info[index].remind = !kaifu_info[index].remind;
    let key =
      "game-" + game.game_id + "-server-remind-" + kaifu_info[index].starttime;
    if (kaifu_info[index].remind) {
      localStorage.setItem(key, true);
    } else {
      localStorage.removeItem(key);
    }
    setGame({ ...game, kaifu_info });
  };

  const isDay = (time) => {
    if (time) {
      // 获取当前时间 若第一项为今日日期  则显示今天 00:00(截取时间)
      let nowDay = dayjs().format("MM.DD");
      let firstTime = dayjs.unix(time).format("MM.DD HH:mm");
      console.log(nowDay, firstTime);
      let showTime = "";

      if (nowDay === firstTime.split(" ")[0]) {
        showTime = "今天 " + firstTime.split(" ")[1];
      } else {
        showTime = firstTime;
      }

      return showTime;
    }
  };

  return (
    <div className="game-server">
      <div className="tips">温馨提示：仅供参考，以游戏内开服为准！</div>

      {game.kaifu_info && game.kaifu_info.length > 0 ? (
        <div className="container">
          {game.kaifu_info.map((server, index) => (
            <div key={index} className="item">
              <div className="item-left">
                <div className="time">{isDay(server.starttime)}</div>
                <div className="name">{server.kaifuname}</div>
              </div>

              {server.starttime < dayjs().unix() ? (
                <div className="operate disable">已开服</div>
              ) : (
                <div>
                  <div className="operate" onClick={() => setRemind(index)}>
                    {server.remind ? "取消" : "提醒"}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="introImg">
            <img src="/resources/game/trumpet.png" alt="" />
          </div>
          <p className="intro01">开服说明：动态开服</p>
          <p className="intro02">动态开服即上一区服玩家人数达到要求后自动</p>
          <p className="intro03">开启下一区服，请以游戏内具体区服为准。</p>
        </>
      )}
    </div>
  );
};
