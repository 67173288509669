import useActiveState from "../../lib/useActiveState";
import { Link } from "react-router-dom";
import { BellOutlined, SearchOutlined } from "@ant-design/icons";
import { NavLink } from "../../lib/Navigation";
import { useThrottleEffect } from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";

// 分类页顶部搜索
export default (props) => {
  const [messages, setMessages] = useActiveState("index-messages");

  useThrottleEffect(
    () => {
      if (props.active && general.isLogin()) {
        general.axios
          .post("/getUnReadWarn")
          .then(({ data: resData }) => {
            let { status, data } = resData;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              return null;
            }
            setMessages(data.unread_warn == 1);
          })
          .catch((error) => console.error(error.message));
      }
    },
    [props.active],
    { wait: 500 }
  );

  return (
    <div className="search">
      <div className="status-bar-background" />
      <Link to="/search" className="searched">
        <img src="/resources/comment/search.png" alt="" className="icon" />
        <div className="text">请输入关键词搜索</div>
      </Link>
      {/* <div className="opt">
        <NavLink to="/messages" className={`item ${messages ? "active" : ""}`}>
          <BellOutlined className="icon" />
        </NavLink>
      </div> */}
    </div>
  );
};
