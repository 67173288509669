import {Link, useHistory} from "react-router-dom";
import React, {useState} from "react";
import general from "../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [password, setPassword] = useState({});
  const [saving, setSaving] = useState(false);
  const save = useLockFn(async () => {
    if (!data.oldPassword) {
      Dialog.error('请输入旧密码');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入新密码');
      return;
    }
    if (!data.confirmPassword) {
      Dialog.error('请确认密码');
      return;
    }
    if (data.password !== data.confirmPassword) {
      Dialog.error('两次输入的密码不一致');
      return;
    }
    setSaving(true);
    Dialog.loading();
    await general.axios.post('/user/user/modifyPwd', {
      password: data.oldPassword,
      newpassword: data.password,
    })
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then(response => {
        let {status} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          localStorage.setItem('password', data.password);
          Dialog.success('保存成功', () => history.goBack());
        }
      })
      .catch(error => {
        Dialog.error(error.message);
      });
  });

  return <div className="user-password">
    <div className="user-input">
      <div className="name">旧密码</div>
      <input name="oldPassword" type={!password.oldPassword ? 'password' : 'text'} value={data.oldPassword} placeholder="请输入旧密码" autoComplete="off" onFocus={() => setFocus('oldPassword')} onChange={e => setData({...data, oldPassword: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, oldPassword: !password.oldPassword})}>
        {password.oldPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'oldPassword' && data.oldPassword ? <CloseOutlined className="close" onClick={() => setData({...data, oldPassword: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">新密码</div>
      <input name="password" type={!password.password ? 'password' : 'text'} value={data.password} placeholder="请输入新密码" autoComplete="off" onFocus={() => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, password: !password.password})}>
        {password.password ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'password' && data.password ? <CloseOutlined className="close" onClick={() => setData({...data, password: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">确认密码</div>
      <input name="confirmPassword" type={!password.confirmPassword ? 'password' : 'text'} value={data.confirmPassword} placeholder="请确认密码" autoComplete="off" onFocus={() => setFocus('confirmPassword')} onChange={e => setData({...data, confirmPassword: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, confirmPassword: !password.confirmPassword})}>
        {password.confirmPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'confirmPassword' && data.confirmPassword ? <CloseOutlined className="close" onClick={() => setData({...data, confirmPassword: ''})}/> : null}
    </div>
    {user.mobile ? <Link className="tips" to="/user/password-phone" replace>忘记旧密码？验证手机号验证修改</Link> : null}
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};
