const urlMatching = (item) => {
  let route =
    item.type == "game_newinfo"
      ? `/game?id=${item.param}`
      : item.type == "special"
      ? `/game/indexProject?id=${item.param}`
      : item.type == "outer_h5"
      ? item.param
      : "";

  return route;
};

export default urlMatching;
