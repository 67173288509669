import { useLockFn, useMount, useScroll } from "ahooks";
import { NavLink } from "../../lib/Navigation";
import Image from "../../lib/Image";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Dialog from "../../lib/Dialog";
import general from "../../general";
import $ from "jquery";
import Game from "./Game";

export default (props) => {
  useMount(() => {
    if (props.setOperate) {
      props.setOperate(
        <>
          <div className="gift-gifts-top">
            <NavLink className="my" to="/user/gift">
              我的礼包
            </NavLink>
          </div>
        </>
      );
    }
  });

  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/getGifts", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setDatas((games) =>
          parms.page === 1 || !parms.page
            ? result.list
            : [...games, ...result.list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => Dialog.error(error.message));
  });
  useMount(() => load());
  const scroll = useScroll();
  useEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [id, setId] = useState();

  return (
    <>
      <div className="gift-gifts">
        {/*<div className="img">*/}
        {/*  <Image className="img" src="/resources/gift/img.jpg"/>*/}
        {/*</div>*/}
        <Link className="search" to="/gift/search">
          <div className="container">
            <SearchOutlined className="icon" />
            <div className="text">请输入关键词搜索</div>
          </div>
        </Link>
        <div className="gift-items">
          {datas.map((gift) => (
            <div
              key={gift.game_id}
              className="gift-item"
              onClick={() => setId(gift.game_id)}
            >
              <div className="icon">
                <Image src={gift.game_img} />
              </div>
              <div className="data">
                <div className="name">
                  {gift.game_name_main || gift.game_name}
                </div>
                {gift.game_name_branch ? (
                  <div className="branch">{gift.game_name_branch}</div>
                ) : null}
                <div className="value">
                  共<span>{gift.packs_count}</span>款礼包
                </div>
              </div>
              <div className="opt">立即领取</div>
            </div>
          ))}
        </div>
      </div>
      <Game id={id} setId={setId} />
    </>
  );
};
