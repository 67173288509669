import { createSlice } from "@reduxjs/toolkit";

export const reducer = createSlice({
  name: "name",
  // state数据的初始值
  initialState: {},
  // 定义的action
  reducers: {
    setState(state, action) {
      console.log("action----", action);
      for (const key in action.payload) {
        state[key] = action.payload[key];
      }
    },
  },
});

// 导出所有的修改函数方便页面使用
export const { setState } = reducer.actions;

export const state = (state) => state.reducer;

// 导出reducer在store里使用
export default reducer.reducer;
