import { useEffect, useState } from "react";
import general from "../../../general";
import { useReactive } from "ahooks";
import { NavLink } from "../../../lib/Navigation";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

export default (props) => {
  const data = useReactive({
    active: props.active,
  });
  useEffect(() => {
    data.active = props.active;
  }, [props]);
  let [tools, setTools] = useState([]);
  const load = () => {
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    // 获取工具箱列表
    general.axios
      .post("/base/CloudHangingUp/getGameToolBox", {
        maiyou_gameid: props.game.maiyou_gameid,
      })
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("工具箱列表111----", resData);

        let { status, data } = resData;

        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        let list = data.list;
        list.forEach((value, index) => {
          value.show = false;
        });
        setTools([...list]);

        console.log(tools);
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };
  useEffect(() => {
    if (data.active) {
      load();
    }
  }, [data.active]);

  return (
    <div className="game-tools">
      {tools.length > 0 ? (
        tools.map((item) => (
          <div key={item.id} className="tools-every">
            <div className="tools-top">
              <div className="top-left">
                <div className="icon">
                  <Image src={item.icon} alt="" />
                </div>
                <div>
                  <p className="name">{item.name}</p>
                  <p className="title">{item.title}</p>
                </div>
              </div>
              {item.name == "云挂机" && (
                <NavLink to={"/game/hangup"} user={true}>
                  <div className="top-btn"> 去使用 </div>
                </NavLink>
              )}
            </div>

            {item.show && (
              <div
                className="introduce"
                dangerouslySetInnerHTML={{
                  __html: item.introduce
                    ? item.introduce.replace(
                        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                        "$1<br>$2"
                      )
                    : null,
                }}
              ></div>
            )}
            {item.show ? (
              <div
                className="operation"
                onClick={() => {
                  item.show = !item.show;
                  setTools([...tools]);
                }}
              >
                收起
                <UpOutlined style={{ marginLeft: "4px" }} />
              </div>
            ) : (
              <div
                className="operation"
                onClick={() => {
                  console.log(item);
                  item.show = !item.show;
                  setTools([...tools]);
                }}
              >
                使用介绍
                <DownOutlined style={{ marginLeft: "4px" }} />
              </div>
            )}
          </div>
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无工具</p>
        </div>
      )}
    </div>
  );
};
