import {Link, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import general from "../../general";
import dayjs from 'dayjs';
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [data, setData] = useState({});
  const load = (parms = {}) => {
    Dialog.loading();
    general.axios.post('/user/rebate/getRebateDetail', {rebate_id: props.query.id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (data) {
            setData(data);
          }
        })
        .catch(err => {
          Dialog.error('getRebateDetail ' + err.message);
        });
  }
  useEffect(() => {
    load();
  }, []);

  return <div className="rebate-detail" style={{backgroundImage: 'url(/resources/rebate/bg.png)'}}>
    <div className="title">
      <img src="/resources/rebate/line-left.png" alt=""/>
      账号详情
      <img src="/resources/rebate/line-right.png" alt=""/>
    </div>
    <div className="info">
      <dl>
        <dt>游戏名称</dt>
        <dd>{data.game_name_main || data.game_name}</dd>
      </dl>
      <dl>
        <dt>申请账号</dt>
        <dd>{data.game_account}</dd>
      </dl>
      <dl>
        <dt>充值时间</dt>
        <dd>{dayjs.unix(data.recharge_time).format('YYYY-MM-DD HH:mm:ss')}</dd>
      </dl>
      <dl>
        <dt>游戏区服</dt>
        <dd>{data.server_name}</dd>
      </dl>
      <dl>
        <dt>角色名</dt>
        <dd>{data.role_name}</dd>
      </dl>
      <dl>
        <dt>角色ID</dt>
        <dd>{data.role_id}</dd>
      </dl>
      <dl>
        <dt>备注</dt>
        <dd>{data.handle_remark}</dd>
      </dl>
    </div>
    <div className="data">
      <div className="time">{dayjs.unix(data.add_time).format('YYYY-MM-DD HH:mm:ss')}</div>
      <div className="amount">充值金额：<span>{data.recharge_amount}</span></div>
    </div>
    <div className="title">
      <img src="/resources/rebate/line-left.png" alt=""/>
      温馨提示
      <img src="/resources/rebate/line-right.png" alt=""/>
    </div>
    <div className="remind">
      <p>返利发放：晚九点前提交的返利均在一个工作日发放</p>
      <p>返利形式：游戏内发放（邮件领取／直接到角色上）激活 码形式发放（注意查看App信息）</p>
      <p>若申请后超过2个工作日未收到，可点击详情内申请核 实</p>
    </div>
    <div className="operate">
      <Link to={'/rebate/submit?id=' + data.rebate_id} onClick={() => {
        general.rebate = {
          ...data,
          last_recharge_time: data.recharge_time,
          can_rebate_amount: data.recharge_amount,
        }
      }} className="btn">重新提交</Link>
      <Link to={'/service'} className="btn">联系客服</Link>
    </div>
    <div className="tips">此笔返利没有收到或有任何疑问？（请在15天内提交核实申请）</div>
  </div>;
};