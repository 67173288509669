import { useMount, useReactive } from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import { useEffect } from "react";
import { spm } from "../../../general";

export default ({
  game,
  type,
  close,
  callback: _callback,
  superGo: _superGo,
  cloud: _cloud,
}) => {
  const [config] = useActiveState("config");
  const data = useReactive({
    game: `《${game?.game_name_main || game?.game_name}》${
      game?.game_name_branch || ""
    }`,
    size: game?.game_size?.ios_size,
    superable: config.udid_usable,
    interflow: game?.cloud_is_double,
    clouded: game?.cloudPlayed,
    plan: undefined,
  });

  useEffect(() => {
    console.warn(game);
    console.warn(data);
    console.warn({ type, plan: data.plan });
  });

  const callback = () => {
    _callback();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.next`);
  };
  const cloudGo = () => {
    _cloud();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.cloud`);
  };
  const superGo = () => {
    _superGo();
    close();
    spm(`game.${game.maiyou_gameid}.intercept.${data.spm}.download`);
  };

  useMount(() => {
    data.spm = type === "super" ? "download" : type;

    if (type === "cloud" && !data.interflow && !data.superable) {
      data.plan = "cloud";
    } else if (type === "super" && !data.interflow && data.clouded) {
      data.plan = "backSuper";
    } else if (
      type === "cloud" &&
      !data.interflow &&
      data.superable &&
      !data.clouded
    ) {
      data.plan = "cloudSuperable";
    } else if (type === "auto") {
      data.plan = "auto";
    }
    if (!data.plan) {
      callback();
    }

    // alert("intercept" + data.plan);
  });

  return (
    <>
      {!!data.plan && (
        <>
          <div className="game-intercept">
            <div className="gi-container">
              <div className="gi-head">温馨提示</div>
              <div className="gi-body">
                <div className="text">
                  {data.plan === "cloud" ? (
                    <>
                      {data.game}
                      游戏云玩所产生的数据属于安卓端，无法与ios数据互通；若您使用云玩游戏，后续下载ios客户端无法继续用该角色体验游戏
                    </>
                  ) : null}
                  {data.plan === "backSuper" ? (
                    <>
                      {data.game}
                      游戏云玩所产生的数据属于安卓端，无法与ios数据互通；
                      <br />
                      确认放弃原角色，重新开始游戏吗？
                    </>
                  ) : null}
                  {data.plan === "cloudSuperable" ? (
                    <>
                      喜欢这款游戏吗？下载客户端游戏体验更佳哦~
                      {!data.interflow ? (
                        <>
                          <br />
                          {data.game}
                          游戏云玩所产生的数据属于安卓端，无法与ios数据互通；若您下载玩游戏，后续云玩无法继续用该角色体验游戏
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {data.plan === "auto" ? (
                    <>udid获取成功，请现返回APP继续畅玩。</>
                  ) : null}
                </div>
              </div>
              <div className="gi-foot">
                {(() => {
                  if (data.plan === "backSuper") {
                    return (
                      <>
                        <div className="btn" onClick={cloudGo}>
                          继续云玩
                        </div>
                        <div className="btn" onClick={callback}>
                          确认放弃，继续下载
                        </div>
                      </>
                    );
                  } else if (data.plan === "cloudSuperable") {
                    return (
                      <>
                        <div className="btn" onClick={callback}>
                          继续畅玩
                        </div>
                        <div className="btn" onClick={superGo}>
                          下载（{data.size}）
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <div className="btn" onClick={callback}>
                        我已知晓
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
