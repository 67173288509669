import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import general from "../../general";
import { useScroll } from "ahooks";
import $ from "jquery";
import KeepScroll from "../../lib/KeepScroll";

const tops = [
  {
    id: "hot",
    name: "热玩榜",
  },
  {
    id: "new",
    name: "新游榜",
  },
  {
    id: "dl",
    name: "下载榜",
  },
];

const Top = ({ active, id }) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      type: { hot: 2, new: 3, dl: 4 }[id],
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/base/game/getGameList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        setInit(true);
        let result = res.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setGames((games) =>
          parms.page === 1 || !parms.page
            ? result.game_list
            : [...games, ...result.game_list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [active, scroll]);

  return (
    <>
      <div className="top-items">
        {games.map((game, index) => (
          <Fragment key={game.game_id}>
            <Link to={"/game?id=" + game.game_id} className="top-item">
              <div className="index">
                {index < 3 ? (
                  <img src={"/resources/index/new-" + (index + 1) + ".png"} />
                ) : (
                  index + 1
                )}
              </div>
              <div className="icon">
                <Image src={game.game_image.thumb} />
                {game.discount < 1 ? (
                  <div className="discount">{game.discount * 10}折</div>
                ) : (
                  <>
                    <div className="badge">
                      {game.game_desc
                        ?.split("+")
                        .filter((game, index) => index == 0)
                        .map((item) => item)}
                    </div>
                  </>
                )}
              </div>
              <div className="subject">
                <div className="data">
                  <div className="name">
                    {game.game_name_main || game.game_name}
                  </div>
                  <div className="desc">
                    {game.game_name_branch ? (
                      <div className="branch">{game.game_name_branch}</div>
                    ) : null}
                    {game.game_classify_type}
                    {game.game_species_type != 3 ? (
                      <>&nbsp;·&nbsp;{game.gama_size.ios_size}</>
                    ) : null}
                  </div>
                  <div className="info">
                    {game.game_desc
                      ? game.game_desc?.split("+").map((item, index) => (
                          <div key={index} className="tag">
                            {item}
                          </div>
                        ))
                      : game.introduction}
                  </div>
                </div>
              </div>
            </Link>
          </Fragment>
        ))}
        {games.length > 0 && !more ? (
          <div className="view-end">别撩啦！已经到底啦~</div>
        ) : null}
      </div>
    </>
  );
};

export default (props) => {
  const [current, setCurrent] = useState("hot");

  return (
    <>
      <div className="top-index">
        <div className="top-head width">
          <div className="width">
            {tops.map((item) => (
              <div
                key={item.id}
                className={`item ${item.id === current ? "active" : ""}`}
                onClick={() => setCurrent(item.id)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className="top-body">
          {tops.map((item) => (
            <KeepScroll
              key={item.id}
              active={props.active && item.id == current}
              lazy
            >
              <div
                className={`top-group ${item.id === current ? "active" : ""}`}
              >
                <Top
                  {...{
                    ...item,
                    active: props.active && item.id === current,
                  }}
                />
              </div>
            </KeepScroll>
          ))}
        </div>
      </div>
    </>
  );
};
