import {useMount} from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import {useState} from "react";
import {useHistory} from "react-router-dom";

export default ({query}) => {
  const history = useHistory();
  const [data, setData] = useState();
  useMount(() => {
    if (query.type != 'notice') {
      if (general.message) {
        setData({
          id: general.message.message_id,
          title: general.message.title,
          time: general.message.message_time,
          content: general.message.content,
        });
        console.log(general.message);
        if (general.message.read_tag == 0) {
          general.axios.post('/user/message/operateMessage', {message_id: query.id, operate_type: 1})
            .then(({data: resData}) => {
              let {status} = resData;
              if (!status.succeed) {
                console.error(status.error_desc);
                return null;
              }
              general.message.read_tag = 1;
            })
            .catch(error => console.error(error.message));
        }
      }
      return;
    }
    Dialog.loading();
    general.axios.post('/user/platform_notice/getNoticeInfo', {notice_id: query.id})
      .finally(() => Dialog.close())
      .then(({data: resData}) => {
        let {status, data} = resData;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return null;
        }
        setData({
          id: data.notice_info.id,
          title: data.notice_info.title,
          time: data.notice_info.notice_time,
          content: data.notice_info.content,
        });
      })
      .catch(error => console.error(error.message));
  });

  const click = (e) => {
    var url = e.target?.src;
    if (url) {
      history.push(`/img?imgs=${[url]}&img=0`);
    }
  }

  return data ? <>
    <div className="message-message">
      <div className="title">{data.title}</div>
      <div className="time">{data.create_time}</div>
      <div className="content" dangerouslySetInnerHTML={{__html: data.content || null}} onClick={click}/>
    </div>
  </> : null;
}