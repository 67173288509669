import React, { Fragment, useEffect, useState } from "react";
import general from "../../../general";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import $ from "jquery";
import { useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { PlayCircleOutlined } from "@ant-design/icons";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [gameGroups, setGameGroups] = useState({});
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/reserveGames", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(({ data: resData }) => {
        setInit(true);
        let result = resData.data;
        if (parms.page) {
          setPage(parms.page);
        }
        for (const game of result.game_list) {
          let group = dayjs.unix(game.starting_time).format("MM-DD");
          if (!gameGroups[group]) {
            gameGroups[group] = [];
            gameGroups[group].push(game);
          } else {
            let index = gameGroups[group].findIndex(
              (value) => value.game_id === game.game_id
            );
            if (index === -1) {
              gameGroups[group].push(game);
            }
          }
        }
        setGameGroups(gameGroups);
        setMore(result.paginated.more);
        console.log("6666666666", gameGroups);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div>
      {gameGroups && Object.entries(gameGroups).length > 0 && (
        <div className="new-new">
          {Object.entries(gameGroups).map(([group, games]) => (
            <Fragment key={group}>
              <dl>
                <dt>{group}</dt>
                <dd>
                  {games.map((game) => (
                    <Link
                      key={game.game_id}
                      to={`/game?id=${game.game_id}`}
                      className="new-new-item"
                    >
                      <div className="data">
                        <div className="icon">
                          <Image src={game.game_image?.thumb} />
                          {game.discount < 1 && (
                            <div className="discount">
                              {game.discount * 10}折
                            </div>
                          )}
                        </div>
                        <div className="info">
                          <div className="name">
                            <div className="name-left">
                              <span>
                                {game.game_name_main || game.game_name}
                              </span>
                              {game.game_name_branch ? (
                                <span className="versions">
                                  {game.game_name_branch}
                                </span>
                              ) : null}
                            </div>
                            <div className="opt">
                              <div className="time">预约</div>
                            </div>
                          </div>
                          <div className="tags">
                            {game.game_desc?.split("+").map((item, index) => (
                              <div
                                key={index}
                                className="tag"
                                style={{ marginTop: 0 }}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                          <div className="subscribe-time">
                            <div className="time-time">
                              {dayjs
                                .unix(game.starting_time)
                                .format("MM-DD HH:mm")}
                              上线
                            </div>
                            <div className="reserves">
                              <span>{game.reserved_total}</span>人预约
                            </div>
                          </div>
                        </div>
                      </div>
                      <object>
                        <Link
                          to={
                            game.video_url
                              ? `/video?url=${game.video_url}`
                              : `/game?id=${game.game_id}`
                          }
                          className="media"
                        >
                          <div className="container">
                            <Image
                              src={
                                game.banner_url ||
                                game.game_ur_list[0] ||
                                game.video_img_url
                              }
                              alt=""
                            />
                            {game.video_url ? (
                              <PlayCircleOutlined className="play" />
                            ) : null}
                          </div>
                        </Link>
                      </object>
                    </Link>
                  ))}
                </dd>
              </dl>
            </Fragment>
          ))}
        </div>
      )}

      {Object.entries(gameGroups).length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
