import React, {useEffect} from "react";
import Test1 from "./test/Test1";
import Dialog from "../lib/Dialog";

export default props => {
  useEffect(() => {
    setTimeout(() => {
      Dialog.alert('15sdasd是待发送发送到发送到');
    }, 2000);
  }, []);

  return <Test1/>;
};