import useActiveState from "../../../lib/useActiveState";
import Getter from "./Getter";
import { useState, useEffect } from "react";
import Dialog from "../../../lib/Dialog";
import general from "../../../general";
import { useRequest } from "ahooks";
import _ from "lodash";

export default ({ reject, children, getter: DiyGetter }) => {
  const [config, setConfig] = useActiveState("config");
  const [key] = useActiveState("key");
  let [udid, setUdid] = useActiveState("udid");

  const [dep, setDep] = useState(_.random(132, 277));
  const retry = () => {
    setDep(_.random(132, 277));
  };
  const { loading } = useRequest(
    async () => {
      if (udid) {
        return;
      }
      Dialog.loading();
      await general.axios
        .post("/base/newindex/getKeyUdid", { key })
        .finally(() => Dialog.close())
        .then(({ data: resData }) => {
          if (!resData.status.succeed) {
            Dialog.error(resData.status.error_desc);
            return;
          }
          if (resData.data.udid) {
            setUdid((udid = resData.data.udid));
          }
        })
        .catch((error) => Dialog.error(error.message));
      if (udid) {
        await general.axios
          .post("/base/common/getConfig")
          .then(({ data: resData }) => {
            let { status, data } = resData;
            if (status.succeed) {
              setConfig({ ...config, udid_usable: data.udid_usable });
            }
          })
          .catch((error) => console.error(error));
      }
    },
    { refreshDeps: [dep] }
  );

  return (
    <>
      {!loading ? (
        <>
          {udid && localStorage.getItem("desFileDown") ? (
            children
          ) : (
            <Getter
              close={reject}
              retry={retry}
              getter={
                DiyGetter ? <DiyGetter close={reject} retry={retry} /> : null
              }
            />
          )}
        </>
      ) : null}
    </>
  );
};
