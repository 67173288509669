import {Link, useHistory} from "react-router-dom";
import React, {useState} from "react";
import general from "../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useInterval, useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const history = useHistory();
  const [user, setUser] = useActiveState('user');
  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [password, setPassword] = useState({});

  const [coding, setCoding] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const code = () => {
    if (!data.mobile) {
      Dialog.error('请输入手机号');
      return;
    }
    if (coding || codeTime) {
      return;
    }
    setCoding(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: data.mobile,
      code: 'code',
      type: '03',
    })
      .finally(() => {
        setCoding(false);
        Dialog.close();
      })
      .then(response => {
        let result = response.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc === '电话号码未绑定 请先绑定手机号码' ? '绑定手机号错误' : result.status.error_desc);
        } else {
          setCodeTime(60);
        }
      })
      .catch(error => {
        Dialog.error(error.message);
      });
  }

  const [saving, setSaving] = useState(false);
  const save = useLockFn(async () => {
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入新密码');
      return;
    }
    if (!data.confirmPassword) {
      Dialog.error('请确认密码');
      return;
    }
    if (data.password !== data.confirmPassword) {
      Dialog.error('两次输入的密码不一致');
      return;
    }
    setSaving(true);
    Dialog.loading();
    await general.axios.post('/user/user/forgetPwd', {
      mobile: data.mobile,
      code: data.code,
      newpassword: data.password,
    })
        .finally(() => {
          setSaving(false);
          Dialog.close();
        })
        .then(response => {
          let {status, data} = response.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            if (data.token) {
              localStorage.setItem('token', data.token);
              localStorage.setItem('password', data.password);
            }
            Dialog.success('保存成功', () => history.goBack());
          }
        })
        .catch(error => {
          Dialog.error(error.message);
        });
  });

  return <div className="user-password-phone">
    <div className="user-input">
      <div className="name">手机号</div>
      <div className="value">{user.mobile ? user.mobile : '未绑定'}</div>
    </div>
    <div className="user-input">
      <div className="name">手机号</div>
      <input name="mobile" type="text" value={data.mobile} placeholder="请输入手机号" autoComplete="off" onFocus={() => setFocus('mobile')} onChange={e => setData({...data, mobile: e.target.value})}/>
      {focus === 'mobile' && data.mobile ? <CloseOutlined className="close" onClick={() => setData({...data, mobile: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">验证码</div>
      <input name="code" type="text" value={data.code} placeholder="请输入验证码" autoComplete="off" onFocus={() => setFocus('code')} onChange={e => setData({...data, code: e.target.value})}/>
      <div className={'btn ' + (coding || codeTime ? 'disable' : '')} onClick={code}>
        {coding ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
      </div>
    </div>
    <div className="user-input">
      <div className="name">新密码</div>
      <input name="password" type={!password.password ? 'password' : 'text'} value={data.password} placeholder="请输入新密码" autoComplete="off" onFocus={() => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, password: !password.password})}>
        {password.password ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'password' && data.password ? <CloseOutlined className="close" onClick={() => setData({...data, password: ''})}/> : null}
    </div>
    <div className="user-input">
      <div className="name">确认密码</div>
      <input name="confirmPassword" type={!password.confirmPassword ? 'password' : 'text'} value={data.confirmPassword} placeholder="请确认密码" autoComplete="off" onFocus={() => setFocus('confirmPassword')} onChange={e => setData({...data, confirmPassword: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, confirmPassword: !password.confirmPassword})}>
        {password.confirmPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'confirmPassword' && data.confirmPassword ? <CloseOutlined className="close" onClick={() => setData({...data, confirmPassword: ''})}/> : null}
    </div>
    <Link className="tips" to="/user/password" replace>验证旧密码修改</Link>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};
