import {useEffect} from "react";

export default props => {
  useEffect(() => {

  }, []);

  return <>
    邀请好友……
  </>;
};