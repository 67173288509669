import React, { useEffect, useState } from "react";
import general from "../../../general";
import dayjs from "dayjs";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useMount, useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";

const types = {
  0: "其它",
  2: "攻略",
  3: "独家永久",
  4: "独家限时",
  5: "永久活动",
  6: "限时活动",
};

const status = {
  0: "未开始",
  1: "进行中",
  2: "已结束",
};

export default (props) => {
  useMount(() => {
    if (props.query.groupName && props.setTitle) {
      props.setTitle(props.query.groupName);
    }
  });
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [articles, setArticles] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      game_id: props.query.id,
      group: props.query.group,
      pagination: { page: page, count: 20 },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/articles", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setArticles((articles) =>
          parms.page === 1 || !parms.page
            ? result.list
            : [...articles, ...result.list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <>
      <div className="game-article">
        {articles.map((article) => (
          <Link
            key={article.news_id}
            to={"/article/detail?id=" + article.news_id}
            className="item"
            onClick={() => (general.article = article)}
          >
            <div className="container">
              <div className="desc">
                <div className={"type type-" + article.type}>
                  {types[article.type]}
                </div>
                <div className="state">{status[article.status]}</div>
                <div className="time">
                  {article.news_date
                    ? dayjs.unix(article.news_date).format("YYYY-MM-DD")
                    : ""}
                </div>
              </div>
              <div className="title">
                {article.news_title_main || article.news_title}
              </div>
            </div>
            <div className="more">&gt;</div>
          </Link>
        ))}
        <div className={"pull-to-load " + (!more ? "nomore" : "")} />
      </div>
    </>
  );
};
