import React, { useEffect, useState } from "react";
import general from "../../../general";
import { Link } from "react-router-dom";
import $ from "jquery";
import dayjs from "dayjs";
import { useUpdateEffect, useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [trades, setTrades] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/collectedTrades", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setTrades((trades) =>
          parms.page === 1 || !parms.page
            ? result.account_list
            : [...trades, ...result.account_list]
        );
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useUpdateEffect(() => {
    if (props.active && (!init || props.action === "POP")) {
      load();
    }
  }, [props]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="favorite-trade">
      {trades.map((trade) => (
        <Link
          key={trade.trade_id}
          to={"/trade/detail?id=" + trade.trade_id}
          className="item"
        >
          <div className="info">
            <div className="icon">
              <Image src={trade.account_icon} alt="" />
            </div>
            <div className="text">
              <div className="name">区服：{trade.server_name}</div>
              <div className="desc">{trade.title}</div>
            </div>
          </div>
          <div className="data">
            <div className="time">
              上架时间：
              {dayjs.unix(trade.sell_time).format("YYYY-MM-DD HH:mm:ss")}
            </div>
            <div className="price">
              <div className="amount">{trade.total_amount}</div>
              <div className="price">{trade.trade_price}</div>
            </div>
          </div>
        </Link>
      ))}
      {trades.length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
