import {useEffect, useState} from "react";
import $ from "jquery";
import {useScroll} from "ahooks";
import Active from "./Active";

export default props => {
  const _scroll = useScroll();
  const [scroll, setScroll] = useState({top: 0, left: 0});
  useEffect(() => {
    if (props.active && scroll) {
      $('html,body').scrollTop(scroll.top);
      $('html,body').scrollLeft(scroll.left);
    } else {
      setScroll(_scroll);
    }
  }, [props.active]);

  return props.lazy ? <Active active={props.active}>
    {props.children}
  </Active> : props.children;
};