import React, {useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import dayjs from 'dayjs';
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import {CSSTransition} from "react-transition-group";

export default ({gift, setGift}) => {
  const [show, setShow] = useState(true);

  return gift ? <CSSTransition in={show} timeout={250} appear={true} onExited={() => {
    setGift(null);
    setShow(true);
  }}>
    <div className="gift-detail">
      <div className="gift-mask" onClick={() => setShow(false)}/>
      <div className="gift-container">
        <div className="gift-top">
          <div className="icon"><Image src={gift.gift_image?.thumb}/></div>
          <div className="text">
            <div className="name">{gift.gift_name_main || gift.gift_name}</div>
            <div className="time">有效期：{dayjs.unix(gift.gift_dealine).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        </div>
        <div className="gift-main">
          <div className="gift-desc">
            <div className="title">礼包说明</div>
            <div className="main">{gift.gift_desc}</div>
          </div>
        </div>
        <CopyToClipboard text={gift.gift_code} onCopy={() => Dialog.info('已复制')}>
          <div className="gift-bottom">复制</div>
        </CopyToClipboard>
      </div>
    </div>
  </CSSTransition> : null;
};