import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useUpdateEffect, useScroll } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { CSSTransition } from "react-transition-group";

export default (props) => {
  const explain = () => {
    Dialog.alert(
      "问答须知",
      <div className="qa-explain">
        想对新接触的游戏有更多的了解？游戏问答让萌新有地方问，让大神为您解答
        <br />
        问答利益
        <br />
        理性发言，中肯发言
        <br />
        问的清楚，答的明白
        <br />
      </div>
    );
  };
  useEffect(() => {
    props.setOperate(<QuestionCircleOutlined onClick={explain} />);
  }, []);
  const [game] = useState(general.game ?? {});
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      game_id: props.query.id,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/base/qa/getQuestions", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list, paginated },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (parms.page) {
            setPage(parms.page);
          }
          setDatas((datas) =>
            parms.page === 1 || !parms.page ? list : [...datas, ...list]
          );
          setMore(paginated.more && list.length > 0);
        }
      })
      .catch((err) => {
        Dialog.error("getQuestions " + err.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const history = useHistory();
  const toDetail = (data) => {
    general.question = data;
    history.push("/answer?id=" + data.id);
  };

  const [question, setQuestion] = useState(false);
  const [content, setContent] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (!content) {
      return;
    }
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    general.axios
      .post("/base/qa/submitQuestion", {
        game_id: props.query.id,
        question: content,
      })
      .finally(() => {
        setSubmiting(false);
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("提交成功", () => {
            setQuestion(false);
            setContent("");
          });
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <div className="qa-index">
      <div className="qa-game">
        <div className="icon">
          <Image src={game.game_image?.thumb} />
        </div>
        <div className="info">
          <div className="name">{game.game_name}</div>
          <div className="desc">有{game.played_count}人正在玩该游戏</div>
          <div className="desc">
            共{game.question_count}条问题，收到{game.answer_count}
            个回答，去逛逛吧
          </div>
        </div>
      </div>
      <div className="qa-items">
        {datas.map((item, index) => (
          <div key={index} className="qa-item" onClick={() => toDetail(item)}>
            <div className="qa-q">
              <div className="icon">问</div>
              <div className="text">{item.question}</div>
            </div>
            <div className="qa-as">
              {item.answers.list.length > 0 ? (
                <div>
                  {item.answers.list.map((item, inde) => (
                    <div key={inde} className="qa-a">
                      <div className="icon">答</div>
                      <div className="text">{item.content}</div>
                    </div>
                  ))}
                  <div className="qa-more">
                    查看全部<span>{item.answers.count}</span>个回答
                  </div>
                </div>
              ) : (
                <div className="qa-empty">暂无回答 快来帮帮他</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="qa-operate">
        <div className="btn" onClick={() => setQuestion(true)}>
          我要请教
        </div>
      </div>
      <CSSTransition in={question} timeout={250} unmountOnExit={true}>
        <div className="qa-form">
          <div className="mask" onClick={() => setQuestion(false)} />
          <div className="form">
            <div className="head">
              <div className="title">
                像{game.played_count}位玩过该游戏的人请教
              </div>
              <div
                className={"btn " + (!content ? " disable" : "")}
                onClick={submit}
              >
                提交
              </div>
            </div>
            <div className="input">
              <textarea
                rows="5"
                placeholder="5-100字范围内，请准确描述您的问题吧~"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
      {datas.length > 0 ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
