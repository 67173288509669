import React, {useState} from 'react';
import URI from "urijs";
import general from "../general";
import axios from "axios";
import {url} from "../config";
import {useInterval, useMount, useReactive} from "ahooks";

const Error = ({error}) => {
  const data = useReactive({
    show: false,
    weixinUrl: 'https://wpa1.qq.com/7ixvXOlr?_type=wpa&qidian=true',
    weixinUrlLoad: false,
    weixinUrlClick: false,
  });
  useMount(() => {
    general.axios.get('/getWeixinServiceUrl')
      .then(({data: resData}) => {
        if (resData?.data?.url) {
          data.weixinUrl = resData.data.url;
        }
      })
      .finally(() => {
        data.weixinUrlLoad = true;
        if (data.weixinUrlClick) {
          openWeixinUrl();
        }
      });
  });
  useMount(() => {
    axios.post(`${url}/log`, {
      type: 'error boundary',
      message: error.message,
      stack: error.stack,
    });
  });
  const retry = () => {
    window.history.pushState(null, null, URI(window.location.href).hash(URI(window.location.hash.substring(1)).addQuery('retry-cache', 1).toString()).toString())
    window.location.reload();
  }
  const openWeixinUrl = () => {
    if (!data.weixinUrlLoad) {
      data.weixinUrlClick = true;
      return;
    }
    if (navigator.userAgent === 'app') {
      window.location.href = URI('app://browser')
        .addQuery('url', data.weixinUrl)
        .toString();
    } else {
      window.location.href = data.weixinUrl;
    }
  }

  const [count, setCount] = useState(3);

  useInterval(() => {
    setCount(count - 1);
    if (count === 0) {
      // retry();
    }
  }, 1000);

  return <>
    <div className="error-boundary">
      <div className="title">发生了一个小错误！</div>
      {data.show ? <>
        <div className="info">{error.stack}</div>
      </> : <>
        <div className="desc" onClick={() => data.show = true}>
          {error.message}
          <span>详情 &gt;&gt;</span>
        </div>
      </>}
      <div className="option">
        <div className="btn" onClick={() => retry()}>{count >= 0 && `（${count}秒后自动）`}重新尝试</div>
        <div className="btn" onClick={() => openWeixinUrl()}>联系客服</div>
      </div>
    </div>
  </>
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    /*if (error?.message?.startsWith('Maximum update depth exceeded')) {
      location.reload();
      return;
    }*/
    this.setState({hasError: true, error, errorInfo});
  }

  render() {
    let {hasError, error} = this.state;
    if (hasError) {
      return <Error error={error}/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;