import React, { useEffect } from "react";
import general from "../../general";
import { useLocalStorageState } from "ahooks";
import { Link } from "react-router-dom";
import Image from "../../lib/Image";

// 专题信息展示
export default (props) => {
  const [data, setData] = useLocalStorageState("projectGame", {
    defaultValue: [],
  });
  useEffect(() => {
    if (general.projectGame) {
      setData(general.projectGame);
    }
  }, []);

  useEffect(() => {
    props.setTitle(data.title);
  }, [data]);

  return (
    <>
      <div className="game-project">
        <div className="project-img">
          <Image src={data.url_img} alt="" />
        </div>
        <div className="project-info">{data.describe}</div>
        <div className="games">
          {data.details?.map((game) => (
            <Link
              key={game.game_id}
              to={"/game?id=" + game.game_id}
              className="item"
            >
              <div className="icon">
                <Image src={game.icon} />
              </div>
              <div className="data">
                <div className="name">{game.game_name}</div>
                <div className="desc">
                  {game.game_name_branch ? (
                    <div className="branch">{game.game_name_branch}</div>
                  ) : null}
                  {game.version}
                  &nbsp;·&nbsp;
                  {game.size}
                </div>
                <div className="info">
                  {game.benefits?.split("+").map((item, index) => (
                    <div key={index} className="tag">
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="btn">下载</div>
            </Link>
          ))}
        </div>
        <div className="view-end">别撩啦！已经到底啦~</div>
      </div>
    </>
  );
};
