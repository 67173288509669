import React, { useEffect, useState } from "react";
import UserPhoneVerify from "../util/UserPhoneVerify";
import { useLockFn } from "ahooks";
import general from "../../general";
import { useHistory } from "react-router-dom";
import { prod } from "../../config";
import Dialog from "../../lib/Dialog";
import ImagePicker from "../util/ImagePicker";
import { CSSTransition } from "react-transition-group";

export default () => {
  const history = useHistory();
  const [data, setData] = useState({
    game_uploadedshots: [],
    ...(!prod
      ? {
          server_name: "跨三B",
          sell_price: "917",
          title: "测试标题",
          content: "测试内容",
        }
      : {}),
  });
  const [imgs, setImgs] = useState([]);
  const [games, setGames] = useState([]);
  const [tipsFont, setTipsFont] = useState();
  const gameLoad = useLockFn(async () => {
    Dialog.loading();
    await general.axios
      .post("/tradedGameHint")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTipsFont(list);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });

    await general.axios
      .post("/tradedGameList", { pagination: { count: 917917, page: 1 } })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setGames(data.game_list || []);
        }
      })
      .catch((err) => {
        Dialog.error("tradedGameList " + err.message);
      });
  });
  useEffect(() => {
    gameLoad();
  }, []);
  const [alts, setAlts] = useState([]);
  const altLoad = useLockFn(async () => {
    if (!data.game_id) {
      Dialog.error("请先选择游戏");
      return;
    }
    Dialog.loading();
    await general.axios
      .post("/base/game/getSelfXhUserNameList", {
        game_id: data.game_id,
        pagination: { count: 917917, page: 1 },
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setAlts(data.xh_list);
        }
      })
      .catch((err) => {
        Dialog.error("getSelfXhUserNameList " + err.message);
      });
  });
  useEffect(() => {
    if (data.game_id) {
      altLoad();
    } else {
      setAlts([]);
    }
  }, [data.game_id]);

  const [confirm, setConfirm] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  const [verify, setVerify] = useState();
  const submit = useLockFn(async () => {
    if (imgs.length < 3) {
      Dialog.info("请至少上传三张图片");
      return;
    }
    if (!verify) {
      setVerify(true);
      return;
    }
    Dialog.info("开始图片上传");
    for (let i = 0; i < imgs.length; i++) {
      let formData = new FormData();
      formData.append("type", "trade");
      formData.append("imageData", imgs[i].file);
      try {
        await general.axios
          .post("/user/trade/uploadImagePublicOss", formData)
          .then((res) => {
            let { status, data: result } = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              data.game_uploadedshots.push(result.imageUrl);
              setData({ ...data });
              Dialog.info(
                "上传图片" + data.game_uploadedshots.length + "/" + imgs.length
              );
            }
          });
      } catch (e) {
        Dialog.error(e.message);
        return;
      }
    }
    let formData = new FormData();
    for (let name in data) {
      if (name === "game_uploadedshots") {
        for (let i = 0; i < data[name].length; i++) {
          formData.append(name + "[]", data[name][i]);
        }
      } else {
        formData.append(name, data[name]);
      }
    }
    Dialog.loading("正在提交");
    await general.axios
      .post("/user/trade/submitTradeDetail", formData)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("提交成功", () => {
            setVerify(false);
            history.goBack();
          });
        }
      })
      .catch((err) => {
        Dialog.error("submitTradeDetail " + err.message);
      });
  });

  return (
    <div className="trade-submit">
      <div className="trade-body">
        <div className="trade-input">
          <div className="name">选择游戏</div>
          <div className="value">
            {games.find((game) => game.game_id === data.game_id)
              ?.game_name_main ||
              games.find((game) => game.game_id === data.game_id)?.game_name ||
              "选择 >"}
            <select
              onChange={(e) => setData({ ...data, game_id: e.target.value })}
              defaultValue={data.game_id}
            >
              <option value="">请选择</option>
              {games.map((game) => (
                <option key={game.game_id} value={game.game_id}>
                  {game.game_name_main || game.game_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="trade-input">
          <div className="name">选择小号</div>
          <div className="value">
            {alts.find((alt) => alt.xh_username === data.xh_username)
              ?.xh_alias || "选择 >"}
            <select
              onChange={(e) =>
                setData({ ...data, xh_username: e.target.value })
              }
              defaultValue={data.xh_username}
            >
              <option value="">请选择</option>
              {alts.map((alt, index) => (
                <option key={index} value={alt.xh_username}>
                  {alt.xh_alias}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="trade-input">
          <div className="name">游戏区服</div>
          <div className="value">
            <input
              type="text"
              placeholder="请输入游戏区服"
              value={data.server_name}
              onChange={(e) =>
                setData({ ...data, server_name: e.target.value })
              }
            />
          </div>
        </div>
        <div className="trade-input">
          <div className="name">出售价</div>
          <div className="value">
            <input
              type="text"
              placeholder="不低于6元"
              value={data.sell_price}
              onChange={(e) => setData({ ...data, sell_price: e.target.value })}
            />
          </div>
        </div>
        <div className="trade-input">
          <div className="name">出售可得（手续费5%,最低5元）</div>
          <div className="value">
            {(() => {
              let value = data.sell_price * 10;
              let fee = value * 0.05;
              if (fee < 50) {
                fee = 50;
              }
              return value - fee || 0;
            })()}
            平台币
          </div>
        </div>
        <div className="trade-input">
          <div className="name">标题</div>
          <div className="value">
            <input
              type="text"
              placeholder="请输入标题"
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </div>
        </div>
        <div className="trade-input develop">
          <div className="name">商品描述（选填）</div>
          <div className="value">
            <textarea
              rows="3"
              placeholder="可描述角色等级、装备、属性，完善的描述可快速有效促 成交易。"
              value={data.content}
              onChange={(e) => setData({ ...data, content: e.target.value })}
            />
          </div>
        </div>
        <div className="trade-input develop">
          <div className="name">二级密码（有则必填）</div>
          <div className="value">
            <input
              type="text"
              placeholder="例如：仓库密码。此密码仅审核人员及最终买家可见；"
              value={data.second_level_pwd}
              onChange={(e) =>
                setData({ ...data, second_level_pwd: e.target.value })
              }
            />
          </div>
        </div>
        <div className="trade-input develop">
          <div className="name">上传图片 请上传3-5张游戏截图</div>
          <div className="value">
            <ImagePicker
              multiple={true}
              max={5}
              files={imgs}
              setFiles={setImgs}
            />
          </div>
        </div>
        <div className="trade-tips">
          <p>
            <span>温馨提示：</span>
            亲爱的玩家，为了快速审核交易信息，请上传以下截图：人
            物属性界面、VIP充值等级或特权界面、背包仓库或宠物属性界面。
          </p>
          <p>
            <span>备注：</span>
            由于游戏排行榜会因为时间关系导致最终排行误差，为避免造成
            买卖双方纠纷，上传排行描述及相关截图的一律不通过审核！
          </p>
        </div>
        <div className="trade-operate">
          <div className="btn" onClick={submit}>
            确认出售
          </div>
        </div>
      </div>
      <CSSTransition
        in={confirm}
        timeout={250}
        appear={true}
        unmountOnExit={true}
      >
        <div className="trade-confirm">
          <div className="container">
            <div className="head">
              <div className="title">交易细则</div>
            </div>
            <div className="main">
              <div className="title">出售流程</div>
              <div className="process">
                <img src="/resources/trade/process.png" alt="" />
                <ul className="clearfix">
                  <li>上传商品</li>
                  <li>审核冻结</li>
                  <li>买家购买</li>
                  <li>出售成功</li>
                </ul>
              </div>
              <div className="title">卖家须知</div>
              <div className="body">
                {tipsFont?.maijiaxuzhi?.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            </div>
            <div className="operate">
              <div className="input">
                <input type="radio" onChange={() => setConfirmed(true)} />
                我已阅读
              </div>
              <div
                className={"buy" + (!confirmed ? " disable" : "")}
                onClick={() => confirmed && setConfirm(false)}
              >
                我已经记住
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <UserPhoneVerify
        show={verify}
        type="06"
        close={() => setVerify(false)}
        onSuccess={submit}
      />
    </div>
  );
};
