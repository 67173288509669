import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import List from "./List";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import general from "../../general";
import { NavLink } from "../../lib/Navigation";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";

export default (props) => {
  useEffect(() => {
    props.setOperate(
      <NavLink to="/user/voucher" className="voucher-index-operate">
        我的代金券
      </NavLink>
    );
  }, []);
  const [kw, setKw] = useState("");
  const [title, setTitle] = useState();
  const [datas, setDatas] = useState([]);
  const load = () => {
    Dialog.loading();
    general.axios
      .get("/base/voucher/getLatePlayGames")
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTitle(data.title);
        setDatas(data.list.map((item) => ({ ...item, ...item.game_info })));
      })
      .catch((err) => {
        Dialog.error("getLatePlayGames " + err.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  return (
    <div className="voucher-index">
      <div className="voucher-head">
        <div className="input">
          <SearchOutlined className="icon" />
          <input
            type="text"
            placeholder="请输入要搜索的游戏"
            value={kw}
            onChange={(e) => setKw(e.target.value)}
          />
          {kw ? (
            <CloseOutlined className="empty" onClick={() => setKw("")} />
          ) : null}
        </div>
        <div className="btn">搜索</div>
      </div>
      <div className="voucher-body">
        <div className="voucher-title">
          {title}
          <Link to={"/voucher/lateplay?title=" + title} className="more">
            更多 &gt;
          </Link>
        </div>
        <div className="lateplay-main">
          <div className="items">
            {datas.map((data) => (
              <Link
                key={data.game_id}
                to={"/game?id=" + data.game_id + "&voucher=1"}
                className="item"
              >
                <div className="icon">
                  <Image src={data.game_image.thumb} />
                </div>
                <div className="info">
                  <div className="name">
                    {data.game_name_main || data.game_name}
                  </div>
                  <div className="desc">
                    {data.game_name_branch ? (
                      <div className="branch">{data.game_name_branch}</div>
                    ) : null}
                    {data.game_classify_type} · {data.gama_size.ios_size}
                  </div>
                  <div className="voucher">
                    <span>￥{data.total_amount}</span>共{data.count_amount}张券
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="voucher-title">代金券专区</div>
        <div className="voucher-main">
          <List active={props.active} kw={kw} />
        </div>
      </div>
    </div>
  );
};
