import { CloseOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../../lib/Dialog";

export default ({ url, close }) => {
  return (
    <>
      <div className="game-ios-tips" onClick={close}>
        <div className="git-container" onClick={(e) => e.stopPropagation()}>
          <div className="git-head">
            <div className="title">下载小贴士</div>
            <CloseOutlined className="close" onClick={close} />
          </div>
          <div className="git-body">
            <dl>
              <dt>1.同意“安装”游戏</dt>
              <dd>游戏将在桌面自动下载哦</dd>
            </dl>
            <dl>
              <dt>2.游戏安装成功后</dt>
              <dd>
                若需授权信任，请打开
                <span>设置→通用→设备管理→信任企业级应用授权</span>哦
              </dd>
            </dl>
            <dl>
              <dt>3.无法弹出安装弹窗</dt>
              <dd>
                请复制链接后，在“Safari”浏览器打开。
                <CopyToClipboard
                  text={url}
                  onCopy={() => Dialog.info("已复制")}
                >
                  <span className="copy">复制</span>
                </CopyToClipboard>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};
