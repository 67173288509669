import React, { useEffect } from "react";
import general from "../../general";
import { ArrowUpOutlined, FireOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useLocalStorageState } from "ahooks";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";

// 搜索推荐
export default (props) => {
  const [kws, setKws] = useLocalStorageState("search-kws", {
    defaultValue: [],
  });
  const [gameGroups, setGameGroups] = useLocalStorageState(
    "search-gamegroups",
    {
      defaultValue: [
        { text: "热搜游戏", games: [] },
        { text: "猜你想搜", games: [] },
      ],
    }
  );
  useEffect(() => {
    Dialog.loading();
    general.axios
      .get("/base/search/index", {
        headers: { username: "" },
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          return;
        }
        setKws(data.search_keyword);
        gameGroups[0].games = data.recommend_game_list || [];
        gameGroups[1].games = data.guess_youlike || [];
        console.log(data);
        setGameGroups([...gameGroups]);
        console.log("ttttttttt----------", gameGroups);
      })
      .catch((err) => {
        console.log("search " + err.message);
      });
  }, []);

  return (
    <div className={"search-cover " + (props.kw ? "" : "active")}>
      <>
        {kws.length > 0 ? (
          <>
            <div className="title">搜索热词</div>
            <div className="kws clearfix">
              {kws.map((kw, index) => (
                <div
                  key={index}
                  className="kw"
                  onClick={() => {
                    props.setKw(kw);
                    props.setIsShow(false);
                    props.setSwiperIndex(null);
                  }}
                >
                  {index == 0 ? <FireOutlined className="icon" /> : null}
                  {index == 1 ? <ArrowUpOutlined className="icon" /> : null}
                  {kw}
                </div>
              ))}
            </div>
          </>
        ) : null}
      </>

      <div className="groups clearfix">
        {gameGroups?.map((gameGroup, index) => (
          <div key={index}>
            {gameGroup.games?.length > 0 && (
              <div className="group">
                <div className="title">{gameGroup.text}</div>
                <div className="games">
                  {gameGroup.games?.map((game, index) => (
                    <Link
                      key={game.game_id}
                      to={"/game?id=" + game.game_id}
                      className="game"
                    >
                      <div className="index">{index + 1}</div>
                      <div className="icon">
                        <Image src={game.game_icon} />
                      </div>
                      <div className="info">
                        <div className="name">
                          {game.game_name_main || game.game_name}
                        </div>
                        {game.game_name_branch ? (
                          <div className="branch">{game.game_name_branch}</div>
                        ) : null}
                        <div className="desc">{game.tagsName}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
