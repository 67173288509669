import Today from "./Today";
import Future from "./Future";
import History from "./History";
import KeepScroll from "../../lib/KeepScroll";
import React, { useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import general from "../../general";
import { useLocalStorageState } from "ahooks";
import { CSSTransition } from "react-transition-group";

const router = [
  {
    id: "today",
    component: Today,
    title: "今日开服",
  },
  {
    id: "future",
    component: Future,
    title: "即将开服",
  },
  {
    id: "history",
    component: History,
    title: "历史开服",
  },
];

export default (props) => {
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState();
  useEffect(() => {
    props.setOperate(<FilterOutlined onClick={() => setTagIng(true)} />);
    general.axios
      .post("/base/game/getClassifyList")
      .finally(() => {})
      .then((response) => {
        let result = response.data.data;
        let tags = result.game_classify_list[0].sub_classify_list[0];
        setTags(
          tags.map((type) => ({
            id: type.game_classify_id,
            name: type.game_classify_name,
          }))
        );
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  }, []);
  const [type, setType] = useLocalStorageState("server-type", {
    defaultValue: 0,
  });
  const [tagIng, setTagIng] = useState(false);

  return (
    <div className="server-index">
      <div className="server-type clearfix">
        {router.map((item, index) => (
          <div
            key={index}
            className={"item " + (index === type ? "active" : "")}
            onClick={() => setType(index)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className="server-body">
        {router.map((item, index) => (
          <div
            key={index}
            className={"server-body-item " + (index === type ? "active" : "")}
          >
            <KeepScroll active={props.active && index === type ? "active" : ""}>
              <item.component
                active={props.active && index === type ? "active" : ""}
                tag={tag}
              />
            </KeepScroll>
          </div>
        ))}
      </div>
      <CSSTransition in={tagIng} timeout={250} unmountOnExit={true}>
        <div className="server-tag">
          <div className="mask" onClick={() => setTagIng(false)} />
          <div className="container">
            <div className="title">游戏分类</div>
            <div className="tags clearfix">
              <div
                className={"tag " + (!tag ? "active" : "")}
                onClick={() => {
                  setTag("");
                  setTagIng(false);
                }}
              >
                <div>全部</div>
              </div>
              {tags.map((item) => (
                <div
                  key={item.id}
                  className={"tag " + (tag === item.id ? "active" : "")}
                  onClick={() => {
                    setTag(item.id);
                    setTagIng(false);
                  }}
                >
                  <div>{item.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
