import {
  BellOutlined,
  SettingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { NavLink } from "../../lib/Navigation";
import general, { spm } from "../../general";
import dayjs from "dayjs";
import useActiveState from "../../lib/useActiveState";
import useActiveEffect from "../../lib/useActiveEffect";
import {
  useLocalStorageState,
  useLockFn,
  useMount,
  useScroll,
  useThrottleEffect,
  useUpdateEffect,
} from "ahooks";
import { useEffect, useState } from "react";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { useHistory, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Active from "../../lib/Active";
import { sysUrl } from "../../config";

SwiperCore.use([Pagination]);

export default (props) => {
  const [ed, setEd] = useState();
  useEffect(() => {
    if (props.active && !ed) {
      setEd(true);
    }
  }, [props.active]);
  const history = useHistory();
  const location = useLocation();
  const [config] = useActiveState("config");
  const [user, setUser] = useActiveState("user");
  const [app] = useActiveState("app");
  const [refreshTime, setRefreshTime] = useState(0);
  const load = useLockFn(async (parms = { background: false }) => {
    if (general.cloud) {
      return;
    }
    !parms.background && Dialog.loading();
    await general.axios
      .post("/user/user/getMemberInfo")
      .finally(() => {
        !parms.background && Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          if (status.error_code == 1016) {
            localStorage.removeItem("user_id");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("token");
          } else {
            Dialog.error(status.error_desc);
          }
        } else {
          setRefreshTime(dayjs().valueOf());
          /*if (data.member_info.member_name == 'iosceshi') {
            alert(navigator.userAgent);
          }*/
          setUser({ load: true, isLogin: true, ...data.member_info });
          if (!data.member_info.isSetPassword) {
            Dialog.close(true);
            Dialog.alert(
              "温馨提示",
              "由于您是一键登录，为了确保您的游戏体验，请设置登录密码。",
              () => history.replace("/user/password-force"),
              { btn: "修改密码" }
            );
          }
        }
      })
      .catch((error) => {
        !parms.background && Dialog.error(error.message);
      });
  });
  useMount(() => {
    if (general.isLogin()) {
      load();
    }
  });
  useActiveEffect(() => {
    if (general.isLogin()) {
      if (!user.isLogin) {
        load();
      }
    } else {
      setUser({ isLogin: false });
    }
  });

  useMount(() => {
    setTimeout(() => {
      general.isLogin() && load({ background: true });
    }, 15000);
  });

  useUpdateEffect(() => {
    if (
      location.pathname.indexOf("/screen") !== -1 &&
      dayjs().valueOf() - refreshTime >= 3000
    ) {
      general.isLogin() && load({ background: true });
    }
  }, [location, refreshTime, user]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && general.isLogin()) {
      if (scroll && scroll.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  const [messages, setMessages] = useActiveState("user-messages");
  useThrottleEffect(() => {
    if (!props.active || !user.member_id) {
      return;
    }
    general.axios
      .post("/getUnReadWarn")
      .then(({ data: resData }) => {
        let { status, data } = resData;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return null;
        }
        setMessages(data.unread_warn);
      })
      .catch((error) => console.error(error.message));
  }, [props.active, user.member_id]);

  const [hangupShow, setHangupShow] = useState(0);
  useThrottleEffect(() => {
    if (!ed) {
      return;
    }
    general.axios
      .post("/base/CloudHangingUp/isShowCloudHangingUp")
      .then(({ data: resData }) => {
        let { status, data } = resData;

        console.log("云挂机入口是否显示", data);
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        setHangupShow(data.is_show_cloud);
      })
      .catch((error) => console.error(error.message));
  }, [ed, user.member_id]);

  return (
    <>
      <Active active={props.active}>
        <div className="user-index">
          <div className="user-head">
            <div className="status-bar-background" />
            <div className="operate">
              <NavLink
                to="/messages"
                className={`item ${messages > 0 ? "active" : ""}`}
              >
                <BellOutlined className="item" />
              </NavLink>
              <NavLink to="/setting" user={false} className="item">
                <SettingOutlined className="item" />
              </NavLink>
            </div>
            <div className="container">
              <NavLink to="/user" className="user">
                <div className="icon">
                  <Image
                    src={user.icon_link || "/resources/user/avatar.png"}
                    alt=""
                  />
                  {user.is_super_member == 1 ? (
                    <>
                      <object>
                        <NavLink
                          className="vip"
                          to={`${sysUrl}/home/SuperMenber`}
                          iframe={{ title: "超级会员" }}
                        >
                          <img src={`/resources/user/super_member.png`} />
                        </NavLink>
                      </object>
                    </>
                  ) : null}
                </div>
                <div className="info">
                  {user.isLogin ? (
                    <>
                      <div className="name">
                        <div className="named">{user.nick_name}</div>
                        <object style={{ height: "35px" }}>
                          <NavLink
                            to={`${sysUrl}/home/growth`}
                            iframe={{ title: "会员中心" }}
                            className="growth"
                            style={{ backgroundImage: `url()` }}
                          >
                            <img
                              src={`/resources/user/Lv${user.growth_grade}.png?version=20220901`}
                              alt={`Lv${user.growth_grade}`}
                            />
                            <div className="receive_icon">
                              <img
                                src="/resources/user/receive_welfare.png"
                                alt=""
                              />
                            </div>
                          </NavLink>
                        </object>
                      </div>
                      <div className="account">帐号：{user.member_name}</div>
                      <div className="phone">
                        手机：{user.mobile || "未绑定"}
                      </div>
                    </>
                  ) : (
                    <div className="empty">未登录</div>
                  )}
                </div>
              </NavLink>
            </div>
          </div>
          <div className="user-economy">
            <NavLink to="/ptb" className="item">
              <div className="value">
                {user.platform_coins || 0}
                <span>个</span>
              </div>
              <div className="name">平台币 去充值&gt;</div>
            </NavLink>
            <div className="line"></div>
            <NavLink
              to={`${sysUrl}/home/flb`}
              iframe={{ title: "福利币明细" }}
              className="item"
            >
              <div className="value">
                {user.flb || 0}
                <span>个</span>
              </div>
              <div className="name">福利币 去使用&gt;</div>
            </NavLink>
            <div className="line"></div>
            <NavLink
              to="/user/voucher"
              className={`item ${user.unread_vouchers > 0 ? "active" : null}`}
            >
              <div className="value">
                {user.available_voucher || 0}
                <span>个</span>
              </div>
              <div className="name">代金券 享优惠&gt;</div>
            </NavLink>
          </div>

          <div className="user-he-entrance">
            <NavLink
              className="user-h5-every"
              to={`${sysUrl}/home/SuperMenber`}
              iframe={{ title: "超级会员" }}
            >
              <img
                className="icon"
                src="/resources/user/new/super.png"
                alt=""
              />
              <div>
                <img
                  className="title"
                  src="/resources/user/new/super-title.png"
                  alt=""
                />
                <p className="tips">专属特惠 超值性价比</p>
              </div>
            </NavLink>

            <NavLink
              to={`${sysUrl}/home/MonthCard`}
              iframe={{ title: "省钱卡" }}
              className="user-h5-every"
            >
              <img className="icon" src="/resources/user/new/card.png" alt="" />
              <div>
                <img
                  className="card"
                  src="/resources/user/new/card-title.png"
                  alt=""
                />
                <p className="tips">开通回本 每天再领5元</p>
              </div>
            </NavLink>
          </div>

          {hangupShow == 1 && (
            <NavLink to="/game/hangup">
              <div className="user-hangup">
                <div className="hangup-left">
                  <img src="/resources/user/new/cloud-title.png" alt="" />
                  <span>24小时在线挂机，省时又省心</span>
                </div>

                <RightOutlined style={{ color: "rgba(102, 98, 192, 0.3)" }} />
              </div>
            </NavLink>
          )}

          <div className="user-general">
            <div className="title">常用功能</div>
            <div className="items clearfix">
              <NavLink to="/rebate" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/rebate.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">申请返利</div>
              </NavLink>
              <NavLink
                to="/user/gift"
                className={`item ${user.unread_packs > 0 ? "active" : null}`}
                onClick={() => setUser({ ...user, unread_packs: 0 })}
              >
                <div className="icon">
                  <img src="/resources/user/gift.png?version=20220907" alt="" />
                </div>
                <div className="text">我的礼包</div>
              </NavLink>
              <NavLink to="/article" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/activity.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">精彩活动</div>
              </NavLink>
              <NavLink
                to={`https://m-app.9917.cn/pages/recharge_transfer/index.html?version=1`}
                iframe={{ title: "充值转游" }}
                className="item"
              >
                <div className="icon">
                  <img src="/resources/user/move.png?version=20220907" alt="" />
                </div>
                <div className="text">充值转游</div>
              </NavLink>
              <NavLink to="/recovery" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/recovery.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">小号回收</div>
              </NavLink>
              <NavLink to="/service" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/service.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">联系客服</div>
              </NavLink>
              <NavLink to="/service" className="item">
                <div className="icon">
                  <img src="/resources/user/help.png?version=20220907" alt="" />
                </div>
                <div className="text">帮助中心</div>
              </NavLink>
              <NavLink
                className={`item ${
                  config.udid_usable &&
                  config.udid_usable != localStorage.getItem("udid_usable")
                    ? "active"
                    : ""
                }`}
                to="/user/game"
                onClick={() => spm(`user.user.game`)}
              >
                <div className="icon">
                  <img src="/resources/user/game.png?version=20220907" alt="" />
                </div>
                <div className="text">我的游戏</div>
              </NavLink>
              {/*<NavLink to={`${sysUrl}/home/bindaccount`} iframe={{title: '绑定微信'}} className="item">
              <div className="icon"><img src="/resources/user/wx.png" alt=""/></div>
              <div className="text">绑定微信</div>
            </NavLink>*/}
            </div>
          </div>
          <div className="user-more">
            <div className="title">更多功能</div>
            <div className="items clearfix">
              <NavLink to="/user" className="item">
                <div className="icon">
                  <img src="/resources/user/save.png?version=20220907" alt="" />
                </div>
                <div className="text">帐号安全</div>
              </NavLink>
              <NavLink to="/user/favorite" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/favorite.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">我的收藏</div>
              </NavLink>
              <NavLink to="/user/bill" className="item">
                <div className="icon">
                  <img src="/resources/user/bill.png?version=20220907" alt="" />
                </div>
                <div className="text">货币明细</div>
              </NavLink>
              <div className="item" onClick={() => Dialog.info("暂无QQ群")}>
                <div className="icon">
                  <img src="/resources/user/qq.png?version=20220907" alt="" />
                </div>
                <div className="text">加入QQ群</div>
              </div>
              <NavLink to="/feedback" className="item">
                <div className="icon">
                  <img
                    src="/resources/user/feedback.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">投诉反馈</div>
              </NavLink>

              <NavLink
                to={`${sysUrl}/home/Fissionactivity?invite=${
                  user.member_name
                }&agent=${app.agent}&time=${dayjs().valueOf()}`}
                iframe={{ title: "邀好友，赚奖励" }}
                user={false}
                token={false}
                className="item"
              >
                <div className="icon">
                  <img
                    src="/resources/user/invite.png?version=20220907"
                    alt=""
                  />
                </div>
                <div className="text">邀请好友</div>
              </NavLink>
            </div>
          </div>
        </div>
      </Active>
    </>
  );
};
