import React, { useCallback, useEffect, useRef, useState } from "react";
import general from "../../general";
import dayjs from "dayjs";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useUpdateEffect, useScroll, useThrottleEffect } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default ({ active, tag }) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [datas, setDatas] = useState([]);
  const [time, setTime] = useState();
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios
      .post("/base/game/getKaiFuList", {
        kf_type: 10,
        game_classify_id: tag,
        pagination: { count: 999999, page: 1 },
      })
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data;
        let datas = [];
        let tmp;
        let now = dayjs().format("HH");
        result.data.kaifu_list.forEach((item) => {
          let time = dayjs.unix(item.kaifu_start_date).format("HH:mm");
          let index = datas.findIndex((item) => item.time === time);
          if (index === -1) {
            datas.push({ time: time, datas: [] });
            index = datas.length - 1;
            if (!tmp && time.slice(0, 2) === now) {
              tmp = time;
            }
          }
          datas[index].datas.push(item);
        });
        setDatas(datas);
        setTime(tmp);
      })
      .catch((err) => {
        Dialog.error("getKaiFuList " + err.message);
      });
  };
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);
  useUpdateEffect(() => {
    if (active) {
      load();
    }
  }, [tag]);

  const scroll = useScroll();
  const [mode, setMode] = useState();
  const timesRef = useRef(null);
  const [timeEl, setTimeEl] = useState();
  const timeRef = useCallback((node) => {
    if (node) {
      setTimeEl(node);
    }
  }, []);
  const groupsRef = useRef(null);
  const [groupEl, setGroupEl] = useState();
  const groupRef = useCallback((node) => {
    if (node) {
      setGroupEl(node);
    }
  }, []);
  useEffect(() => {
    if (time && timesRef.current && timeEl && groupEl) {
      $(timesRef.current).animate(
        { scrollLeft: timeEl.offsetLeft - $(timesRef.current).width() / 2 },
        250
      );
      if (mode !== "scroll") {
        $("html,body").animate(
          {
            scrollTop:
              groupEl.offsetTop -
              (timesRef.current.offsetTop + timesRef.current.offsetHeight),
          },
          250
        );
      }
    }
  }, [time, mode, timesRef, timeEl, groupEl]);
  useThrottleEffect(
    () => {
      if (active && datas.length > 0) {
        datas.forEach((data, index) => {
          datas[index].in =
            Math.abs(scroll.top - $(".server-group").eq(index).offset().top) <
            1024;
        });
        setDatas([...datas]);
      }
      /*if (groupsRef.current) {
      let index = datas.findIndex((item, index) => {

      });
      console.log(index);
    }*/
    },
    [active, scroll, groupsRef],
    { wait: 77 }
  );

  return (
    <div className="server-today">
      <div className="server-time" ref={timesRef}>
        {datas.map((item, index) => (
          <div
            key={index}
            className={"item" + (time === item.time ? " active" : "")}
            ref={time === item.time ? timeRef : null}
            onClick={() => {
              setTime(item.time);
              setMode("direct");
            }}
          >
            {item.time}
          </div>
        ))}
      </div>
      <div className="today-body">
        <div className="server-groups" ref={groupsRef}>
          {datas.map((item, index) => (
            <div
              key={index}
              className="server-group"
              ref={time === item.time ? groupRef : null}
            >
              <div className="group-title">
                <FieldTimeOutlined className="icon" />
                {item.time}
              </div>
              <div
                className="group-body"
                style={{ minHeight: 78 * item.datas.length }}
              >
                {item.in
                  ? item.datas.map((game) => (
                      <Link
                        key={game.game_id}
                        to={"/game?id=" + game.game_id}
                        className="server-item"
                      >
                        <div className="icon">
                          <Image src={game.game_image.thumb} />
                        </div>
                        <div className="data">
                          <div className="name">
                            {game.game_name_main || game.game_name}
                          </div>
                          <div className="desc">
                            {game.game_name_branch ? (
                              <div className="branch">
                                {game.game_name_branch}
                              </div>
                            ) : null}
                            {game.game_classify_type}
                            &nbsp;·&nbsp;
                            {game.game_size?.ios_size}
                          </div>
                          <div className="info">{game.kaifu_name}</div>
                        </div>
                        <div className="btn">下载</div>
                      </Link>
                    ))
                  : null}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="view-end">别撩啦！已经到底啦~</div>
    </div>
  );
};
