import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import {
  useLocalStorageState,
  useLockFn,
  useReactive,
  useScroll,
} from "ahooks";
import KeepScroll from "../../lib/KeepScroll";
import dayjs from "dayjs";
import { NavLink } from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

const types = [
  {
    id: "can",
    text: "待赎回",
  },
  {
    id: "ed",
    text: "已赎回",
  },
  {
    id: "expire",
    text: "已过期",
  },
];

export default (props) => {
  const [typeIndex, setTypeIndex] = useLocalStorageState("recovery-type", {
    defaultValue: 0,
  });
  const datas = useReactive(
    types.map((item) => ({
      id: item.id,
      text: item.text,
      init: false,
      loading: null,
      page: 1,
      more: null,
      datas: [],
    }))
  );
  const load = useLockFn(async (parms = {}) => {
    let data = datas[typeIndex];
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      pagination: { count: 20, page: data.page },
    };
    if (data.id === "can") {
      tmp.isRedeem = true;
    }
    if (data.id === "ed") {
      tmp.isRedeemed = true;
    }
    if (data.id === "expire") {
      tmp.isRedeem = false;
    }
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios
      .post("/recycledAlts", tmp)
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then((res) => {
        data.init = true;
        let {
          status,
          data: { list, paginated },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (parms.page) {
            data.page = parms.page;
          }
        }
        data.datas =
          parms.page === 1 || !parms.page ? list : [...data.datas, ...list];
        data.more = paginated.more && list.length > 0;
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);
  useEffect(() => {
    if (props.active) {
      load({ page: 1 });
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        data.init &&
        data.more
      ) {
        load({ page: data.page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [data, setData] = useState();
  const [confirm, setConfirm] = useState();
  const [confirmed, setConfirmed] = useState();

  return (
    <div className="recovery-record">
      <div className="record-head">
        {types.map((item, index) => (
          <div
            key={index}
            className={"item" + (index === typeIndex ? " active" : "")}
            onClick={() => setTypeIndex(index)}
          >
            {item.text}
          </div>
        ))}
      </div>
      <div className="record-body">
        {datas.map((type, index) => (
          <div
            key={index}
            className={"type-item" + (index === typeIndex ? " active" : "")}
          >
            <KeepScroll active={index === typeIndex}>
              {type.datas.map((item, index) => (
                <div
                  key={index}
                  className={"record-item " + type.id}
                  onClick={() => {
                    if (type.id === "can") {
                      setData(item);
                      setConfirm(true);
                    }
                  }}
                >
                  <div className="icon">
                    <Image src={item.game.img.thumb} alt="" />
                  </div>
                  <div className="subject">
                    <div className="info">
                      <div className="name">
                        {item.game.name_main || item.game.name}
                      </div>
                      <div className="desc">
                        小号昵称:<span>{item.alt.alias}</span>
                      </div>
                      <div className="desc">
                        回收成功金额:<span>{item.recycledAmount}</span>平台币
                      </div>
                    </div>
                    <div className="operate">
                      <div className="price">
                        <span>{item.redeemdAmount}元</span>(赎回应付)
                      </div>
                      <div className="time">
                        {type.id === "ed" ? (
                          <>
                            赎回时间:
                            {dayjs
                              .unix(item.redeemedTime)
                              .format("MM-DD HH:mm")}
                          </>
                        ) : (
                          <>
                            过期时间:
                            {dayjs
                              .unix(item.redeemEndTime)
                              .format("MM-DD HH:mm")}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {type.datas.length === 0 && type.init ? (
                <div className="recovery-empty">
                  <img src="/resources/recovery/empty.png" alt="" />
                  暂无数据
                </div>
              ) : null}
            </KeepScroll>
          </div>
        ))}
      </div>
      {confirm ? (
        <div className="recovery-confirm">
          <div
            className="confirm-container"
            style={{
              backgroundImage: "url(/resources/recovery/confirm-bg.png)",
            }}
          >
            <div className="confirm-head">
              <div className="title">确认赎回小号</div>
              <div className="info">赎回该小号需8. 64元</div>
            </div>
            <div className="confirm-body">
              <div className="title">
                <img src="/resources/recovery/title.png" alt="" />
                赎回须知
              </div>
              <div className="content">
                <p>回收后72小时内可赎回，逾期不可赎回。</p>
                <p>
                  赎回小号仅支持现金赎回，需额外支付该小号充值金额的3%
                  作为赎回手续费。（例：若小号充值满100元，回收后可获得
                  50积分。若用户需要赎回，则需支付5+3=8元）。
                </p>
                <p>如有任何疑问，可联系客服咨询！</p>
              </div>
            </div>
            <div className="confirm-foot">
              <div className="input">
                <input
                  type="radio"
                  checked={confirmed}
                  onChange={() => setConfirmed(true)}
                />
                我已阅读
              </div>
              <div className="btns">
                <div className="btn" onClick={() => setConfirm(false)}>
                  我再想想
                </div>
                <NavLink
                  to={data.redeemUrl}
                  iframe={{ title: "赎回" }}
                  className={"btn " + (confirmed ? " confirm" : "")}
                  onClick={(e) => {
                    if (confirmed) {
                      setConfirm(false);
                    } else {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  确认赎回
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
