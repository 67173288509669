import React, { useEffect, useState } from "react";
import general from "../../general";
import dayjs from "dayjs";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useUpdateEffect, useLockFn, useScroll } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

let days = [];
for (let i = 0; i < 5; i++) {
  days.push(dayjs().add(i, "days").format("YYYY-MM-DD"));
}

export default ({ active, tag }) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [day, setDay] = useState(days[0]);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {
      kf_type: 20,
      kf_start_time: dayjs(day).unix(),
      game_classify_id: tag,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    general.axios
      .post("/base/game/getKaiFuList", data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { paginated, kaifu_list: list } = data;
        let tmpDatas = parms.page === 1 || !parms.page ? [] : [...datas];
        list.forEach((item) => {
          let time = dayjs.unix(item.kaifu_start_date).format("HH:mm");
          let index = tmpDatas.findIndex((item) => item.time === time);
          if (index === -1) {
            tmpDatas.push({ time: time, datas: [] });
            index = tmpDatas.length - 1;
          }
          tmpDatas[index].datas.push(item);
        });
        console.log(tmpDatas);
        setDatas(tmpDatas);
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("getKaiFuList " + err.message);
      });
  });
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);
  useUpdateEffect(() => {
    if (active) {
      $("html,body").scrollTop(0);
      load();
    }
  }, [tag, day]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, active]);

  return (
    <div className="server-today">
      <div className="server-time">
        {days.map((item, index) => (
          <div
            key={index}
            className={"item" + (item === day ? " active" : "")}
            onClick={() => setDay(item)}
          >
            {dayjs(item).format("MM-DD")}
          </div>
        ))}
      </div>
      <div className="future-body">
        <div className="server-groups">
          {datas.map((item, index) => (
            <div key={index} className="server-group">
              <div className="group-title">
                <FieldTimeOutlined className="icon" />
                {item.time}
              </div>
              <div className="group-body">
                {item.datas.map((game) => (
                  <Link
                    key={game.game_id}
                    to={"/game?id=" + game.game_id}
                    className="server-item"
                  >
                    <div className="icon">
                      <Image src={game.game_image.thumb} />
                    </div>
                    <div className="data">
                      <div className="name">
                        {game.game_name_main || game.game_name}
                      </div>
                      <div className="desc">
                        {game.game_name_branch ? (
                          <div className="branch">{game.game_name_branch}</div>
                        ) : null}
                        {game.game_classify_type}
                        &nbsp;·&nbsp;
                        {game.game_size?.ios_size}
                      </div>
                      <div className="info">{game.kaifu_name}</div>
                    </div>
                    <div className="btn">下载</div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="view-end">别撩啦！已经到底啦~</div>
    </div>
  );
};
