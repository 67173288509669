import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useThrottleEffect } from "ahooks";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/user/integralShop/myGiftList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { userGiftList: list, paginated } = data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("myGiftList " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $("html,body").scrollTop(0);
      load({ page: 1 });
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="credit-gift">
      {datas && datas.length > 0 ? (
        <>
          {datas.map((data) => (
            <Link
              key={data.game_id}
              to={"/game?id=" + data.game_id}
              className="item"
            >
              <div className="icon">
                <Image src={data.game_img} />
              </div>
              <div className="info">
                <div className="text">
                  <div className="name">{data.goods_name}</div>
                  <div className="game">
                    游戏：{data.game_name_main || data.game_name}
                  </div>
                  <div className="code">{data.cdk}</div>
                  <div className="time">
                    有效期至：
                    {dayjs
                      .unix(data.term_of_validity)
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                </div>
                <CopyToClipboard
                  text={data.cdk}
                  onCopy={() => Dialog.info("已复制")}
                >
                  <div
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    复制
                  </div>
                </CopyToClipboard>
              </div>
            </Link>
          ))}
          <div className={"pull-to-load " + (!more ? "nomore" : "")} />
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无数据~</p>
        </div>
      )}
    </div>
  );
};
