import { Fragment, useCallback, useState, useEffect } from "react";
import Good from "./type/Good";
import general, { redux } from "../../general";
import KeepScroll from "../../lib/KeepScroll";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import { useMount } from "ahooks";
import Type from "./type/Type";
import useRequest from "../../lib/useRequest";

export default (props) => {
  // 分类侧边栏选中项
  let [tag, setTag] = useState("");
  const [isShow, setIsShow] = useState(false);
  const { data: tags } = useRequest(
    () => {
      Dialog.loading();
      return general.axios
        .get("/base/appcategorize/getAppCategorize")
        .finally(() => Dialog.close())
        .then(({ data: resData }) => {
          console.log("分类列表", resData);

          if (!resData.status.succeed) {
            Dialog.error(resData.status.error_desc);
            return false;
          }

          if (resData.data && resData.data.length > 0 && !tag) {
            setTag(resData.data[0].id);
          }

          setIsShow(true);
          return resData.data;
        })
        .catch((error) => Dialog.error(error.message));
    },
    { cacheKey: "game-tags" }
  );

  const tagRef = useCallback((node) => {
    if (node) {
      if (
        node.parentNode.scrollTop + node.parentNode.offsetHeight <
          node.offsetTop ||
        node.parentNode.scrollTop +
          node.parentNode.offsetHeight +
          node.offsetHeight >
          node.offsetTop
      ) {
        $(node.parentNode).animate(
          { scrollTop: node.offsetTop - node.parentNode.offsetHeight / 2 },
          555
        );
        node.parentNode.scrollTop =
          node.offsetTop - node.parentNode.offsetHeight / 2;
      }
    }
  }, []);

  useMount(() => {
    if (general.tag) {
      tag = general.tag;
      setTag(general.tag);

      $("html,body").scrollTop(0);
    }

    redux.subscribe(() => {
      let tag2 = redux.getState().screenRouter?.tag;

      if (tag2) {
        tag = tag2;
        setTag(tag2);

        $("html,body").scrollTop(0);
      }
    });
    console.log("传递tag----------------", tag);
  });

  const dataGet = (data) => {
    if (tags && tags.length > 0) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].id == data) {
          return 6;
        }
      }
    }
  };

  useEffect(() => {
    let num2 = dataGet(tag);
    if (!num2) {
      if (tags && tags.length > 0) {
        setTag(tags[0].id);
      }
    }
  }, [tag]);

  return (
    <div className="game-index-type">
      {isShow && tag && (
        <div>
          {/* 分类左侧导航栏 */}
          <div className="types">
            {tags &&
              tags.length > 0 &&
              tags?.map((item, index) => (
                <Fragment key={index}>
                  <div
                    className={"type " + (tag == item.id ? "active" : "")}
                    onClick={() => {
                      setTag(item.id);
                    }}
                    ref={tag == item.id ? tagRef : null}
                  >
                    {item.title}
                  </div>
                </Fragment>
              ))}
          </div>
          {/* 右侧对应数据展示 */}
          <div className="mains">
            <Fragment>
              {tags &&
                tags.length > 0 &&
                tags?.map((item) => {
                  let active = props.active && item.id == tag;
                  return (
                    <KeepScroll key={item.id} active={active} lazy>
                      <div className={`main ${active ? "active" : ""}`}>
                        {item.pagetype == 1 ? (
                          <>
                            <Good active={active} tag={tag} />
                          </>
                        ) : (
                          <>
                            <Type
                              active={active}
                              tag={tag}
                              pagetype={item.pagetype}
                            />
                          </>
                        )}
                      </div>
                    </KeepScroll>
                  );
                })}
            </Fragment>
          </div>
        </div>
      )}
    </div>
  );
};
