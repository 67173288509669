import React, { useEffect, useState } from "react";
import general from "../../general";
import GiftDetail from "../gift/Detail";
import $ from "jquery";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useScroll } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [kw, setKw] = useState("");
  const [gifts, setGifts] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_info: kw,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("base/gift/getMyGiftList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setGifts((gifts) =>
          parms.page === 1 || !parms.page
            ? result.gift_list
            : [...gifts, ...result.gift_list]
        );
        setMore(result.paginated.more && result.gift_list.length > 0);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useEffect(() => {
    load({ page: 1 });
  }, [kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [gift, setGift] = useState(null);

  return (
    <div className="user-gift">
      <div className="user-gift-head">
        <div className="input">
          <SearchOutlined className="icon" />
          <input
            type="text"
            value={kw}
            placeholder="输入关键词搜索"
            onChange={(e) => setKw(e.target.value)}
          />
          {kw ? (
            <CloseOutlined className="close" onClick={() => setKw("")} />
          ) : null}
        </div>
      </div>
      <div className="user-gift-body">
        {gifts.map((gift, index) => (
          <div key={index} className="item" onClick={() => setGift(gift)}>
            <div className="icon">
              <Image src={gift.gift_image.thumb} />
            </div>
            <div className="text">
              <div className="name">
                {gift.gift_name_main || gift.gift_name}
              </div>
              <div className="desc">{gift.gift_desc}</div>
              <div className="code">{gift.gift_code}</div>
            </div>
            <CopyToClipboard
              text={gift.gift_code}
              onCopy={() => Dialog.info("已复制")}
            >
              <div className="btn" onClick={(e) => e.stopPropagation()}>
                复制
              </div>
            </CopyToClipboard>
          </div>
        ))}
        <div className={"pull-to-load " + (!more ? "nomore" : "")} />
      </div>
      {gifts.length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
      <GiftDetail gift={gift} setGift={setGift} />
    </div>
  );
};
