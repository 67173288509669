import qs from "qs";
import { Fragment, useState } from "react";
import { useMount } from "ahooks";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import { Link } from "react-router-dom";
import { NavLink } from "../../../lib/Navigation";

export default (props) => {
  const game_id = props.game.game_id;
  const [groups, setGroups] = useState([
    {
      name: "货币返利",
      articles: [
        {
          news_title: "日常货币返利，长期有效",
        },
      ],
    },
  ]);
  useMount(() => {
    Dialog.loading();
    general.axios
      .post("/articleGroups", { game_id })
      .finally(() => Dialog.close())
      .then(({ data: resData }) => {
        let { status, data } = resData;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return null;
        }
        setGroups((groups) => [...groups, ...data.groups]);
      })
      .catch((error) => Dialog.error(error.message));
  });

  return (
    <>
      <div className="game-article-group">
        {groups.map(({ id, name, articles }, index) => (
          <Fragment key={index}>
            <div className="group">
              <div className="title">
                <div className="name">{name}</div>
                {id ? (
                  <Link
                    className="opt"
                    to={`/game/article?${qs.stringify({
                      id: game_id,
                      groupId: id,
                      groupName: name,
                    })}`}
                  >
                    更多 &gt;
                  </Link>
                ) : null}
              </div>
              <div className="articles">
                {articles && articles.length > 0 ? (
                  articles.map(
                    (
                      {
                        news_id: id,
                        news_title: title,
                        news_title_main: title_main,
                      },
                      index
                    ) => {
                      let DynamicLink = id ? Link : NavLink;
                      return (
                        <Fragment key={index}>
                          <DynamicLink
                            className="article"
                            to={
                              id
                                ? `/article/detail?id=${id}`
                                : `/rebate?id=${game_id}`
                            }
                          >
                            <div className="title">{title_main || title}</div>
                            <div className="opt">
                              {id ? "立即参与" : "申请返利"}
                            </div>
                          </DynamicLink>
                        </Fragment>
                      );
                    }
                  )
                ) : (
                  <div className="article" style={{ justifyContent: "center" }}>
                    暂无该活动内容~
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
};
