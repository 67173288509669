import {useEffect, useState} from "react";

export default props => {
  const [active, setActive] = useState();
  useEffect(() => {
    if (!active && props.active) {
      setActive(props.active);
    }
  }, [props.active, active]);

  return props.active || active ? props.children : null;
}