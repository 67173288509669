import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import {useEffect, useState} from "react";
import {NavLink} from "../../../lib/Navigation";
import Dialog from "../../../lib/Dialog";
import general from "../../../general";
import useRequest from "../../../lib/useRequest";
import {useInterval, useMount} from "ahooks";

const spm = spm => general.axios.post('/spm', {spm});

export default ({game = {}, cloud, close}) => {
  useMount(() => spm(`game.${game.maiyou_gameid}.vip.reserve`));
  const [user] = useActiveState('user');
  const [mobile, setMobile] = useState(user.mobile);
  const [verify, setVerify] = useState();
  const [captcha, setCaptcha] = useState('');

  useEffect(() => {
    setVerify(!user.mobile || !(new RegExp("^" + user.mobile.replace(/\*+/, '.*') + "$")).test(mobile));
  }, [mobile]);

  const [captchaTime, setCaptchaTime] = useState(0);
  useInterval(() => {
    if (captchaTime) {
      setCaptchaTime(captchaTime - 1);
    }
  }, 1000);
  const {run: getCaptcha, loading: getCaptchaing} = useRequest(() => {
    if (!mobile) {
      return Dialog.error('请填写手机号码');
    }
    if (mobile?.length < 8) {
      Dialog.error('手机号码格式不正确');
      return;
    }
    return general.axios.post('/base/tool/getVerifyCode', {
      mobile: mobile,
      code: 'code',
      type: '09',
    })
      .then(({data: resData}) => {
        if (!resData.status.succeed) {
          Dialog.error(resData.status.error_desc);
          return false;
        }
        setCaptchaTime(60);
        return true;
      })
      .catch(error => Dialog.error(error.message));
  }, {manual: true});

  const go = play => {
    if (!mobile) {
      return Dialog.error('请填写手机号码');
    }
    if (mobile.length < 8) {
      Dialog.error('手机号码格式不正确');
      return;
    }
    if (verify && !captcha) {
      return Dialog.error('请填写验证码');
    }
    Dialog.loading();
    general.axios.post('/vipReserve', {
      game_id: game.maiyou_gameid,
      mobile,
      captcha: verify ? captcha : undefined,
      type: play ? 'cloud' : 'normal',
    })
      .finally(() => Dialog.close())
      .then(({data: resData}) => {
        if (!resData.status.succeed) {
          Dialog.error(resData.status.error_desc);
          return false;
        }
        Dialog.success('预约成功');
        setTimeout(() => {
          close();
          if (play && cloud) {
            cloud('reserve');
          }
        }, 1200)
      })
      .catch(err => Dialog.error('vipReserve ' + err.message));
  }

  return <>
    <div className="game-vip-reserve" onClick={close}>
      <div className="container" onClick={e => e.stopPropagation()}>
        <div className="img"><Image src="/resources/game/vip-reserve.png"/></div>
        <div className="desc">
          稳定版 《{game.game_name_main || game.game_name}》预计将在3天内处理完成。完成后将通过短信进行通知，收到后打开页面即可安装完成。
        </div>
        <div className="tips">等待期间你可以畅玩云游戏版 《{game.game_name_main || game.game_name}》</div>
        <div className="label">请输入接收短信的手机号：</div>
        <div className="input">
          <input type="text" placeholder="请输入手机号" value={mobile} onChange={e => setMobile(e.target.value)}/>
        </div>
        {verify ? <>
          <div className="label">请输入验证码：</div>
          <div className="input">
            <input type="text" placeholder="请输入验证码" value={captcha} onChange={e => setCaptcha(e.target.value)}/>
            <div className={`btn ${getCaptchaing || captchaTime ? 'disable' : ''}`} onClick={getCaptcha}>
              {!captchaTime && !getCaptchaing ? '获取验证码' : null}
              {getCaptchaing ? '获取中...' : null}
              {captchaTime && !getCaptchaing ? `${captchaTime}秒后重新获取` : ''}
            </div>
          </div>
        </> : null}
        <div className="btns">
          <NavLink className="btn" onClick={() => go()}>提交预约</NavLink>
          {cloud ? <NavLink className="btn" onClick={() => go(true)}>预约并畅玩云游戏</NavLink> : null}
        </div>
      </div>
    </div>
  </>;
};