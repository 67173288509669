import { useEffect, useState, useRef } from "react";
import general from "../../general";
import { Link } from "react-router-dom";
import Dialog from "../../lib/Dialog";
import { useMount } from "ahooks";
import dayjs from "dayjs";

export default (props) => {
  const [loaded, setLoaded] = useState();
  const [article, setArticle] = useState({});
  useMount(() => {
    Dialog.loading();
    // 活动详情入口过多，最好是  该接口返回游戏名称信息
    general.axios
      .post("/base/news/getNewsDetail", { news_id: props.query.id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("活动详情---------", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setLoaded(true);
        let result = /<body[^>]*?>([\s\S]*)<\/body>/g.exec(
          data.news_info.content
        );
        if (result && result[1]) {
          data.news_info.content = result[1];
        }
        setArticle(data.news_info);
      })
      .catch((err) => {
        Dialog.error("getNewsDetail " + err.message);
      });
  });
  // useEffect(() => {
  //   if (article) {
  //     props.setTitle(article.title_main || article.title);
  //   }
  // }, [article]);

  let [paddingTopVal, setPaddingTopVal] = useState(0);
  const articleTop = useRef(null);
  useEffect(() => {
    console.log("heihgt---------", articleTop.current.clientHeight);
    paddingTopVal = articleTop.current.clientHeight + 10;
    setPaddingTopVal(articleTop.current.clientHeight + 10);

    console.log("元素的高度:", paddingTopVal);
  }, [article]);

  return (
    <div className="article-detail">
      <div className="article-top" ref={articleTop}>
        <div className="article-head">
          <div className="head-every" style={{ alignItems: "center" }}>
            <div className="title">游戏名称：</div>
            <div className="name">{article.name}</div>
          </div>

          <div className="head-every">
            <div className="title">活动名称：</div>
            <div className="name">{article.title}</div>
          </div>

          <div className="head-every">
            <div className="title">领取方式：</div>
            <div className="name">
              {article.payment === 1 ? "自动发放" : "联系客服领取"}
            </div>
          </div>

          <div className="head-every">
            <div className="title">活动时间：</div>
            <div className="name">
              {article.deadline == 1
                ? "永久"
                : `${
                    article.creat_date
                      ? dayjs
                          .unix(article.creat_date)
                          .format("YYYY[年]M[月]D[日]")
                      : ""
                  }-${
                    article.deadline
                      ? dayjs
                          .unix(article.deadline)
                          .format("YYYY[年]M[月]D[日]")
                      : ""
                  }`}
            </div>
          </div>
        </div>
      </div>

      <div
        className="article"
        style={{
          paddingTop: `${paddingTopVal}px`,
        }}
      >
        <div className="article-content-title">活动内容</div>

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: article.content || null }}
        />
      </div>
      {loaded ? <div className="view-end">别撩啦！已经到底啦~</div> : null}

      <div className="service-contact">
        <Link to="/service" className="contact">
          联系客服
        </Link>
      </div>
    </div>
  );
};
