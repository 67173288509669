import {useLocalStorageState} from "ahooks";

let ts = {};

export default (key, options) => {
  if (window.location.href.indexOf('retry-cache') > 0 && !ts[key]) {
    window.localStorage.removeItem(key);
    ts[key] = true;
    console.warn(`retry-cache ${key}`);
  }
  return useLocalStorageState(key, options);
}