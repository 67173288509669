import React, {useContext, useEffect} from "react";
import ActiveContext from "./ActiveContext";

const useActiveEffect = (effect, deps) => {
  const theme = useContext(ActiveContext);
  useEffect(() => {
    if (theme) {
      return effect();
    }
  }, [theme]);
};

export default useActiveEffect;