import { useEffect } from "react";
export default (props) => {
  useEffect(() => {
    props.setTitle(props.query.game + "VIP表");
  }, [props]);

  const vipWatchContent = localStorage.getItem("vipWatchContent");

  return (
    <>
      <div className="game-vip-content">
        {vipWatchContent ? (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: vipWatchContent }}
          />
        ) : (
          <div
            style={{
              padding: "10px 0",
              textAlign: "center",
              border: "1px solid #000",
            }}
          >
            无VIP
          </div>
        )}
      </div>
    </>
  );
};
