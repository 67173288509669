import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import general from "../../general";
import $ from "jquery";
import { useReactive, useScroll } from "ahooks";
import { Link, useHistory } from "react-router-dom";
import KeepScroll from "../../lib/KeepScroll";
import Explain from "../voucher/Explain";
import Dialog from "../../lib/Dialog";
import { NavLink } from "../../lib/Navigation";
import { sysUrl } from "../../config";
import useActiveState from "../../lib/useActiveState";

const types = [
  {
    id: "usable",
    text: "可使用",
  },
  {
    id: "used",
    text: "已使用",
  },
  {
    id: "expire",
    text: "已过期",
  },
];

export default (props) => {
  const [app] = useActiveState("app");
  const [typeIndex, setTypeIndex] = useState(0);
  const datas = useReactive(
    types.map((item) => ({
      id: item.id,
      text: item.text,
      init: false,
      loading: null,
      page: 1,
      more: null,
      datas: [],
    }))
  );
  const load = (parms = {}) => {
    let data = datas[typeIndex];
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    let tmp = {
      pagination: { count: 20, page: data.page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    if (data.id === "usable") {
      tmp.is_expired = false;
      tmp.is_used = false;
    } else if (data.id === "used") {
      tmp.is_used = true;
    } else if (data.id === "expire") {
      tmp.is_expired = true;
    }
    general.axios
      .post("/receivedVouchers", tmp)
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then((res) => {
        data.init = true;
        let result = res.data.data;
        if (parms.page) {
          data.page = parms.page;
        }
        data.datas =
          parms.page === 1 || !parms.page
            ? result.list
            : [...data.datas, ...result.list];
        data.more = result.paginated.more;
      })
      .catch((err) => {
        Dialog.error("receivedVouchers " + err.message);
      });
  };
  useEffect(() => {
    if (props.active && !datas[typeIndex].init) {
      load();
    }
  }, [props.active, typeIndex]);

  const scroll = useScroll();
  useEffect(() => {
    let data = datas[typeIndex];
    if (props.active && data.init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        data.init
      ) {
        load({ page: data.page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [explain, setExplain] = useState();

  return (
    <div className="user-voucher">
      <div className="user-voucher-head">
        <div className="items">
          {types.map((item, index) => (
            <div
              key={index}
              className={"item" + (index === typeIndex ? " active" : "")}
              onClick={() => setTypeIndex(index)}
            >
              {item.text}
            </div>
          ))}
        </div>
      </div>
      <div className="user-voucher-body">
        {datas.map((item, index) => (
          <div
            key={index}
            className={"type-item" + (index === typeIndex ? " active" : "")}
          >
            <KeepScroll active={index === typeIndex}>
              {item.datas.length === 0 && item.init ? (
                <div
                  className="empty"
                  style={{
                    backgroundImage: "url(/resources/voucher/empty.png)",
                  }}
                />
              ) : null}
              {item.datas.map((voucher, index) => (
                <div
                  key={index}
                  className={
                    "voucher-item " +
                    item.id +
                    (item.id !== "usable" ? " invalid" : "")
                  }
                  style={{
                    backgroundImage:
                      "url(/resources/voucher/" +
                      (item.id === "usable" ? "normal" : "invalid") +
                      ".png)",
                  }}
                >
                  <div className="amounts">
                    <div className="amount">￥{voucher.amount * 1}</div>
                    <div className="amount-meet">
                      满{voucher.meet_amount * 1}元可用
                    </div>
                  </div>
                  <div className="info">
                    <div className="name">{voucher.name}</div>
                    <div className="time">
                      {dayjs.unix(voucher.start_time).format("YYYY-MM-DD")} ~{" "}
                      {dayjs.unix(voucher.end_time).format("YYYY-MM-DD")}
                    </div>
                    <div className="game">
                      仅限《{voucher.game_name_main || voucher.game_name}》
                    </div>
                    <div className="use" onClick={() => setExplain(voucher)}>
                      使用方法&gt;
                    </div>
                  </div>
                  {voucher.use_target == "superMember" ? (
                    <>
                      <NavLink
                        className="operate"
                        to={`${sysUrl}/home/SuperMenber`}
                        iframe={{ title: "超级会员" }}
                      />
                    </>
                  ) : (
                    <>
                      {voucher.use_target == "monthcard" ? (
                        <>
                          <NavLink
                            className="operate"
                            to={`${sysUrl}/home/monthCard`}
                            iframe={{ title: "省钱卡" }}
                          />
                        </>
                      ) : (
                        <>
                          <Link
                            to={
                              voucher.game_id
                                ? "/game?mid=" + voucher.game_id
                                : "/screen-game"
                            }
                            className="operate"
                            onClick={() => {
                              if (
                                !(
                                  voucher.is_used == "0" &&
                                  voucher.is_expired == "0"
                                )
                              ) {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
              {item.datas.length > 0 && !item.more ? (
                <div className="view-end">别撩啦！已经到底啦~</div>
              ) : null}
            </KeepScroll>
          </div>
        ))}
      </div>
      {explain ? <Explain voucher={explain} setVoucher={setExplain} /> : null}
    </div>
  );
};
