import { useEffect } from "react";
import general from "../general";
import { BulbOutlined } from "@ant-design/icons";

export default (props) => {
  let faqGroup = general.faqGroups[props.query.group];
  let faqs = faqGroup.items;
  useEffect(() => {
    props.setTitle(faqGroup.text);
  }, []);

  return (
    <div className="faq-index">
      {faqs.map((faq, index) => (
        <dl key={index}>
          <dt>
            <BulbOutlined className="icon" />
            {faq.q}
          </dt>
          <dd>{faq.a}</dd>
        </dl>
      ))}
    </div>
  );
};
