import { useState } from "react";
import KeepScroll from "../../../lib/KeepScroll";
import PlayGame from "./PlayGame";
import FavoriteGame from "./FavoriteGame";
import FavoriteTrade from "./FavoriteTrade";

const types = [
  /*{
    id: 'play-game',
    text: '正在玩',
    component: PlayGame,
  },*/
  {
    id: "favorite-gmae",
    text: "游戏收藏",
    component: FavoriteGame,
  },
  {
    id: "favorite-trade",
    text: "交易收藏",
    component: FavoriteTrade,
  },
];

export default (props) => {
  const [typeIndex, setTypeIndex] = useState(0);

  return (
    <div className="user-game">
      <div className="ug-head">
        {types.map((item, index) => (
          <div
            key={index}
            className={"item" + (index === typeIndex ? " active" : "")}
            onClick={() => setTypeIndex(index)}
          >
            {item.text}
          </div>
        ))}
      </div>
      <div className="ug-body">
        {types.map((item, index) => (
          <div
            key={index}
            className={"type-item" + (index === typeIndex ? " active" : "")}
          >
            <KeepScroll
              active={props.active && index === typeIndex ? "active" : ""}
              lazy
            >
              <item.component
                active={props.active && index === typeIndex ? "active" : ""}
                action={props.action}
              />
            </KeepScroll>
          </div>
        ))}
      </div>
    </div>
  );
};
