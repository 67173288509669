import { useEffect, useState } from "react";
import general from "../../general";
import { useLockFn, useScroll, useUpdateEffect } from "ahooks";
import UserPhoneVerify from "../util/UserPhoneVerify";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const load = useLockFn(async () => {
    Dialog.loading();
    await general.axios
      .post("/recyclableAlts", { gameId: props.query.id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (list.length > 0) {
            let amount = 0;
            list.forEach((item) => {
              amount += item.recycledCoin;
            });
            setData({
              ...list[0].game,
              ...general.game,
              count: list.length,
              amount: amount,
            });
          } else {
            setData({});
          }
          setDatas(list);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  const [verify, setVerify] = useState();
  const recovery = useLockFn(async () => {
    let id = [];
    datas.forEach((item) => {
      if (item.checked) {
        id.push(item.id);
      }
    });
    if (id.length < 1) {
      Dialog.error("请选择小号");
      return;
    }
    if (!verify) {
      setVerify(true);
      return;
    }
    Dialog.loading();
    await general.axios
      .post("/recycleAlt", { id: id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("回收成功", () => {
            setVerify(false);
            load();
          });
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });

  return (
    <div className="recovery-detail">
      <div className="recovery-info">
        <div className="icon">
          <Image src={data.img?.thumb} alt="" />
        </div>
        <div className="info">
          <div className="name">{data.name_main || data.name}</div>
          <div className="desc">
            满足回收小号：<span>{data.count || 0}</span>个 可回收总金额：
            <span>{data.amount || 0}</span>平台币
          </div>
        </div>
      </div>
      <div className="recovery-thead">
        <div className="th">小号昵称</div>
        <div className="th">累计金额</div>
        <div className="th">可回收金额</div>
        <div className="th">操作</div>
      </div>
      <div className="recovery-tbody">
        {datas.map((item, index) => (
          <div key={index} className="tr">
            <div className="td">{item.alias}</div>
            <div className="td">{item.rechargedAmount}元</div>
            <div className="td">{item.recycledCoin}平台币</div>
            <div className="td">
              <input
                type="checkbox"
                value="1"
                checked={item.checked}
                onChange={(e) => {
                  setDatas((datas) => {
                    datas[index].checked = e.target.checked;
                    return [...datas];
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="recovery-operate">
        <div
          className={
            "btn" +
            (datas.findIndex((item) => item.checked) !== -1 ? " active" : "")
          }
          onClick={recovery}
        >
          确认回收
        </div>
      </div>
      <UserPhoneVerify
        show={verify}
        type="05"
        close={() => setVerify(false)}
        onSuccess={recovery}
      />
    </div>
  );
};
