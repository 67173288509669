import React, { useEffect, useState } from "react";
import { useLockFn, useScroll } from "ahooks";
import general from "../../general";
import $ from "jquery";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {
      pagination: { page: page },
    };
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    general.axios
      .post("/user/rebate/getRebateList", data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { paginated, rebate_list: list } = data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="rebate-record">
      {datas.map((data) => (
        <Link
          key={data.rebate_id}
          to={"/rebate/detail?id=" + data.rebate_id}
          className="item"
        >
          <div className="main">
            <div className="icon">
              <Image src={data.game_img} alt="" />
            </div>
            <div className="info">
              <div className="name">
                {data.game_name_main || data.game_name}
              </div>
              <div className="time">
                处理时间：
                {dayjs.unix(data.handle_time).format("YYYY-MM-DD HH:mm:ss")}
              </div>
              <div className="amount">
                充值金额：<span>{data.recharge_amount}元</span>
              </div>
            </div>
          </div>
          <div className="operate">
            <div className="state">
              状态：
              {(() => {
                if (data.status === "-1") {
                  return <span>驳回</span>;
                } else if (data.status === "0") {
                  return "待审核";
                } else if (data.status === "1") {
                  return "审核完成待处理";
                } else if (data.status === "2") {
                  return "完成";
                }
              })()}
            </div>
            <div className="btn">详情</div>
          </div>
          {data.remark ? <div className="remark">{data.remark}</div> : null}
        </Link>
      ))}
    </div>
  );
};
