import useActiveState from "../../../lib/useActiveState";
import {useMount} from "ahooks";
import general from "../../../general";
import useUrlState from "@ahooksjs/use-url-state";
import Intercept from "./Intercept";
import Require from "./Require";
import {useState} from "react";

export default ({game, type, close, callback, superGo, cloud}) => {
  const [able, setAble] = useState();
  const [{key}] = useUrlState();
  const [udid] = useActiveState('udid');

  useMount(() => {
    if (type == 'cloud' && !game.cloud_support_type) {
      close();
      return;
    }
    setAble(true);
    if (key && udid) {
      console.warn('auto', {key, udid});
      general.axios.post('/base/newindex/getKeyUdid', {key, udid, version: 2}).catch(error => console.error(error));
    }
  });

  return <>
    {!!able && <Require reject={close}>
      <Intercept game={game} type={type} close={close} callback={callback} superGo={superGo} cloud={cloud}/>
    </Require>}
  </>;
}