import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RetweetOutlined } from "@ant-design/icons";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useUpdateEffect } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  useEffect(() => {
    props.setOperate(
      <Link to="/recovery/record" className="recovery-record">
        回收记录
      </Link>
    );
  }, []);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [tipsFont, setTipsFont] = useState();
  const load = useLockFn(async (parms = {}) => {
    Dialog.loading();
    let tmp = {
      pagination: { page: page, count: 20 },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    await general.axios
      .post("/recycleHint")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTipsFont(list);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
    await general.axios
      .post("/recyclableAltGames", tmp)
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list, paginated },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setLoaded(true);
          if (parms.page) {
            setPage(parms.page);
          }
          setDatas((datas) =>
            parms.page === 1 || !parms.page ? list : [...datas, ...list]
          );
          setMore(paginated.more && list.length > 0);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    if (props.active) {
      load({ page: 1 });
    }
  }, [props.active]);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="recovery-index">
      <div className="recovery-step">
        <img src="/resources/recovery/step.png" alt="" />
      </div>
      <div className="recovery-explain">
        <div className="title">回收须知</div>
        {tipsFont?.huishouxuzhi?.map((item, index) => (
          <div className="item" key={index}>
            {item}
          </div>
        ))}
      </div>
      <div className="recovery-tips">
        <div className="title">温馨提示</div>
        {tipsFont?.wenxintishi?.map((item, index) => (
          <div className="item" key={index}>
            {item}
          </div>
        ))}
      </div>
      <div className="recovery-title">可回收小号</div>
      <div className="recovery-items">
        {datas.map((item) => (
          <Link
            key={item.id}
            to={"/recovery/select?id=" + item.id}
            className="item"
            onClick={() => {
              general.game = { img: item.img };
            }}
          >
            <div className="icon">
              <Image src={item.img.thumb} alt="" />
            </div>
            <div className="info">
              <div className="name">{item.name_main || item.name}</div>
              <div className="desc">
                可回收小号：<span>{item.recyclable}</span>个
              </div>
              <div className="desc">
                历史累计充值：<span>{item.rechargedAmount}</span>元
              </div>
            </div>
            <div className="price">
              <span>{item.recyclableCoin}平台币</span>（回收可得）
            </div>
          </Link>
        ))}
      </div>
      {datas.length === 0 && loaded ? (
        <div className="recovery-empty">
          <img src="/resources/recovery/empty.png" alt="" />
          暂无可回收小号
        </div>
      ) : null}
    </div>
  );
};
