import { CommentOutlined, LikeOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useUpdateEffect } from "ahooks";
import { NavLink } from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { Link } from "react-router-dom";

export default (props) => {
  const [commentLoading, setCommentLoading] = useState();
  const [comment, setComment] = useState({});
  const commentLoad = () => {
    if (commentLoading) {
      return;
    }
    setCommentLoading(true);
    Dialog.loading();
    general.axios
      .post("/base/comment/getCommentDetail", { id: props.query.id })
      .finally(() => {
        setCommentLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setComment(data);
      })
      .catch((err) => {
        Dialog.error("getCommentDetail " + err.message);
      });
  };
  useEffect(() => {
    commentLoad();
  }, []);

  const [replyLoading, setReplyLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const replyLoad = (parms = {}) => {
    if (replyLoading) {
      return;
    }
    setReplyLoading(true);
    Dialog.loading();
    let tmp = {
      comment_id: props.query.id,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/base/comment/getCommentListForComment", tmp)
      .finally(() => {
        setReplyLoading(false);
        Dialog.close();
      })
      .then((res) => {
        if (parms.page) {
          setPage(parms.page);
        }
        let {
          status,
          data: { comment_list: list, paginated },
        } = res.data;

        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setDatas((datas) =>
            parms.page === 1 || !parms.page ? list : [...datas, ...list]
          );

          setMore(paginated.more && list.length > 0);
        }
      })
      .catch((err) => {
        Dialog.error("getCommentListForComment " + err.message);
      });
  };
  useEffect(() => {
    replyLoad();
  }, []);
  useEffect(() => {
    props.setTitle(datas.length + "条回复");
  }, [datas]);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        commentLoad();
        replyLoad({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        replyLoad({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const like = useLockFn(async (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    let data = index?.id === comment.id ? comment : datas[index];
    if (parseInt(data.me_like)) {
      return;
    }
    await general.axios
      .post("/base/comment/likeComment", { id: data.id })
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (data.id === comment.id) {
            setComment((data) => ({
              ...data,
              me_like: 1,
              like_count: parseInt(data.like_count) + 1,
            }));
          } else {
            setDatas((datas) => {
              datas[index] = {
                ...datas[index],
                me_like: 1,
                like_count: parseInt(datas[index].like_count) + 1,
              };
              return [...datas];
            });
          }
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  const ref = useRef(null);
  const onReply = (reply) => {
    setReply(reply);
    if (ref) {
      ref.current.focus();
    }
  };
  const [content, setContent] = useState("");
  const [reply, setReply] = useState();
  const [inputing, setInputing] = useState();
  const submit = useLockFn(async () => {
    Dialog.loading();
    await general.axios
      .post("/base/comment/commentForComment", {
        comment_id: comment.id,
        reply_id: reply?.id ?? null,
        content: content,
      })
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("操作成功", () => {
            setContent("");
            commentLoad();
            replyLoad({ page: 1 });
          });
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });

  return (
    <div className="comment-detail">
      {comment.reward_intergral_amount ? (
        <div
          className="comment-reward"
          style={{ backgroundImage: "url(/resources/comment/reward.png)" }}
        >
          {comment.reward_intergral_amount}
        </div>
      ) : null}

      <div className="comment-user">
        <div className="avatar">
          <Image src={comment.user_icon} alt="" />
        </div>
        <div className="info">
          <div className="name">{comment.user_nickname}</div>
          <div className="time">
            {dayjs.unix(comment.time).format("MM-DD HH:mm")}
          </div>
        </div>
      </div>
      <div className="comment-main">
        <div className="comment-content">{comment.content}</div>

        {comment.pic_list?.length > 0 && (
          <div className="comment-img">
            {comment.pic_list?.map((img, index, imgs) => (
              <Link
                key={index}
                className="item"
                to={"/img?imgs=" + imgs + "&img=" + index}
              >
                <Image src={img} alt="" />
              </Link>
            ))}
          </div>
        )}
        <div className="comment-phone-info">
          <div className="device">{comment.device_name}</div>
          <div className="count">
            <div className="item reply">
              <img src="/resources/game/msg.jpg" alt="" className="icon" />
              {comment.reply_count}
            </div>
            <div className="item like" onClick={(e) => like(e, comment)}>
              {parseInt(comment.me_like) ? (
                <img src="/resources/game/like.jpg" alt="" className="icon" />
              ) : (
                <img
                  src="/resources/game/notlike.jpg"
                  alt=""
                  className="icon"
                />
              )}

              {comment.like_count}
            </div>
          </div>
        </div>
      </div>

      {datas.length > 0 && (
        <div>
          <div className="comment-detail-line"></div>

          <div className="comment-info">
            <div className="title">全部回复</div>
            {/* <div className="count">（阅读{comment.view_count}）</div> */}
          </div>
        </div>
      )}

      <div className="comment-reply">
        {datas.map((reply, index) => (
          <div
            key={index}
            className="reply-item"
            onClick={() => onReply(reply)}
          >
            <div className="avatar">
              <Image src={reply.user_logo} alt="" />
              <div className="name">{reply.user_nickname}</div>
            </div>

            <div className="comment-second-main">
              <div className="content">{reply.content}</div>

              <div className="comment-phone-info">
                <div className="device">
                  {dayjs.unix(reply.time).format("MM-DD HH:mm")}
                </div>
                <div className="count">
                  <div className="item like" onClick={(e) => like(e, index)}>
                    {parseInt(reply.me_like) ? (
                      <img
                        src="/resources/game/like.jpg"
                        alt=""
                        className="icon"
                      />
                    ) : (
                      <img
                        src="/resources/game/notlike.jpg"
                        alt=""
                        className="icon"
                      />
                    )}

                    {reply.like_count}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={"comment-operate" + (inputing ? " inputing" : "")}>
        <div className="container">
          <div className="input">
            <input
              type="text"
              placeholder={
                reply
                  ? "回复" + reply.user_nickname + "的评论"
                  : "我来写两句..."
              }
              value={content}
              ref={ref}
              onFocus={(e) => setInputing(true)}
              onBlur={() => {
                if (content) {
                  setReply(null);
                }
                setInputing(false);
              }}
              onKeyUp={(e) => e.keyCode === 13 && submit()}
              onChange={(e) => setContent(e.target.value)}
            />
            {content || inputing ? (
              <div
                className={"btn" + (content ? " active" : "")}
                onClick={submit}
              />
            ) : null}
          </div>
          {!inputing ? (
            <div className="count">
              <div className="item">
                <div>
                  <img src="/resources/game/msg.jpg" alt="" className="icon" />
                </div>
                {comment.reply_count}
              </div>
              <div className="islike item" onClick={(e) => like(e, comment)}>
                <div>
                  {parseInt(comment.me_like) ? (
                    <img
                      src="/resources/game/like.jpg"
                      alt=""
                      className="icon"
                    />
                  ) : (
                    <img
                      src="/resources/game/notlike.jpg"
                      alt=""
                      className="icon"
                    />
                  )}
                </div>
                {comment.like_count}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
