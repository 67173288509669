import { useEffect, useState } from "react";
import { useUpdate } from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import { NavLink } from "../../lib/Navigation";
import { sysUrl } from "../../config";
import useRequest from "../../lib/useRequest";
import useActiveState from "../../lib/useActiveState";

export default (props) => {
  const [app, setApp] = useActiveState("app");
  const update = useUpdate();
  const { data: tasks, run: getTasks } = useRequest(
    () => {
      Dialog.loading();
      return general.axios
        .post("/user/task/getTaskDataByName", { type: props.type })
        .finally(() => Dialog.close())
        .then(({ data: resData }) => {
          if (!resData.status.succeed) {
            Dialog.error(resData.status.error_desc);
          } else {
            let tasks = Object.entries(resData.data.task_info)
              .filter(([key, receive]) => receive && key != "douyin")
              .map(([_, data]) => data);
            tasks.sort((a, b) => a.sort - b.sort);

            console.log("========", tasks);
            return tasks;
          }
        })
        .catch((error) => Dialog.error(error.message));
    },
    { cacheKey: `task-${props.type}` }
  );

  useEffect(() => {
    if (props.active && (!tasks || props.action === "POP")) {
      getTasks();
    }
  }, [props.action]);

  const receive = (e, task) => {
    if (task.completed && !task.taked) {
      e.preventDefault();
      e.stopPropagation();
      if (task.ing) {
        return;
      }
      task.ing = true;
      update();
      general.axios
        .post("/user/task/takeIntegral", { name: task.name })
        .finally(() => {
          task.ing = false;
          update();
        })
        .then((res) => {
          let result = res.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            task.completed = 1;
            task.taked = 1;
            update();
            Dialog.success("领取成功");
          }
        })
        .catch((err) => Dialog.error("takeIntegral " + err.message));
      return;
    }

    // , "douyin"
    if (["weixin"].includes(task.name)) {
      setCdk(task.name);
      return;
    }

    if (task.name === "guide") {
      setGuide("task");
      general.history.push("/");
      return;
    }

    let route = {
      avatar: "/user",
      nickname: "/user/nickname",
      mobile: "/user",
      authentication: "/user/realname",
      sign: "/task/sign",
      trade: "/screen-trade",
      reserve: "/?tab=new",
    };
    let alert = {
      playGame: "下载任意一款之前没有下载过的游戏，并登录即可完成任务",
      loginGame: "每日登陆游戏就可领",
      recharge: (
        <>
          今日内现金充值任意金额即可完成任务
          <br />
          （包括游戏内使用微信/支付宝支付、现金充值平台币、购买省钱卡、会员卡）
        </>
      ),
      meetRecharge: (
        <>
          今日内现金充值满50元即可完成任务
          <br />
          （包括游戏内使用微信/支付宝支付、现金充值平台币、购买省钱卡、会员卡）
        </>
      ),
      comment: "今日内完成任意一款游戏评论，并且平台审核通过之后即可完成任务",
      voucher: (
        <>
          今日内在游戏内领取任意一张代金券即可完成任务
          <br />
          （盒子游戏详情、游戏内都可领取）
        </>
      ),
    };

    if (route[task.name]) {
      general.history.push(route[task.name]);
    }
    if (alert[task.name]) {
      Dialog.alert("温馨提示", alert[task.name]);
    }
  };

  const [cdk, setCdk] = useState();
  const [code, setCode] = useState();
  const { loading: exchangeing, run: exchange } = useRequest(
    async () => {
      if (exchangeing) {
        return;
      }
      if (!code) {
        Dialog.error("请输入兑换码");
        return;
      }

      console.log(app.brand, "55555555555555");
      return general.axios
        .post("/user/task/exchange", { type: cdk, code })
        .then(({ data: resData }) => {
          if (!resData.status.succeed) {
            Dialog.error(resData.status.error_desc);
          } else {
            for (const task of tasks) {
              if (task.name === cdk) {
                task.completed = 1;
                task.taked = 1;
                continue;
              }
            }
            update();
            setCdk(null);
            Dialog.success("成功领取" + resData.data);
          }
        })
        .catch((error) => Dialog.error(error.message));
    },
    { manual: true }
  );

  const [guide, setGuide] = useActiveState("guide");

  return (
    <>
      <div
        className="task-tasks"
        style={{
          backgroundImage: `url(/resources/task/${props.type}-head.jpg)`,
        }}
      >
        <div className="container">
          {tasks?.map((task, index) => {
            let ts = {
              gift648: {
                url: `${sysUrl}/home/gift648/indexsearch`,
                title: "648礼包",
              },
              weixinBind: {
                url: `${sysUrl}/home/bindaccount`,
                title: "绑定微信号",
              },
              weixinAdd: {
                url: `${sysUrl}/home/addcs`,
                title: "添加企业微信",
              },
            };

            if (task) {
              let t = ts[task.name];
              let Tag = t ? NavLink : "div";
              return (
                <Tag
                  key={index}
                  to={t?.url}
                  iframe={t ? { title: t.title } : null}
                  className="item"
                  onClick={(e) => receive(e, task)}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundImage: `url(/resources/task/${props.type}-${task.name}.png)`,
                    }}
                  ></div>
                  <div className="data">
                    <div className="name">
                      {task.text}
                      {/* {task.total > 1 ? (
                        <div className="state">
                          {task.success_num} / {task.total}
                        </div>
                      ) : null} */}
                    </div>
                    <div className="desc">{task.desc}</div>
                    <div className="amount">+成长值{task.amount}</div>
                  </div>
                  <div className="option">
                    <div
                      className={`btn ${task.completed ? "completed" : ""} ${
                        task.ing ? "ing" : ""
                      } ${task.taked ? "taked" : ""}`}
                    ></div>
                  </div>
                </Tag>
              );
            }
          })}
        </div>
      </div>
      {cdk ? (
        <div className={"task-cdk " + cdk}>
          <div className="mask" onClick={() => setCdk(null)} />
          <div
            className="entity"
            style={{ backgroundImage: "url(/resources/task/novice-cdk.png)" }}
          >
            <div className="head">
              <img src={"/resources/task/" + cdk + "-title.png"} alt="" />
            </div>
            {cdk === "weixin" ? (
              <div className="info">
                任务流程
                <br />
                <p>1.打开微信点击右上角＋，选择添加好友</p>
                <p>
                  2.选择公众号搜索“
                  <span>
                    {app.brand === "97" ? "97游戏盒" : "9917游戏服务号"}
                  </span>
                  ”
                </p>
                <p> 3.关注公众号，点击“游戏福利”获取并复制兑换码</p>
                <p>4.返回盒子输入兑换码，完成任务，代 金券到账</p>
              </div>
            ) : null}
            {/* {cdk === "douyin" ? (
              <div className="info">
                任务流程
                <br />
                <p>
                  1.打开抖音APP点击首页右上角搜索“
                  <span>{app.brand === "97" ? "97游戏盒" : "9 917游戏"}</span>”
                </p>
                <p>2.关注后进入首页，右上角点击“私信”</p>
                <p>3.弹出对话栏后，输入“礼包码”发送</p>
                <p>
                  4.官方账号会自动回复兑换码，复制后
                  返回盒子“新手任务－关注抖音账号－输
                  入礼包码兑换”即可完成任务，代金券 到账！
                </p>
              </div>
            ) : null} */}
            <div className="input">
              <input
                type="text"
                value={code}
                placeholder="请输入兑换码"
                autoComplete="false"
                onChange={(e) => setCode(e.target.value)}
                onKeyUp={(e) => e.keyCode === 13 && exchange()}
              />
              <div
                className={`btn ${exchangeing ? "ing" : ""}`}
                onClick={() => exchange()}
              ></div>
            </div>
            {cdk === "weixin" ? (
              <a href="weixin://" className="option">
                前往微信
              </a>
            ) : null}
          </div>
          <div className="close" onClick={() => setCdk(null)}>
            X
          </div>
        </div>
      ) : null}
    </>
  );
};
