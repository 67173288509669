import { useState } from "react";
import Messages from "./messges/Messages";
import Notices from "./messges/Notices";
import KeepScroll from "../../lib/KeepScroll";

const groups = [
  {
    id: "notice",
    name: "平台公告",
    view: Notices,
  },
  {
    id: "message",
    name: "系统消息",
    view: Messages,
  },
];

export default (props) => {
  const [current, setCurrent] = useState("notice");

  return (
    <div className="message-messages">
      <div className="message-head">
        <div className="width">
          {groups.map((item) => (
            <div
              key={item.id}
              className={`item ${item.id === current ? "active" : ""}`}
              onClick={() => setCurrent(item.id)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="message-body">
        {groups.map((item) => (
          <KeepScroll
            key={item.id}
            active={props.active && item.id == current}
            lazy
          >
            <div
              className={`message-group ${item.id === current ? "active" : ""}`}
            >
              <item.view
                {...{ ...item, active: props.active && item.id === current }}
              />
            </div>
          </KeepScroll>
        ))}
        <div className="pull-to-load nomore"></div>
      </div>
    </div>
  );
};
