import React, { useEffect, useState } from "react";
import { ShareAltOutlined, StarOutlined, StarFilled } from "@ant-design/icons";
import general from "../../general";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { NavLink } from "../../lib/Navigation";
import useActiveState from "../../lib/useActiveState";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { CSSTransition } from "react-transition-group";
import { sysUrl } from "../../config";

const devices = {
  0: "双",
  1: "安卓",
  2: "苹果",
};

const status = {
  0: "verify",
  "-1": "invalid",
  1: "complete",
  2: "reject",
  3: "reviewed",
};

const Operates = (props) => {
  const [trade, setTrade] = useState({});
  useEffect(() => {
    setTrade(props.trade);
  }, [props]);
  const favorite = () => {
    Dialog.loading();
    general.axios
      .post(trade.is_collected ? "/cancelCollectTrade" : "/collectTrade", {
        id: trade.trade_id,
      })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTrade({ ...trade, is_collected: !trade.is_collected });
          Dialog.info("操作成功");
        }
      })
      .catch((err) => {
        Dialog.error("collectTrade " + err.message);
      });
  };
  return (
    <div className="trade-operates">
      {trade.is_collected ? (
        <StarFilled className="trade-operate" onClick={favorite} />
      ) : (
        <StarOutlined className="trade-operate" onClick={favorite} />
      )}
      <CopyToClipboard
        text={trade.trade_detail_url}
        onCopy={() => Dialog.info("已复制链接")}
      >
        <ShareAltOutlined className="trade-operate" />
      </CopyToClipboard>
    </div>
  );
};

export default (props) => {
  const [user] = useActiveState("user");
  const [trade, setTrade] = useState({});
  const [game, seGame] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.setOperate(
      <Operates trade={trade ? trade : { trade_id: props.id }} />
    );
  }, [props.id, trade]);
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios
      .post("/user/trade/getTradeDetail", { trade_id: props.query.id })
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTrade({ ...data, is_collected: parseInt(data.is_collected) });
          seGame(data.game_info);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  const [confirm, setConfirm] = useState();
  const [confirmed, setConfirmed] = useState();
  const [change, setChange] = useState();

  return (
    <>
      <div className="trade-detail">
        <Link
          to={"/game?mid=" + trade.game_id}
          className="trade-box trade-game"
        >
          <div className="icon">
            <Image src={game.game_image?.thumb} />
          </div>
          <div className="data">
            <div className="name">{game.game_name_main || game.game_name}</div>
            {game.game_name_branch ? (
              <div className="branch">{game.game_name_branch}</div>
            ) : null}
            <div className="desc">
              {game.game_classify_name?.map((item, index) => (
                <span key={index}>{item.tagname}</span>
              ))}
              <span>{game.game_download_num}次下载</span>
              <span>{game.game_size?.ios_size}</span>
            </div>
          </div>
          <div className="opt">
            <div className="btn">下载</div>
          </div>
        </Link>
        <div className="trade-box">
          <div className="trade-title">
            基本信息<span>（适用于{devices[trade.game_device_type]}端）</span>
          </div>
          <div className="trade-content">
            <div className="item">
              上架时间：
              {dayjs.unix(trade.sell_time).format("YYYY-MM-DD HH:mm:ss")}
            </div>
            <div className="item">小号：{trade.xh_alias}</div>
            <div className="item">区服：{trade.server_name}</div>
            <div className="item">
              备注：此号已创建
              {Math.ceil((dayjs().unix() - trade.xh_create_time) / 86400)}天，
              {trade.xh_recharge_money * 1 > 0 ? (
                <>
                  实际累充<span>{trade.xh_recharge_money}</span>元
                </>
              ) : null}
            </div>
          </div>
          <div className="trade-price">
            ￥<span>{trade.sell_price}</span>
          </div>
          <div className="trade-state">
            <img
              src={"/resources/trade/" + status[trade.check_status] + ".png"}
              alt=""
            />
          </div>
          <div className="trade-title">{trade.title}</div>
          <div
            className="trade-content"
            dangerouslySetInnerHTML={{
              __html: trade.content
                ? trade.content.replace(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                    "$1<br>$2"
                  )
                : null,
            }}
          />
        </div>
        {trade.check_status == 2 ? (
          <>
            <div className="trade-box">
              <div className="trade-title">审核失败</div>
              <div
                className="trade-content err"
                dangerouslySetInnerHTML={{
                  __html: trade.reject_content.replace(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                    "$1<br>$2"
                  ),
                }}
              />
            </div>
          </>
        ) : null}
        <div className="trade-box">
          <div className="trade-title">游戏截图</div>
          <div className="trade-content trade-imgs clearfix">
            {trade.game_screenshots?.map((img, index, imgs) => (
              <Link
                key={index}
                to={
                  "/img?imgs=" +
                  imgs.map((item) => item.source) +
                  "&img=" +
                  index
                }
                className="item"
              >
                <Image src={img.thumb} alt="" />
              </Link>
            ))}
          </div>
        </div>
        {trade.check_status == 3 &&
        trade.seller_username != user.member_username ? (
          <div className="trade-operate">
            <div
              className="btn"
              onClick={() => {
                setConfirm(true);
                setChange(false);
              }}
            >
              立即购买
            </div>
            {trade.is_change == 2 ? (
              <div
                className="btn"
                onClick={() => {
                  setConfirm(true);
                  setChange(true);
                }}
              >
                立即换购
              </div>
            ) : null}
          </div>
        ) : null}
        <CSSTransition in={confirm} timeout={250} unmountOnExit={true}>
          <div className="trade-confirm">
            <div className="container">
              <div className="head">
                <div className="title">买家必读</div>
                <img
                  style={{ width: "16px", height: "16px" }}
                  src="/resources/game/close.png"
                  alt=""
                  onClick={() => setConfirm(false)}
                />
              </div>
              <div className="main">
                <div className="title">购买流程</div>
                <div className="process">
                  <img src="/resources/trade/process.png" alt="" />
                  <ul className="clearfix">
                    <li>选择商品</li>
                    <li>购买商品</li>
                    <li>下载游戏</li>
                    <li>登录游戏</li>
                  </ul>
                </div>
                <div className="title">买家须知</div>
                <div className="body">
                  <p>
                    角色描述、截图已通过官方核验。因时间因素造成的变化(排行榜、称号、装备到期等)，不视为信息失实。
                  </p>
                  <p>
                    交易后角色直接转入您购买所使用的账号，登录游戏即可查收角色。
                  </p>
                  <p>
                    交易过程仅限小号转移不涉及账号交易或换绑操作，无需担心购买的号被找回。
                  </p>
                  <p>
                    购买时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有累计充值。
                  </p>
                  <p>交易完成后，不支持退货。</p>
                  <p>交易完成的小号超过72小时后才可重新上架交易。</p>
                  <p>所购买的小号不支持转游/转区操作。</p>
                  <p>
                    买家须知:因买家购买过程中操作失误产生的一切损失，由买家自行承担，平台概不负责。
                  </p>
                </div>
              </div>
              <div className="operate">
                <div className="input">
                  <input type="radio" onChange={() => setConfirmed(true)} />
                  我已阅读
                </div>
                <NavLink
                  className={"buy" + (!confirmed ? " disable" : "")}
                  to={`${sysUrl}/home/trade?id=${trade.trade_id}${
                    change ? "&type=2" : ""
                  }`}
                  onClick={(e) => {
                    if (!confirmed) {
                      e.preventDefault();
                      e.stopPropagation();
                      return;
                    }
                    setConfirm(false);
                    setConfirmed(false);
                  }}
                  iframe={{ title: "购买" }}
                >
                  立即{change ? "换购" : "购买"}
                </NavLink>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};
