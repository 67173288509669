// 分类-各分类对应所有游戏列表
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SwiperCore, { Pagination } from "swiper";
import general from "../../../general";
import $ from "jquery";
import { useLockFn, useScroll, useMount, useUpdateEffect } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import Banner from "./Banner";

SwiperCore.use([Pagination]);

export default (props) => {
  const [loading, setLoading] = useState();
  let [page, setPage] = useState(1);
  const [sort, setSort] = useState("def");
  let [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [isShow, setIsShow] = useState(false);
  let [banners, setBanners] = useState([]);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      categorize_id: props.tag,
      categorize_type: sort,
      page,
    };

    if (parms.page) {
      tmp.page = parms.page;
    }

    general.axios
      .post("/base/appcategorize/getAppCategorizeData", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        console.log("分类对应数据列表", response);
        let result = response.data.data;
        console.log(props, "--------------");
        let list = [];

        if (props.pagetype == 3) {
          // 设置banner
          banners = result.three?.banner;
          setBanners(result.three?.banner);
          list = result.three?.game;
        } else {
          list = result.two?.game;
        }
        console.log(games);

        games = parms.page === 1 || !parms.page ? list : [...games, ...list];
        setGames((games) =>
          parms.page === 1 || !parms.page ? list : [...games, ...list]
        );

        if (parms.page) {
          setPage(parms.page);
        }
        if (list && list.length > 0) {
          setMore(true);
        } else {
          setMore(false);
        }

        setIsShow(true);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useMount(() => load());

  const scroll = useScroll();
  useEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  useUpdateEffect(() => {
    // 排序规则切换后
    // 若有banner，屏幕返回banner以下
    if (banners && banners.length > 0) {
      let topHeight = $("#classifyBanners")[0].clientHeight || 250;
      console.log("banner高度", $("#classifyBanners"));
      console.log(topHeight);
      // $("html,body").scrollTop(topHeight);

      $("html,body").animate({ scrollTop: topHeight }, page * 300);
    } else {
      // 否则返回屏幕顶部
      // $("html,body").scrollTop(0);
      $("html,body").animate({ scrollTop: 0 }, page * 300);
    }

    page = 1;
    setPage(1);
    if (props.active) {
      console.log(page);
      load({ page: 1 });
    }
  }, [sort]);

  return (
    // banner+游戏列表/游戏列表
    <div className={"game-type classify-banner" + (!props.tag ? " notag" : "")}>
      {banners && banners.length > 0 && (
        <div className="banners" id="classifyBanners">
          <Banner banners={banners} type={props.pagetype} />
        </div>
      )}

      <div className="head">
        {[
          { id: "def", text: "默认排序" },
          { id: "new", text: "最新上架" },
          { id: "hot", text: "热门游戏" },
        ].map((item) => (
          <div
            key={item.id}
            className={"item " + (sort == item.id ? "active" : "")}
            onClick={() => setSort(item.id)}
          >
            {item.text}
          </div>
        ))}
      </div>

      {isShow ? (
        <>
          {games && games.length > 0 ? (
            <div className="body">
              {games.map((game, index) => (
                <Link
                  key={game.game_id}
                  to={"/game?id=" + game.game_id}
                  className="game-list-global-type"
                >
                  {game.discount && game.discount < 1 ? (
                    <div className="discount">
                      <span style={{ fontSize: "0.28rem", fontWeight: "bold" }}>
                        {game.discount * 10}
                      </span>
                      <span style={{ fontSize: "0.2rem" }}>折</span>
                    </div>
                  ) : null}
                  {index < 3 ? (
                    <img
                      src={`/resources/classify/${index}.png`}
                      alt=""
                      className="ranking-img"
                    />
                  ) : null}
                  <div className="icon">
                    <Image src={game.icon} />
                  </div>
                  <div className="data">
                    <div className="name">
                      <div className="name-main">
                        {game.game_name_main || game.game_name}
                      </div>
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                    </div>
                    <div className="desc">
                      {game.version}
                      <div className="line"></div>
                      {game.played_count && game.played_count > 0 ? (
                        <div className="info" style={{ marginTop: 0 }}>
                          <div className="circle"></div>
                          {game.played_count}w人在玩
                        </div>
                      ) : null}
                    </div>
                    <div className="info">
                      {game.benefits
                        ? game.benefits?.split("+").map((item, index) => (
                            <div key={index} className="tag">
                              {item}
                            </div>
                          ))
                        : game.introduction}
                    </div>
                  </div>
                </Link>
              ))}
              {games && games.length > 0 && !more ? (
                <div className="view-end">别撩啦！已经到底啦~</div>
              ) : null}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                paddingTop: "30px",
              }}
            >
              <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
              <p>暂无数据~</p>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};
