import { useEffect, useState } from "react";
import general from "../../../general";
import { useReactive } from "ahooks";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default (props) => {
  const data = useReactive({
    loading: false,
  });

  const [trades, setTrades] = useState([]);
  const load = (parms = {}) => {
    if (data.loading) {
      return;
    }
    data.loading = true;
    Dialog.loading();
    general.axios
      .post("/user/trade/getTradeList", {
        member_id: undefined,
        username: undefined,
        trade_type: "3",
        game_id: props.query.game_id,
        pagination: { count: 100, page: 1 },
      })
      .finally(() => {
        data.loading = false;
        Dialog.close();
      })
      .then((res) => {
        let result = res.data.data;
        setTrades(result.account_list);
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  return (
    <div className="game-trade">
      {trades.length > 0 ? (
        trades.map((trade) => (
          <Link
            key={trade.trade_id}
            to={"/trade/detail?id=" + trade.trade_id}
            className="item"
          >
            <div className="icon">
              <Image src={trade.account_icon} alt="" />
            </div>
            <div className="data">
              <div className="text">
                {trade.title} 区服：{trade.server_name}
              </div>
              <div className="time">
                上架时间：
                {dayjs.unix(trade.sell_time).format("YYYY-MM-DD HH:mm:ss")}
              </div>
              <div className="price">
                <div className="amount">{trade.total_amount}</div>
                <div className="priced">{trade.trade_price}</div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无交易</p>
        </div>
      )}
    </div>
  );
};
