import { useEffect, useState } from "react";
import { useLockFn } from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";

export default () => {
  const [tipsFont, setTipsFont] = useState();

  const tipsLoad = useLockFn(async () => {
    Dialog.loading();
    await general.axios
      .post("/tradedGameHint")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setTipsFont(list);
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    tipsLoad();
  }, []);

  return (
    <div className="trade-notice">
      <div className="notice-info">
        账号交易采用转移小号的形式；买入或者卖出游戏角色，不影响您
        的游戏主账号使用。
      </div>
      <div className="notice-process">
        <div className="title">购买流程</div>
        <div className="process">
          <img src="/resources/trade/process.png" alt="" />
          <ul className="clearfix">
            <li>选择商品</li>
            <li>购买商品</li>
            <li>下载游戏</li>
            <li>登录游戏</li>
          </ul>
        </div>
        <div className="title">买家须知</div>
        <div className="body">
          <p>
            角色描述、截图已通过官方核验。因时间因素造成的变化(排行榜、称号、装备到期等)，不视为信息失实。
          </p>
          <p>交易后角色直接转入您购买所使用的账号，登录游戏即可查收角色。</p>
          <p>
            交易过程仅限小号转移不涉及账号交易或换绑操作，无需担心购买的号被找回。
          </p>
          <p>
            购买时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有累计充值。
          </p>
          <p>交易完成后，不支持退货。</p>
          <p>交易完成的小号超过72小时后才可重新上架交易。</p>
          <p>所购买的小号不支持转游/转区操作。</p>
          <p>
            买家须知:因买家购买过程中操作失误产生的一切损失，由买家自行承担，平台概不负责。
          </p>
        </div>
      </div>
      <div className="notice-process">
        <div className="title">出售流程</div>
        <div className="process">
          <img src="/resources/trade/process.png" alt="" />
          <ul className="clearfix">
            <li>上传商品</li>
            <li>审核冻结</li>
            <li>买家购买</li>
            <li>出售成功</li>
          </ul>
        </div>
        <div className="title">卖家须知</div>
        <div className="body">
          {tipsFont?.maijiaxuzhi?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
};
