import { HashRouter as Router, Route } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { Provider } from "react-redux";
import { store } from "./lib/store";
import ErrorBoundary from "./lib/ErrorBoundary";
import React from "react";
import { prod } from "./config";
import { useMount } from "ahooks";
import $ from "jquery";
import Index from "./view/Index";

const VConsole = React.lazy(() => import("./view/VConsole"));

dayjs.locale("zh-cn");

export default () => {
  useMount(() => {
    if (
      navigator.standalone &&
      document.referrer &&
      location.href.indexOf("desktop=1") >= 0
    ) {
      $("meta[name=viewport]").attr(
        "content",
        "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      );
    }
  });
  return (
    <>
      <Provider store={store}>
        <ErrorBoundary>
          <React.Suspense fallback={null}>
            {!prod ? <VConsole /> : null}
            <Router>
              <Route render={(props) => <Index {...props} />} />
            </Router>
          </React.Suspense>
        </ErrorBoundary>
      </Provider>
    </>
  );
};
