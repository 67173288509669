import {Swiper, SwiperSlide} from 'swiper/react';
import {useEffect, useState} from "react";
import Image from "../../lib/Image";
import {useHistory} from "react-router-dom";
import general from "../../general";

export default ({query}) => {
  const history = useHistory();
  const [swiper, setSwiper] = useState();
  const [imgs, setImgs] = useState([]);
  const [img, setImg] = useState(0);
  useEffect(() => {
    setImgs(query.imgs?.split(',') || general.imgs);
    setImg(parseInt(query.img));
  }, [query]);
  useEffect(() => {
    swiper && swiper.slideTo(img);
  }, [img]);

  return <div className="view-img" onClick={() => history.goBack()}>
    <Swiper initialSlide={0} pagination={{clickable: true}} onSwiper={setSwiper}>
      {imgs?.map((img,index) => <SwiperSlide key={index} className="img">
        <Image src={img} alt=""/>
      </SwiperSlide>)}
    </Swiper>
  </div>;
};