import { useEffect, useState } from "react";
import { DownOutlined, SoundOutlined, UpOutlined } from "@ant-design/icons";
import general from "../../general";
import { useLocalStorageState, useLockFn } from "ahooks";
import Dialog from "../../lib/Dialog";

export default (props) => {
  const [key, setKey] = useState();
  const [datas, setDatas] = useLocalStorageState("rebate-guide", {
    defaultValue: [],
  });
  const load = useLockFn(async () => {
    Dialog.loading();
    general.axios
      .post("/user/rebate/guide")
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { guide_list: datas },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setDatas(datas);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  useEffect(() => {
    load();
  }, []);

  return (
    <div className="rebate-guide">
      <div className="tips">
        <SoundOutlined className="icon" />
        温馨提示：自主返利暂只支持BT游戏，其他返利请联系客服
      </div>
      <div className="list">
        {datas.map((data, index) => (
          <dl key={index} className={index === key ? "active" : ""}>
            <dt onClick={() => setKey(index === key ? null : index)}>
              <div className="text">{data.guide_title}</div>
              <DownOutlined className="icon icon-down" />
              <UpOutlined className="icon icon-up" />
            </dt>
            <dd
              dangerouslySetInnerHTML={{
                __html: data.guide_content.replace(
                  /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                  "$1<br>$2"
                ),
              }}
            />
          </dl>
        ))}
      </div>
    </div>
  );
};
