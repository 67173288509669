import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useLockFn} from "ahooks";
import Dialog from "../../lib/Dialog";
import general from "../../general";

export default () => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [focus, setFocus] = useState('');
  const [password, setPassword] = useState({});
  const [saving, setSaving] = useState(false);
  const save = useLockFn(async () => {
    if (!data.password) {
      Dialog.error('请输入新密码');
      return;
    }
    setSaving(true);
    Dialog.loading();
    general.axios.post('/finalCellularLogin', {
      username: localStorage.getItem('username'),
      token: localStorage.getItem('token'),
      password: data.password
    })
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        localStorage.setItem('token', data.token);
        localStorage.setItem('user_id', data.id);
        localStorage.setItem('username', data.username);
        Dialog.success('保存成功', () => history.replace('/'));
      })
      .catch(err => {
        Dialog.error('finalCellularLogin ' + err.message);
      });
    /*await general.axios.post('/user/user/modifyPwd', {
      newpassword: data.password,
    })
      .finally(() => {
        setSaving(false);
        Dialog.close();
      })
      .then(response => {
        let {status} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('保存成功', () => history.replace('/'));
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });*/
  });

  return <div className="user-password">
    <div className="user-input">
      <div className="name">密码</div>
      <input name="password" type={!password.password ? 'password' : 'text'} value={data.password} placeholder="请输入密码" autoComplete="off" onFocus={() => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
      <div className="password" onClick={() => setPassword({...password, password: !password.password})}>
        {password.password ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      </div>
      {focus === 'password' && data.password ? <CloseOutlined className="close" onClick={() => setData({...data, password: ''})}/> : null}
    </div>
    <div className="user-btn" onClick={save}>保存</div>
  </div>;
};