import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import dayjs from "dayjs";
import { NavLink } from "../../lib/Navigation";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

export default ({ id, setId }) => {
  const [show, setShow] = useState(true);

  const [game, setGame] = useState();
  const [groups, setGroups] = useState();
  const [group, setGroup] = useState();
  useEffect(() => {
    setGame(null);
    setGroups(null);
    if (!id) {
      return;
    }
    Dialog.loading();
    general.axios
      .post("/base/game/getGameDetail", { game_id: id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let game = { ...data, ...data.game_info };
        let gifts = game.gift_bag_list;
        let groups = {};
        for (const gift of gifts) {
          let id = "default";
          let name = "免费礼包";
          if (gift.is_vip == 2 || gift.member_level_limit > 0) {
            id = "special";
            name = "特权礼包";
          } else if (gift.recharge_amount > 0) {
            id = "recharge";
            name = "充值礼包";
          }
          if (!groups[id]) {
            groups[id] = {
              id,
              name,
              gifts: [],
            };
          }
          groups[id].gifts.push(gift);
        }
        groups = Object.entries(groups).map(([_, group]) => group);
        setGame(game);
        setGroups(groups);
        setGroup(groups.length ? groups[0] : null);
      })
      .catch((err) => {
        Dialog.error("getGameDetail " + err.message);
      });
  }, [id]);

  const [gift, setGift] = useState({});
  const [giftShow, setGiftShow] = useState();
  const [giftCode, setGiftCode] = useState();
  const giftReceive = (gift) => {
    if (gift.unicode != 1 && gift.surplus_num < 1) {
      return;
    }
    Dialog.loading();
    general.axios
      .post("/base/game/getReceiveGift", { gift_id: gift.packid })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setGiftShow(false);
          setGiftCode(data);
        }
      })
      .catch((err) => {
        Dialog.error("getReceiveGift " + err.message);
      });
  };

  return (
    <>
      {id ? (
        <>
          <CSSTransition
            in={show}
            timeout={250}
            appear={true}
            onExited={() => {
              setId(null);
              setShow(true);
            }}
          >
            <div className="gift-game">
              <div className="mask" onClick={() => setShow(null)} />
              <div className="container">
                {game ? (
                  <>
                    <Link className="game" to={"/game?id=" + game.game_id}>
                      <div className="icon">
                        <Image src={game.game_image?.thumb} />
                      </div>
                      <div className="data">
                        <div className="name">
                          {game.game_name_main || game.game_name}
                        </div>
                        <div className="desc">
                          下载次数：{game.game_download_num}次
                        </div>
                        <div className="count">
                          礼包数量：<span>{game.gift_bag_list.length}</span>个
                        </div>
                      </div>
                      <div className="opt">下载</div>
                    </Link>
                  </>
                ) : null}
                {groups ? (
                  <>
                    <div className="nav">
                      {groups.map((_group) => (
                        <div
                          key={_group.id}
                          className={`item ${
                            _group == group ? "active" : null
                          }`}
                          onClick={() => setGroup(_group)}
                        >
                          {_group.name}
                        </div>
                      ))}
                    </div>
                    <div className="groups">
                      {groups.map((_group) => (
                        <div
                          key={_group.id}
                          className={`group ${
                            _group == group ? "active" : null
                          }`}
                        >
                          {_group.gifts?.map((gift, index) => (
                            <div
                              key={index}
                              className="item"
                              onClick={() => {
                                setGift(gift);
                                setGiftShow(true);
                              }}
                            >
                              <div className="icon">
                                <Image src="/resources/gift/gift.png" />
                              </div>
                              <div className="info">
                                <div className="name">
                                  <div className="named">{gift.packname}</div>
                                  {gift.is_vip == 2 ||
                                  gift.member_level_limit > 0 ? (
                                    <>
                                      <div className="special">
                                        <div className="label">
                                          {gift.member_level_limit > 0
                                            ? `Lv${gift.member_level_limit}`
                                            : "VIP"}
                                        </div>
                                        <div className="value">专享</div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                                <div className="desc">
                                  {gift.packcontent}
                                  <br />
                                  剩余：
                                  <span>
                                    {gift.unicode == 1
                                      ? "无限"
                                      : gift.surplus_num}
                                  </span>
                                  有效期：
                                  {dayjs
                                    .unix(gift.starttime)
                                    .format("YYYY-MM-DD")}{" "}
                                  至{" "}
                                  {dayjs
                                    .unix(gift.endtime)
                                    .format("YYYY-MM-DD")}
                                </div>
                              </div>
                              <NavLink
                                className={`opt ${
                                  gift.unicode != 1 && gift.surplus_num < 1
                                    ? "nothing"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  giftReceive(gift);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
                <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
                  <div className="gift-detail">
                    <div
                      className="gift-mask"
                      onClick={() => setGiftShow(false)}
                    />
                    <div className="gift-container">
                      <div className="gift-top">
                        <div className="text">
                          <div className="name">
                            <div className="named">{gift.packname}</div>
                            {gift.is_vip == 2 || gift.member_level_limit > 0 ? (
                              <>
                                <div className="special">
                                  <div className="label">
                                    {gift.member_level_limit > 0
                                      ? `Lv${gift.member_level_limit}`
                                      : "VIP"}
                                  </div>
                                  <div className="value">专享</div>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="time">
                            有效期：
                            {dayjs
                              .unix(gift.endtime)
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </div>
                        </div>
                      </div>
                      <div className="gift-main">
                        <div className="gift-desc">
                          <div className="title">礼包说明</div>
                          <div className="main">{gift.packcontent}</div>
                        </div>
                      </div>
                      <NavLink
                        className="gift-bottom"
                        onClick={() => giftReceive(gift)}
                      >
                        领取
                      </NavLink>
                    </div>
                  </div>
                </CSSTransition>
                {giftCode ? (
                  <div className="dialog active">
                    <div className="dialog-mask" />
                    <div className="dialog-content">
                      <div className="dialog-modal">
                        <div className="modal-body">
                          <div className="modal-title">领取成功</div>
                          <div className="modal-content">{giftCode}</div>
                        </div>
                        <div className="modal-btns">
                          <div className="modal-btn">
                            <CopyToClipboard
                              text={giftCode}
                              onCopy={() => Dialog.info("已复制")}
                            >
                              <div onClick={() => setGiftCode(null)}>复制</div>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </CSSTransition>
        </>
      ) : null}
    </>
  );
};
