import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useUpdateEffect, useScroll } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { CSSTransition } from "react-transition-group";

export default (props) => {
  const explain = () => {
    Dialog.alert(
      "问答须知",
      <div className="qa-explain">
        想对新接触的游戏有更多的了解？游戏问答让萌新有地方问，让大神为您解答
        <br />
        问答利益
        <br />
        理性发言，中肯发言
        <br />
        问的清楚，答的明白
        <br />
      </div>
    );
  };
  useEffect(() => {
    props.setOperate(<QuestionCircleOutlined onClick={explain} />);
  }, []);
  const [data] = useState(general.question ?? {});
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      question_id: props.query.id,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/base/qa/getAnswers", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let {
          status,
          data: { list, paginated },
        } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (parms.page) {
            setPage(parms.page);
          }
          setDatas((datas) =>
            parms.page === 1 || !parms.page ? list : [...datas, ...list]
          );
          setMore(paginated.more && list.length > 0);
        }
      })
      .catch((err) => {
        Dialog.error("getAnswers " + err.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [answer, setAnswer] = useState(false);
  const [content, setContent] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (!content) {
      return;
    }
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    general.axios
      .post("/base/qa/submitAnswer", {
        question_id: props.query.id,
        content: content,
      })
      .finally(() => {
        setSubmiting(false);
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("提交成功", () => {
            setAnswer(false);
            setContent("");
          });
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <div className="qa-detail">
      <div className="qa-q">
        <div className="avatar">
          <Image src={data.user_icon} alt="" />
        </div>
        <div className="main">
          <div className="info">
            <div className="name">{data.user_nickname}</div>
            <div className="time">
              {dayjs.unix(data.time).format("MM-DD HH:mm")}
            </div>
          </div>
          <div className="desc">
            <div className="icon">问</div>
            <div className="text">{data.question}</div>
          </div>
        </div>
      </div>
      <div className="qa-info">
        <div className="icon">答</div>
        <div className="text">
          共有<span>1</span>条玩家回答
        </div>
      </div>
      <div className="qa-as">
        {datas.map((item, index) => (
          <div key={index} className="qa-a">
            <div className="avatar">
              <Image src={item.user_icon} alt="" />
            </div>
            <div className="main">
              <div className="info">
                <div className="name">{item.user_nickname}</div>
                <div className="time">
                  {dayjs.unix(item.time).format("MM-DD HH:mm")}
                </div>
              </div>
              <div className="desc">{item.content}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="qa-operate">
        <div className="btn" onClick={() => setAnswer(true)}>
          我来回答
        </div>
      </div>
      <CSSTransition in={answer} timeout={250} unmountOnExit={true}>
        <div className="qa-form">
          <div className="mask" onClick={() => setAnswer(false)} />
          <div className="form">
            <div className="head">
              <div className="title">乐于助人的人，运气都不会差哦~</div>
              <div
                className={"btn " + (!content ? " disable" : "")}
                onClick={submit}
              >
                提交
              </div>
            </div>
            <div className="input">
              <textarea
                rows="5"
                placeholder="这个游戏我玩过，我来说说"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
      {datas.length > 0 ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
