import React, { useEffect, useState } from "react";
import general from "../../../general";
import $ from "jquery";
import { useLockFn, useScroll, useThrottleEffect } from "ahooks";
import Dialog from "../../../lib/Dialog";
import { NavLink } from "../../../lib/Navigation";
import Image from "../../../lib/Image";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/user/platform_notice/getNoticeList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { notice_list: list, paginated } = data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("getMessageList " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $("html,body").scrollTop(0);
      load({ page: 1 });
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="notice-items">
      {datas.map((item) => (
        <NavLink
          key={item.notice_id}
          className={`item ${item.read_tag == 2 ? "active" : ""}`}
          to={`/message?type=notice&id=${item.notice_id}`}
          onClick={() => {
            item.read_tag = 1;
          }}
        >
          <div className="data">
            <div className="time">{item.notice_time}</div>
            {item.cover_img ? (
              <>
                <div className="img">
                  <Image src={item.cover_img} />
                </div>
              </>
            ) : null}
            <div className="title">{item.title}</div>
            <div className="desc">{item.desc}</div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};
