import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useThrottleEffect } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import { Link } from "react-router-dom";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default (props) => {
  const [user, setUser] = useActiveState("user");
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/user/integralShop/giftList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { giftList: list, paginated } = data;
        list = list.map((item) => {
          item.is_receive = parseInt(item.is_receive);
          return item;
        });
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("giftList " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $("html,body").scrollTop(0);
      load({ page: 1 });
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const [data, setData] = useState();
  const exchange = useLockFn(async () => {
    if (data.is_receive) {
      return;
    }
    Dialog.loading();
    await general.axios
      .post("/user/integralShop/exchangeGift", { g_id: data.g_id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setDatas([
          ...datas.map((item) => {
            if (item === data) {
              item.is_receive = true;
            }
            return item;
          }),
        ]);
        setData(null);
        setUser({ ...user, integral: user.integral - data.goods_price });
        Dialog.success("兑换成功");
      })
      .catch((err) => {
        Dialog.error("exchangeGift " + err.message);
      });
  });

  return (
    <div className="credit-game">
      {datas && datas.length > 0 ? (
        <>
          {datas.map((data, index) => (
            <div key={index} className="item" onClick={() => setData(data)}>
              <div className="icon">
                <Image src={data.game_img} />
              </div>
              <div className="info">
                <div className="name">
                  {data.goods_name_main || data.goods_name}
                </div>
                {data.game_name_branch ? (
                  <div className="branch">{data.game_name_branch}</div>
                ) : (
                  <div className="name">
                    {data.goods_name_main || data.goods_name}
                  </div>
                )}
                <div className="count">库存：{data.surplus_num}</div>
                <div className="amount">
                  <span>{data.goods_price}</span>积分
                </div>
              </div>
              <div className={"btn" + (data.is_receive ? " disable" : "")}>
                立即兑换
              </div>
            </div>
          ))}
          {data ? (
            <div className="detail">
              <div className="mask" onClick={() => setData(null)} />
              <div className="container">
                <Link to={"/game?id=" + data.game_id} className="game">
                  <div className="icon">
                    <Image src={data.game_img} />
                  </div>
                  <div className="info">
                    <div className="title">
                      {data.goods_name_main || data.goods_name}
                    </div>
                    {data.game_name_branch ? (
                      <div className="branch">{data.game_name_branch}</div>
                    ) : (
                      <div className="name">
                        游戏：{data.goods_name_main || data.goods_name}
                      </div>
                    )}
                    <div className="count">库存：{data.surplus_num}</div>
                  </div>
                  <div className="more">&gt;</div>
                </Link>
                <div className="title">商品描述：</div>
                <div className="content">{data.goods_details}</div>
                <div className="title">使用方式</div>
                <div className="content">{data.use_desc}</div>
                <div
                  className={"btn" + (data.is_receive ? " disable" : "")}
                  onClick={exchange}
                >
                  {data.goods_price}积分兑换
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无数据~</p>
        </div>
      )}
    </div>
  );
};
