import { useLocalStorageState, useSize } from "ahooks";
import { useRef } from "react";
import KeepScroll from "../../lib/KeepScroll";
import Ptb from "./Ptb";
import Game from "./Game";
import Time from "./Time";
import { Link } from "react-router-dom";
import useActiveState from "../../lib/useActiveState";

const types = [
  {
    id: "",
    text: "兑换福利币",
    component: Ptb,
  },
  {
    id: "",
    text: "兑换游戏礼品",
    component: Game,
  },
  {
    id: "",
    text: "限时兑换",
    component: Time,
  },
];

export default (props) => {
  const [user] = useActiveState("user");
  const [typeIndex, setTypeIndex] = useLocalStorageState("credit-type", {
    defaultValue: 0,
  });

  const headRef = useRef(null);
  const sizeBody = useSize(headRef);

  return (
    <>
      <div className="credit-index">
        <div className="credit-head" ref={headRef}>
          <div className="width">
            <div className="summary">
              <Link to="/user/credit" className="item">
                <div className="value">{user.integral || "?"}</div>
                <div className="name">我的积分</div>
              </Link>
              <Link to="/credit/gift" className="item">
                <div className="icon">
                  <img src="/resources/credit/gift.png" alt="" />
                </div>
                <div className="name">我的礼包</div>
              </Link>
              <Link to="/user/credit" className="item">
                <div className="icon">
                  <img src="/resources/credit/credit.png" alt="" />
                </div>
                <div className="name">积分明细</div>
              </Link>
            </div>
            <div className="tips">
              <div className="text">
                真实充值1元即可获得10积分，真实充值：微信、支付宝充值
              </div>
            </div>
            <div className="type">
              {types.map((item, index) => (
                <div
                  key={index}
                  className={"item" + (index === typeIndex ? " active" : "")}
                  onClick={() => setTypeIndex(index)}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="credit-body" style={{ paddingTop: sizeBody?.height }}>
          {types.map((item, index) => (
            <div
              key={index}
              className={"type-item" + (index === typeIndex ? " active" : "")}
            >
              <KeepScroll
                active={props.active && index === typeIndex ? "active" : ""}
              >
                <item.component
                  active={props.active && index === typeIndex ? "active" : ""}
                  action={props.action}
                />
              </KeepScroll>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
