import { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import New from "./New";
import Good from "./Good";
import { useLocalStorageState, useMount, useUpdateEffect } from "ahooks";
import dayjs from "dayjs";
import { NavLink } from "../../lib/Navigation";
import Top from "./Top";
import KeepScroll from "../../lib/KeepScroll";
import HeadNew from "./HeadNew";
import useUrlState from "@ahooksjs/use-url-state";
import general from "../../general";
import { useHistory } from "react-router-dom";
import _useLocalStorageState from "../../lib/useLocalStorageState";
import urlMatching from "../../utils/urlMatching";

// 顶部tab
const router = [
  {
    id: "good",
    title: "推荐",
    view: Good,
  },
  {
    id: "new",
    title: "新游",
    view: New,
  },
  {
    id: "top",
    title: "排行榜",
    view: Top,
  },
];

export default (props) => {
  const history = useHistory();
  const [advertising, setAdvertising] = useState(null);
  // console.log(sessionStorage.getItem("index-advert"));
  const [isShow, setIsShow] = useState(
    sessionStorage.getItem("index-advert") || "close"
  );
  useEffect(() => {
    if (!sessionStorage.getItem("index-advert")) {
      if (advertising?.img_url) {
        sessionStorage.setItem("index-advert", "open");
        setIsShow("open");
      }
    }
  }, [advertising]);

  useMount(() => {
    // general.axios
    //   .post("/user/platform_notice/getHomeNotice")
    //   .then(({ data: resData }) => {
    //     let { status, data } = resData;
    //     if (!status.succeed) {
    //       return Dialog.error(status.error_desc);
    //     }
    //   })
    //   .catch((error) => console.error(error.message));

    history.listen(() => {
      console.warn(`history ${window.location.href}`);
      general.historys.push(window.location.href);
    });
  });

  const [extra, setExtra] = useLocalStorageState(
    "index-tab" + dayjs().format("YYYY-MM-DD"),
    { defaultValue: [] }
  );
  const [current, setCurrent] = useState("good");
  const [query] = useUrlState({}, { navigateMode: "replace" });
  useUpdateEffect(() => {
    if (query.tab) {
      setCurrent(query.tab);
    }
  }, [query.tab]);
  const [badge, setBadge] = useLocalStorageState(
    "index-tab-badge-" + dayjs().format("YYYY-MM-DD"),
    { defaultValue: {} }
  );

  return (
    <>
      <div className="index-index">
        <div className="index-head width">
          <div className="nav">
            <div className="nav-left">
              {router.map((item) => (
                <div
                  key={item.id}
                  className={
                    "item" +
                    (item.id === current ? " active" : "") +
                    (item.id === "new" && !badge[item.id]
                      ? " badge-" + item.id
                      : "")
                  }
                  onClick={() => {
                    setCurrent(item.id);
                    badge[item.id] = true;
                    setBadge(badge);
                  }}
                >
                  {item.title}
                </div>
              ))}
              {/* 顶部自定义标签*/}
              {extra.length > 0
                ? extra.map((item) => {
                    return (
                      <NavLink
                        key={item.id}
                        className={`item ${item.icon ? "item-img" : ""} ${
                          item.id === current ? " active" : ""
                        }`}
                        user={item.is_verification_login == 1 ? true : false}
                        iframe={
                          item.type == "outer_h5" ? { title: item.name } : null
                        }
                        to={urlMatching(item)}
                        href={item.type == "outer_contact" ? item.param : ""}
                      >
                        {item.icon ? <img src={item.icon} alt="" /> : item.name}
                      </NavLink>
                    );
                  })
                : null}
            </div>

            <HeadNew active={props.active} />
          </div>
        </div>
        <div className="index-body">
          {router.map((item) => (
            <KeepScroll
              key={item.id}
              active={props.active && item.id == current}
              lazy
            >
              <div
                className={
                  "index-body-item " + (item.id === current ? "active" : "")
                }
              >
                <item.view
                  active={props.active && item.id === current}
                  setExtra={setExtra}
                  setAdvertising={setAdvertising}
                  isShow={isShow}
                />
              </div>
            </KeepScroll>
          ))}
        </div>
      </div>
      {/* 首页广告 */}
      {isShow === "open" && advertising?.img_url ? (
        <div className="width index-advertising">
          <div className="adver-main">
            <CloseOutlined
              style={{
                fontSize: "16px",
                color: "#fff",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 999,
                padding: "9px 16px",
              }}
              onClick={() => {
                setIsShow("close");
                sessionStorage.setItem("index-advert", "close");
              }}
            />

            <NavLink
              className="adver-img"
              user={advertising.if_check == 1 ? true : false}
              iframe={
                advertising.type == "outer_h5"
                  ? { title: advertising.title }
                  : null
              }
              to={urlMatching(advertising)}
              href={
                advertising.type == "outer_contact" ? advertising.param : ""
              }
            >
              <img src={advertising.img_url} alt="" />
            </NavLink>
          </div>
        </div>
      ) : null}
    </>
  );
};
