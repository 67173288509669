import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import general from "../../general";
import { CloseOutlined } from "@ant-design/icons";
import { prod, url } from "../../config";
import { useLocalStorageState } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

const random = _.random(1000000, 9999999);

export default (props) => {
  const history = useHistory();
  const { aplus_queue } = window;
  const [app] = useActiveState("app");
  useEffect(() => {
    props.setOperate(
      <Link to="login" className="account-register-btn">
        登录
      </Link>
    );
  }, []);
  const [focus, setFocus] = useState("");
  const [data, setData] = useState(
    !prod
      ? {
          username: random,
          password: random,
        }
      : {}
  );
  const [agreement, setAgreement] = useLocalStorageState("agreement", {
    defaultValue: true,
  });
  const [registering, setRegistering] = useState(false);
  const [datas, setDatas] = useLocalStorageState("users", { defaultValue: [] });

  let timer = null;
  const register = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      registerCode();
    }, 500);
  };

  const registerCode = () => {
    if (!data.username) {
      Dialog.error("请输入用户名");
      return;
    }
    if (!data.password) {
      Dialog.error("请输入密码");
      return;
    }
    if (!agreement) {
      Dialog.error("请阅读下方的协议");
      return;
    }
    if (registering) {
      return;
    }
    setRegistering(true);
    Dialog.loading();
    general.axios
      .post("/user/user/register", data)
      .finally(() => {
        setRegistering(false);
        Dialog.close();
      })
      .then((res) => {
        console.log("注册--------", res);
        let { status, data: result } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user_id", result.user_id);
          localStorage.setItem("username", data.username);
          setDatas([
            ...datas,
            { username: data.username, password: data.password },
          ]);
          aplus_queue.push({
            action: "aplus.record",
            arguments: [
              "register",
              "register",
              {
                agent: localStorage.getItem("agent"),
                username: data.username,
              },
            ],
          });

          Dialog.success("注册成功", () => history.push("/phone"));
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <div className="account-register">
      <div className="body">
        <div className="logo">
          <img src={app.applogo} alt="" />
        </div>
        <div className="input-item">
          <div className="label">用户名</div>
          <div className="input">
            <input
              name="username"
              type="text"
              value={data.username}
              placeholder="请输入用户名或手机号码"
              autoComplete="off"
              onFocus={() => setFocus("username")}
              onChange={(e) => setData({ ...data, username: e.target.value })}
            />
            {focus === "username" && data.username ? (
              <CloseOutlined
                className="assist close"
                onClick={() => setData({ ...data, username: "" })}
              />
            ) : null}
          </div>
        </div>
        <div className="input-item">
          <div className="label">密码</div>
          <div className="input">
            <input
              name="password"
              type="text"
              value={data.password}
              placeholder="请输入密码"
              onFocus={() => setFocus("password")}
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
            {focus === "password" && data.password ? (
              <>
                <CloseOutlined
                  className="assist close"
                  onClick={() => setData((data) => ({ ...data, password: "" }))}
                />
              </>
            ) : null}
          </div>
        </div>
        <div
          className={"btn " + (registering ? "disable" : "")}
          onClick={register}
        >
          注册
        </div>
      </div>
      <div className="account-agreement">
        <label>
          <span
            className={"agreement" + (agreement ? " checked" : "")}
            onClick={() => setAgreement(true)}
          />
          登录即代表您同意
          <Link to={"/iframe?url=" + url + "/userAgreement&title=用户协议"}>
            用户协议
          </Link>
          、
          <Link to={"/iframe?url=" + url + "/privacyPolicy&title=隐私政策"}>
            隐私政策
          </Link>
          <br />
          接受免除或者限制责任、诉讼管辖约定等粗体标示条款
        </label>
      </div>
    </div>
  );
};
