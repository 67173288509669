import React, { Fragment, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import general from "../../general";
import { useThrottleEffect } from "ahooks";

export default () => {
  const game = general.game;
  const gifts = game.gift_bag_list || [];
  const [groups, setGroups] = useState([]);
  useThrottleEffect(() => {
    let groups = {};
    for (const gift of gifts) {
      let id = "default";
      let name = "免费礼包";
      if (gift.is_vip == 2 || gift.member_level_limit > 0) {
        id = "special";
        name = "特权礼包";
      } else if (gift.recharge_amount > 0) {
        id = "recharge";
        name = "充值礼包";
      }
      if (!groups[id]) {
        groups[id] = {
          id,
          name,
          gifts: [],
        };
      }
      groups[id].gifts.push(gift);
    }
    setGroups(Object.entries(groups).map(([_, group]) => group));
  });

  return (
    <>
      {groups.length > 0 ? (
        <div className="game-detail">
          <div className="game-gift">
            <div className="groups">
              {groups.map((group, index) => (
                <div className="group" key={index}>
                  <div className="name">{group.name}</div>
                  <div className="items">
                    {group.gifts?.map((gift, index) => (
                      <Fragment key={index}>
                        <Link
                          className="item"
                          to={`/game/giftDetail?id=${gift.packid}`}
                          iframe={{ title: gift.packname }}
                        >
                          <div className="info">
                            <div className="name">
                              <div className="named">{gift.packname}</div>
                              {gift.is_vip == 2 ||
                              gift.member_level_limit > 0 ? (
                                <>
                                  <div className="special">
                                    <div className="label">
                                      {gift.member_level_limit > 0
                                        ? `Lv${gift.member_level_limit}`
                                        : "VIP"}
                                    </div>
                                    <div className="value">专享</div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <div className="desc">
                              {gift.packcontent}
                              <br />
                              有效期：
                              {dayjs
                                .unix(gift.starttime)
                                .format("YYYY-MM-DD")}{" "}
                              至 {dayjs.unix(gift.endtime).format("YYYY-MM-DD")}
                            </div>
                          </div>
                          <div
                            className={`operate ${
                              gift.unicode != 1 && gift.surplus_num < 1
                                ? "nothing"
                                : ""
                            }`}
                          />
                        </Link>
                      </Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#fff",
            textAlign: "center",
            paddingTop: "150px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无礼包可领取~</p>
        </div>
      )}
    </>
  );
};
