import { Link, useHistory } from "react-router-dom";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import { useLockFn } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { RightOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

export default (props) => {
  const history = useHistory();
  const [user, setUser] = useActiveState("user");

  const avatar = useLockFn(async (e) => {
    let files = e.target.files;
    if (files.length < 1) {
      return;
    }
    Dialog.loading();
    let formData = new FormData();
    formData.append("file", files[0]);
    await general.axios
      .post("/user/user/uploadAvatar", formData)
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setUser({ ...user, icon_link: data.url });
          Dialog.success("上传成功 请等待审核");
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  });
  const phone = (e) => {
    if (user.mobile) {
      e.preventDefault();
      Dialog.dialog({
        mask: true,
        title: "选择操作",
        btns: [
          {
            text: "解绑手机号",
            onPress: () => history.push("/user/phone-remove"),
          },
          {
            text: "更换手机号",
            onPress: () => history.push("/user/phone-verify"),
          },
          { text: "取消" },
        ],
      });
    }
  };
  const logout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("token");
    localStorage.setItem("logout", true);
    history.goBack();
  };
  console.log("user-------", user);

  return (
    <div className="user-profile">
      <div className="group">
        <div className="item">
          <div className="name">
            个人头像
            {user.avatar_status === "0" ? <span>（审核中）</span> : null}
            {user.avatar_status === "-1" ? <span>（审核未通过）</span> : null}
          </div>
          <div className="operate">
            <Image
              src={user.verify_avatar || user.icon_link}
              alt="个人头像"
              className="avatar"
            />
          </div>
          <input type="file" onChange={avatar} />
        </div>
      </div>
      <div className="group">
        <Link to="/user/nickname" className="item">
          <div className="name">昵称</div>
          <div className="operate">
            {user.nick_name ? user.nick_name : "去设置"}
            <RightOutlined className="icon" />
          </div>
        </Link>
        <Link to="/user/qq" className="item">
          <div className="name">QQ</div>
          <div className="operate">
            {user.qq ? user.qq : "去设置"}
            <RightOutlined className="icon" />
          </div>
        </Link>
        <Link to="/user/phone" className="item" onClick={phone}>
          <div className="name">手机绑定</div>
          <div className="operate">
            {user.mobile ? user.mobile : "去绑定"}
            <RightOutlined className="icon" />
          </div>
        </Link>
      </div>
      <div className="group">
        <Link
          to="/user/realname"
          className="item"
          onClick={(e) => {
            if (user.real_name || user.identity_card) {
              e.preventDefault();
              e.stopPropagation();
              Dialog.info("已认证");
            }
          }}
        >
          <div className="name">实名认证</div>
          <div className="operate">
            {user.real_name || user.identity_card ? (
              <>
                {user.real_name.replace(/(.*)(.)$/, "$1*")},
                {user.identity_card.replace(
                  /^(\d{4})\d{10}(\d+)/,
                  "$1********$2"
                )}
              </>
            ) : (
              "去认证"
            )}
            <RightOutlined className="icon" />
          </div>
        </Link>
        <Link to="/user/password" className="item">
          <div className="name">登录密码</div>
          <div className="operate">
            修改密码
            <RightOutlined className="icon" />
          </div>
        </Link>
      </div>
      <div className="user-btn" onClick={logout}>
        退出登录
      </div>
    </div>
  );
};
