import { Fragment, useState } from "react";
import dayjs from "dayjs";
import general from "../../general";
import { NavLink } from "../../lib/Navigation";
import Explain from "../voucher/Explain";
import Dialog from "../../lib/Dialog";
import { useUpdate } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import { useHistory } from "react-router-dom";
import Image from "../../lib/Image";
import { sysUrl } from "../../config";

export default () => {
  const game = general.game;
  const vouchers = game.voucherslist || [];
  const history = useHistory();
  const [explain, setExplain] = useState();
  const update = useUpdate();
  const [user] = useActiveState("user");
  const [app] = useActiveState("app");
  const receive = (voucher) => {
    if (!user.mobile) {
      Dialog.confirm({
        title: "没有绑定手机号，现在去绑定？",
        yes: () => {
          history.push("/user/phone");
        },
      });
      return;
    }
    general.axios
      .post("/receiveVoucher", { voucher_id: voucher.id })
      .finally(() => Dialog.close())
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success("领取成功");
          voucher.is_received = true;

          update();
        }
      })
      .catch((err) => Dialog.error("receiveVoucher " + err.message));
  };

  return (
    <>
      <div className="game-detail">
        <div className="game-voucher">
          <NavLink
            className="monthcard"
            to={`${sysUrl}/home/monthCard`}
            iframe={{ title: "省钱卡" }}
          >
            <Image src="/resources/game/monthcard.png" alt="省钱卡" />
          </NavLink>

          <div className="circulation">
            非首充优惠券支持成功使用后可再次领取，领取前需先在游戏内创建小号喔
          </div>

          {vouchers.map((voucher, index) => (
            <Fragment key={index}>
              <div
                className="item"
                style={{
                  backgroundImage: "url(/resources/voucher/voucher.png)",
                }}
                onClick={() => setExplain(voucher)}
              >
                <div className="amounts">
                  <div className="amount">￥{voucher.amount * 1}</div>
                  <div className="amount-meet">
                    满{voucher.meet_amount * 1}元可用
                  </div>
                </div>
                <div className="info">
                  <div className="name">{voucher.name}</div>
                  <div className="game">
                    仅限《{voucher.game_name_main || voucher.game_name}》
                  </div>
                  <div className="time">
                    {dayjs.unix(voucher.start_time).format("YYYY-MM-DD")} ~{" "}
                    {dayjs.unix(voucher.end_time).format("YYYY-MM-DD")}
                  </div>
                  {/* <div className="use">使用方法&gt;</div> */}
                </div>
                {voucher.is_received != "0" ? (
                  <div className="operate disable">已领取</div>
                ) : (
                  <NavLink
                    className="operate"
                    onClick={(e) => {
                      e.stopPropagation();
                      receive(voucher);
                    }}
                  >
                    立即领取
                  </NavLink>
                )}
              </div>
            </Fragment>
          ))}
          {explain ? (
            <Explain voucher={explain} setVoucher={setExplain} />
          ) : null}
        </div>
      </div>
    </>
  );
};
