import React, {useEffect, useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import general from "../../general";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import {useScroll, useThrottleEffect} from "ahooks";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [kw, setKw] = useState('');
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    Dialog.loading();
    let tmp = {
      search_info: kw,
      pagination: {page: page, count: 50},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/game/getGameList', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
          setMore(result.paginated.more);
        })
        .catch(error => {
          Dialog.error(error.message);
        });
  }
  useThrottleEffect(() => {
    load({page: 1});
  }, [kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (scroll?.top < -80) {
      load({page: 1});
    }
    if (scroll?.top + window.innerHeight + 20 > $(document).height() && more) {
      load({page: page + 1});
    }
  }, [scroll]);

  return <div className="game-select">
    <div className="game-select-head">
      <input type="text" value={kw} placeholder="请输入游戏名称" onChange={e => setKw(e.target.value)}/>
      <div className="buttton" onClick={() => load({page: 1})}>
        <SearchOutlined className="icon"/>
        <div className="text">搜索</div>
      </div>
    </div>
    <div className="game-select-body">
      {games.map((game,index) => <div key={index} className="item" onClick={() => {
        general.game = game;
        history.goBack();
      }}>
        <div className={"type type-" + game.game_species_type}/>
        <div className="name">{game.game_name_main || game.game_name}</div>
      </div>)}
      <div className={'pull-to-load ' + (!more ? 'nomore' : '')}/>
    </div>
  </div>;
};