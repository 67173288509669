import {useMount} from "ahooks";
import dayjs from 'dayjs';
import Dialog from "../../lib/Dialog";

export default props => {
  useMount(() => {
    let voucher = props.voucher;
    Dialog.alert('使用方法', <div>
      可用范围：{['superMember', 'monthcard'].includes(voucher.use_target) ? `仅限购买${voucher.game_name}使用` : `仅限《${voucher.game_name_main || voucher.game_name}》`}<br/>
      有效期：{dayjs.unix(voucher.start_time).format('YYYY-MM-DD')} ~ {dayjs.unix(voucher.end_time).format('YYYY-MM-DD')}<br/>
      使用条件：满{voucher.meet_amount * 1}元可用<br/>
      使用方法：<br/>
      {voucher.use_target == 'superMember' ? <div>
        APP—>福利中心—>超级会员—>购买{{month: '月', year: '年'}[voucher.game_id] || '超级会员'}卡，选择使用即可
      </div> : <>
        {voucher.use_target == 'monthcard' ? <div>
          APP—>福利中心—>省钱卡—>购买{{month: '月', year: '年'}[voucher.game_id] || '省钱'}卡，选择使用即可
        </div> : <div>
          1.使用App账号登录游戏；<br/>
          2.在游戏内购买道具；<br/>
          3.在支付页面选择可用的优惠券，完成支付。<br/>
        </div>}
      </>}
      {voucher.use_target == 'monthcard' && '特别说明：新人限时折扣和优惠券不能一起使用。'}
    </div>, () => props.setVoucher(null), {btn: '我知道了'});
  });

  return <>

  </>;
};