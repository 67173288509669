import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import general from "../../general";
import { useUpdateEffect, useScroll } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

export default ({ active, tag }) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      kf_type: 30,
      game_classify_id: tag,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/base/game/getKaiFuList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
        parms.finishPullUp && parms.finishPullUp();
        parms.finishPullDown && parms.finishPullDown();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        setDatas((datas) =>
          parms.page === 1 || !parms.page
            ? result.kaifu_list
            : [...datas, ...result.kaifu_list]
        );
        setMore(result.paginated.more);
      })
      .catch((err) => {
        Dialog.error("getKaiFuList " + err.message);
      });
  };
  useEffect(() => {
    if (active && !init) {
      load();
    }
  }, [active]);
  useUpdateEffect(() => {
    $("html,body").scrollTop(0);
    setPage(1);
    if (active) {
      load();
    }
  }, [tag]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, active]);

  return (
    <div className="history-body">
      <div className="server-groups">
        {datas.map((game) => (
          <Link
            key={game.game_id}
            to={"/game?id=" + game.game_id}
            className="server-item"
          >
            <div className="icon">
              <Image src={game.game_image.thumb} />
            </div>
            <div className="data">
              <div className="name">
                {game.game_name_main || game.game_name}
              </div>
              <div className="desc">
                {game.game_name_branch ? (
                  <div className="branch">{game.game_name_branch}</div>
                ) : null}
                {game.game_classify_type}
                &nbsp;·&nbsp;
                {game.game_size?.ios_size}
              </div>
              <div className="info">
                <div className="server">{game.kaifu_name}</div>
                <div className="time">{game.kaifu_name}</div>
              </div>
            </div>
            <div className="btn">下载</div>
          </Link>
        ))}
      </div>
      <div className="view-end">别撩啦！已经到底啦~</div>
    </div>
  );
};
