import useActiveState from "../../lib/useActiveState";
import { Link } from "react-router-dom";
import { NavLink } from "../../lib/Navigation";
import { useThrottleEffect } from "ahooks";
import general from "../../general";
import Dialog from "../../lib/Dialog";

// 首页顶部搜索框
export default (props) => {
  const [messages, setMessages] = useActiveState("index-messages");
  useThrottleEffect(
    () => {
      if (props.active && general.isLogin()) {
        general.axios
          .post("/getUnReadWarn")
          .then(({ data: resData }) => {
            let { status, data } = resData;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              return null;
            }
            setMessages(data.unread_warn == 1);
          })
          .catch((error) => console.error(error.message));
      }
    },
    [props.active],
    { wait: 500 }
  );

  return (
    <div className="search">
      <div className="status-bar-background" />
      <Link to="/search" className="opt">
        <img
          className="search-img"
          src="/resources/index/title/search.png"
          alt=""
        />
      </Link>
      <div className="opt msg">
        <NavLink to="/messages" className={`item ${messages ? "active" : ""}`}>
          <img
            className="search-img"
            src="/resources/index/title/msg.png"
            alt=""
          />
        </NavLink>
      </div>
    </div>
  );
};
