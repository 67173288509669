import { useUpdate } from "ahooks";
import Image from "../../lib/Image";
import { Link } from "react-router-dom";
import general from "../../general";

export default ({ multiple, max, files, setFiles }) => {
  const update = useUpdate();

  const _setFiles = (files) => {
    setFiles([
      ...files
        .filter((file, index) => {
          return index < max;
        })
        .map((file) => {
          if (file instanceof File) {
            var fr = new FileReader();
            let tmp = { file: file };
            fr.onload = (e) => {
              tmp.url = e.target.result;
              update();
            };
            fr.readAsDataURL(file);
            return tmp;
          } else if (typeof file === "string") {
            return { url: file };
          }
          return file;
        }),
    ]);
  };

  return (
    <div className="image-picker">
      {files.map((file, index) => (
        <div key={index} className="item item-file">
          <Link
            to={"/img?img=" + 1}
            className="img"
            onClick={() => (general.imgs = files.map((file) => file.url))}
          >
            <Image src={file.url} alt="" />
          </Link>
          <div
            className="icon"
            onClick={() => _setFiles(files.filter((item) => item !== file))}
          >
            x
          </div>
        </div>
      ))}
      {files.length < max ? (
        <div className="item item-input">
          <div className="icon">+</div>
          <input
            type="file"
            multiple={multiple}
            onChange={(e) => _setFiles([...files, ...e.target.files])}
          />
        </div>
      ) : null}
    </div>
  );
};
