import { useEffect, useRef, useState } from "react";
import { useEventListener, useInterval } from "ahooks";
import { useHistory } from "react-router-dom";
import { sysUrl } from "../../config";
import { NavLink } from "../../lib/Navigation";

function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

export default (props) => {
  const history = useHistory();
  // console.log("props.query-----", props.query);

  useEffect(() => {
    if (props.query.title) {
      props.setTitle(props.query.title);
    }
    if (props.query.url.indexOf("subsidy2200") !== -1) {
      props.setTitle("百亿补贴");
    }
  }, [props.query]);
  const [progress, setProgress] = useState(randomNum(25, 50));
  const [interval, setInterval] = useState(250);
  useInterval(() => {
    if (progress === 100) {
      /*if (props.query.callback === 'close') {
        setTimeout(() => history.goBack(), 1920);
      }*/
      setInterval(null);
    } else {
      setProgress(progress + randomNum(0, (100 - progress) / 2));
    }
  }, interval);

  const ref = useRef(null);

  useEventListener("message", (e) => {
    const message = JSON.parse(e.data);
    if (message.title) {
      props.setTitle(message.title);
    }
    console.log("h5页面渲染啥呀英语语言一月又一月", message);
    if (message.operate === false) {
      props.setOperate(null);
    } else {
      /*growthRole();*/
      if (message.operate == "feedback") {
        props.setOperate(
          <NavLink
            className="my"
            user={true}
            to={`${sysUrl}/home/actcloudhook/feedback`}
            iframe={{ title: "问题反馈" }}
          >
            问题反馈
          </NavLink>
        );
      } else if (message.operate == "notice") {
        props.setOperate(
          <NavLink
            style={{ width: "21px", height: "21px" }}
            user={true}
            to={`${sysUrl}/home/actcloudhook/notice`}
            iframe={{ title: "购买须知" }}
          >
            <img
              src="/resources/hangup/notice.png"
              style={{ width: "21px", maxWidth: "21px", height: "21px" }}
              alt=""
            />
          </NavLink>
        );
      }
    }
  });
  /*const growthRole = () => {
    if (props.query.url.indexOf('/home/growth') > 0) {
      props.setOperate(<ExclamationCircleOutlined onClick={() => {
        ref.current.contentWindow.postMessage('role', '*');
      }}/>);
    }
  }
  useMount(growthRole);*/

  useEffect(() => {
    if (props.active && props.action === "POP") {
      console.info("iframe onenter");
      ref.current.contentWindow.postMessage("onenter", "*");
    }
  }, [props]);

  return (
    <div className="view-iframe">
      <div
        className={"progress " + (progress === 100 ? "loaded" : "loading")}
        style={{ width: progress + "%" }}
      />
      <iframe src={props.query.url} onLoad={() => setProgress(100)} ref={ref} />
    </div>
  );
};
