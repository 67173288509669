import { useCallback, useEffect, useState } from "react";
import { CommentOutlined, LikeOutlined } from "@ant-design/icons";
import general from "../../../general";
import $ from "jquery";
import dayjs from "dayjs";
import { NavLink } from "../../../lib/Navigation";
import { useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";

export default (props) => {
  const [game, setGame] = useState({});
  useEffect(() => {
    setGame(props.game);
  }, [props.game]);
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      topic_id: game.comment_topic_id,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/base/comment/getNewCommentList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { data } = response.data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? data.list : [...datas, ...data.list]
        );
        setMore(data.paginated.more && data.list.length > 0);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init && game.game_id) {
      load();
    }
  }, [props.active, game]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const ref = useCallback((el) => {
    if (el) {
      if ($(el).height() > $(el).parents(".comment-content").height()) {
        $(el).parents(".comment-content").addClass("switchable");
      }
    }
  }, []);
  const toggle = (e) => {
    e.preventDefault();
    $(e.target).parents(".comment-content").toggleClass("open");
  };

  const like = (e, index) => {
    let comment = datas[index];
    if (parseInt(comment.me_like)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    general.axios
      .post("/base/comment/likeComment", { id: comment.id })
      .finally(() => {
        Dialog.close();
      })
      .then((response) => {
        let { status } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setDatas((datas) => {
            datas[index] = {
              ...datas[index],
              me_like: 1,
              like_count: parseInt(datas[index].like_count) + 1,
            };
            return [...datas];
          });
        }
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };

  return (
    <>
      <div className="comment-container">
        <NavLink className="game-qa" to={"/question?id=" + game.game_id}>
          <div className="text">
            <div className="info1">有{game.played_count}人正在玩该游戏</div>
            <div className="info2">
              快加入《{game.game_name_main || game.game_name}》问答社区
            </div>
          </div>
          <div className="operate">加入讨论&gt;</div>
        </NavLink>
        {datas.length > 0 ? (
          <div className="game-comment">
            <div className="comment-title">玩家评论</div>

            {datas.map((comment, index) => (
              <NavLink
                key={comment.id}
                to={"/comment?id=" + comment.id}
                className="comment-item"
              >
                {comment.reward_intergral_amount ? (
                  <div
                    className="comment-reward"
                    style={{
                      backgroundImage: "url(/resources/comment/reward.png)",
                    }}
                  >
                    {comment.reward_intergral_amount}
                  </div>
                ) : null}
                <div className="comment-user">
                  <div className="avatar">
                    <Image src={comment.user_icon} alt="" />
                  </div>
                  <div className="info">
                    <div className="name">{comment.user_nickname}</div>
                    <div className="count">
                      共发表{comment.user_comment_count}个游戏评论
                    </div>
                  </div>
                  {!comment.reward_intergral_amount && (
                    <div className="time">
                      {dayjs.unix(comment.time).format("MM-DD HH:mm")}
                    </div>
                  )}
                </div>
                <div className="comment-main-content">
                  <div className="comment-content">
                    <div className="content">
                      <div ref={ref}>{comment.content}</div>
                    </div>
                    <div className="ctrl" onClick={toggle} />
                  </div>
                  {comment.pic_list.length > 0 && (
                    <div className="comment-img">
                      {comment.pic_list.map((img, index) => (
                        <div key={index} className="item">
                          <Image src={img} alt="" />
                        </div>
                      ))}
                    </div>
                  )}

                  {comment.reply_list.length > 0 ? (
                    <div className="comment-reply">
                      {comment.reply_list.map((reply, index) => (
                        <div key={index} className="reply-item">
                          <div className="reply-user">
                            {reply.user_nickname}：
                          </div>
                          <div className="reply-content">{reply.content}</div>
                        </div>
                      ))}
                      {comment.reply_count > comment.reply_list.length ? (
                        <div className="reply-more">查看全部28条回复&gt;</div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="comment-info">
                    <div className="device">{comment.device_name}</div>
                    <div className="count">
                      <div className="item reply">
                        <img
                          src="/resources/game/msg.jpg"
                          alt=""
                          className="icon"
                        />
                        {comment.reply_count}
                      </div>

                      <object>
                        <NavLink
                          className="item like"
                          onClick={(e) => like(e, index)}
                        >
                          {parseInt(comment.me_like) ? (
                            <img
                              src="/resources/game/like.jpg"
                              alt=""
                              className="icon"
                            />
                          ) : (
                            <img
                              src="/resources/game/notlike.jpg"
                              alt=""
                              className="icon"
                            />
                          )}

                          {comment.like_count}
                        </NavLink>
                      </object>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              paddingTop: "30px",
            }}
          >
            <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
            <p>暂无评论发布</p>
            <NavLink
              to={"/comment/submit?id=" + game.comment_topic_id}
              style={{ color: "#F3AE00" }}
            >
              去发布
            </NavLink>
          </div>
        )}
      </div>
    </>
  );
};
