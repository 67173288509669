import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import List from "./List";
import { NavLink } from "../../lib/Navigation";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMount, useSize } from "ahooks";
import KeepScroll from "../../lib/KeepScroll";

const types = [
  {
    id: "featured",
    name: "官方精选",
  },
  {
    name: "最新发布",
  },
];

export default (props) => {
  const ref = useRef(null);
  const size = useSize(ref);
  const [tab, setTab] = useState("featured");

  useMount(() => {
    props.setOperate &&
      props.setOperate(
        <Link to="/trade/notice" className="trade-index-notice">
          <ExclamationCircleOutlined />
        </Link>
      );
  });

  return (
    <>
      <div className="trade-trades">
        <div className="trade-head width">
          <div className="status-bar-background" />
          <div className="container">
            <div className="text">账号交易</div>
            <Link to="/trade/notice" className="option">
              <ExclamationCircleOutlined />
            </Link>
          </div>
        </div>
        <div className="trade-body">
          <div className="trade-head width" ref={ref}>
            <div className="trade-nav">
              {[
                {
                  id: "recovery",
                  text: "小号回收",
                  url: "/recovery",
                  user: true,
                },
                {
                  id: "submit",
                  text: "我要卖号",
                  url: "/trade/submit",
                  user: true,
                },
                {
                  id: "record",
                  text: "交易记录",
                  url: "/trade/record",
                  user: true,
                },
              ].map((item) => (
                <NavLink
                  key={item.id}
                  to={item.url}
                  user={item.user}
                  className="item"
                >
                  <img
                    src={
                      "/resources/trade/" + item.id + ".png?version=20220907"
                    }
                    alt=""
                  />
                </NavLink>
              ))}
            </div>
            <div className="trade-tab">
              {types.map((item, index) => (
                <div
                  key={index}
                  className={`item ${item.id === tab ? "active" : ""}`}
                  onClick={() => setTab(item.id)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="trade-body" style={{ paddingTop: size?.height }}>
            {types.map((item, index) => (
              <KeepScroll
                key={index}
                active={props.active && item.id === tab}
                lazy
              >
                <div
                  className={`trade-body-item ${
                    item.id === tab ? "active" : ""
                  }`}
                >
                  <List active={props.active} good={tab === "featured"} />
                </div>
              </KeepScroll>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
