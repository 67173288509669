import React, { useEffect, useState } from "react";
import { useLockFn, useScroll } from "ahooks";
import general from "../../general";
import $ from "jquery";
import dayjs from "dayjs";
import Dialog from "../../lib/Dialog";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let data = {
      pagination: { page: page },
    };
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    general.axios
      .post("/user/pay/getRechargeList", data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { paginated, pay_list: list } = data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("getRechargeList " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div className="ptb-record">
      {datas.map((data, index) => (
        <div key={index} className="item">
          <div className="time">
            {dayjs.unix(data.recharge_time).format("YYYY-MM-DD HH:mm:ss")}
          </div>
          <div className="amount">+{data.recharge_coin}</div>
        </div>
      ))}
      {datas.length === 0 && init ? (
        <div className="empty">
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          暂无数据
        </div>
      ) : null}
    </div>
  );
};
