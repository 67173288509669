import React, { useEffect, useState } from "react";
import general from "../../general";
import $ from "jquery";
import { useLockFn, useScroll, useThrottleEffect } from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

export default (props) => {
  const [user, setUser] = useActiveState("user");
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const load = useLockFn(async (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      search_content: props.kw,
      pagination: { count: 20, page: page },
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios
      .post("/user/integralShop/couponList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInit(true);
        if (parms.page) {
          setPage(parms.page);
        }
        let { voucherList: list, paginated } = data;
        setDatas((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );
        setMore(paginated.more && list.length > 0);
      })
      .catch((err) => {
        Dialog.error("couponList " + err.message);
      });
  });
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  useThrottleEffect(() => {
    if (init) {
      $("html,body").scrollTop(0);
      load({ page: 1 });
    }
  }, [props.kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 >= $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const exchange = useLockFn(async (data) => {
    Dialog.loading();
    await general.axios
      .post("/user/integralShop/exchangeCoupon", { g_id: data.v_id })
      .finally(() => {
        Dialog.close();
      })
      .then((res) => {
        let { status } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setUser({ ...user, integral: user.integral - data.goods_price });
          Dialog.success("兑换成功");
        }
      })
      .catch((err) => {
        Dialog.error("exchangeCoupon " + err.message);
      });
  });

  return (
    <div className="credit-time">
      {datas && datas.length > 0 ? (
        <>
          {datas.map((data, index) => (
            <div key={index} className="item">
              <div
                className="voucher"
                style={{
                  backgroundImage: "url(/resources/credit/voucher.png)",
                }}
              >
                <div className="info">
                  <div className="type">满减券</div>
                  <div className="desc">仅限BT游戏使用</div>
                  <div className="time">有效期：{data.valid_day}</div>
                </div>
                <div className="price">
                  <div className="amount">￥{data.voucher_amount}</div>
                  <div className="meet">满{data.voucher_meet_amount}元可用</div>
                </div>
              </div>
              <div className="data">
                <div className="price">价格:{data.goods_price}积分</div>
                <div className="count">库存:{data.surplus_num}</div>
                <div className="btn" onClick={() => exchange(data)}>
                  立即兑换
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          <img style={{ width: "70%" }} src="/resources/empty.png" alt="" />
          <p>暂无数据~</p>
        </div>
      )}
    </div>
  );
};
