import { useState } from "react";

let ios16;
let res = navigator.userAgent.match(/OS (\d+).* like Mac OS/);
let version;
if (res) {
  version = res[1];
  ios16 = res[1] >= 16;
}
console.warn({ ios16 });

export default (props) => {
  const [confirm, setConfirm] = useState(!ios16);

  // 在 JavaScript (JS) 中，无法直接通过代码来判断用户是否已经开启了iOS设备的开发者模式。因为开发者模式属于操作系统的功能，需要由用户手动进行设置并且只有特定版本的iOS才支持该功能（如iOS 9及以上）
  // 原生应用程序可以获取

  return (
    <>
      {confirm ? (
        props.children
      ) : (
        <>
          <div className="game-ios-guide">
            <div className="giu-container">
              <div className="giu-titlle">
                <div className="title-font">下载提示</div>
              </div>
              <div className="giu-tips">
                因您的系统为IOS{version}
                ，现按照IOS官方要求，需开启开发者模式才可进入游戏
              </div>
              <img src="/resources/game/guide.png" alt="" />
              <div className="btn" onClick={() => setConfirm(true)}>
                我知道了
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
