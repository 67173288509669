import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.css";
import "video-react/dist/video-react.css";
import "../css/css.css";
import { navigationRouter, screenRouter } from "../router";
import URI from "urijs";
import general, { redux, spm } from "./../general";
import { NavLink, NavView } from "../lib/Navigation";
import {
  useEventListener,
  useLocalStorageState,
  useMount,
  useTimeout,
} from "ahooks";
import ActiveContext from "../lib/ActiveContext";
import useActiveState from "../lib/useActiveState";
import $ from "jquery";
import { state } from "../lib/reducer";
import { useSelector } from "react-redux";
import Dialog from "../lib/Dialog";
import Image from "../lib/Image";
import { Link, useHistory, useLocation } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { prod } from "../config";
import JSEncrypt from "jsencrypt";

const mobileDetect = new MobileDetect(navigator.userAgent);

const desktopTipsBranch = (
  (navigator.userAgent.match(/(edgios)/i) || {})[0] || ""
).toLowerCase();

let prev = null;

// 入口文件
export default () => {
  let history = useHistory();
  let location = useLocation();

  console.log("入口文件--------");

  useEffect(() => (general.history = history), [history]);
  useEffect(() => (general.location = location), [location]);

  let [params, setParams] = useUrlState({}, { navigateMode: "replace" });

  const [standalone, setStandalone] = useLocalStorageState("standalone");
  useMount(() => {
    if (params.standalone) {
      setStandalone(params.standalone);
    }

    if (params.bd_vid) {
      localStorage.setItem("bd_vid", params.bd_vid);
    }
  });

  const [active, setActive] = useLocalStorageState("active", {
    defaultValue: {},
  });
  const { aplus_queue } = window;

  const { theme } = useSelector(state);
  let [agent, setAgent] = useActiveState("agent");
  let [udid, setUdid] = useActiveState("udid");
  let [key, setKey] = useActiveState("key");
  let [invalidKeys, setInvalidKeys] = useLocalStorageState("invalid-keys", {
    defaultValue: [],
  });
  let [app, setApp] = useActiveState("app");
  useMount(async () => {
    if (params.agent) {
      setAgent((agent = params.agent));
      localStorage.setItem("agent", params.agent);
      console.log("agenttttttttttttttttttttt", localStorage.getItem("agent"));
    }
    console.log("设置udidbbbbbbbbb--------------");
    params.udid && setUdid((udid = params.udid));

    if (params.key) {
      if (!invalidKeys.includes(params.key)) {
        setKey((key = params.key));
      }
    } else {
      setKey((key = ""));
    }
    console.log(key);

    if (!app.brand) {
      if (params.brand) {
        app.brand = params.brand;
      }
      let tmp = {
        "9917游戏": "9917",
        BT玩: "btwan",
        嗨玩游戏: "hiwan",
        "97游戏盒": "97",
        泡泡熊: "zc",
        大碗游戏盒: "dawan",
      };
      if (app.appname && tmp[app.appname]) {
        app.brand = tmp[app.appname];
      }
    } else {
      if (params.brand) {
        app.brand = params.brand;
      }
    }
    app = params;
    setApp(params);
    console.log("params--------------", params);
    console.log("app------------------", app);

    if (!key) {
      await general.axios
        .post("/base/newindex/setKeyUdid", {
          agent,
          brand: params.brand,
          appname: params.appname,
          appicon: params.appicon,
          applogo: params.applogo,
          flash_screen: params.flash_screen,
        })
        .then((res) => {
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setKey((key = data.key));
          setParams((params = { ...params, agent: agent, key: data.key }));
          if (
            params.appname ||
            params.appicon ||
            params.applogo ||
            params.flash_screen
          ) {
            setParams(
              (params = {
                ...params,
                appname: null,
                appicon: null,
                applogo: null,
                flash_screen: null,
              })
            );
          }
        })
        .catch((error) => Dialog.error("setKeyUdid " + error.message));
    }

    if (key && (app.key !== key || !app.udid)) {
      general.axios
        .post("/base/newindex/getKeyUdid", {
          agent,
          key,
          udid,
          brand: params.brand,
        })
        .then((res) => {
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (data.key && data.key !== key) {
            if (!invalidKeys.includes(app.key)) {
              setInvalidKeys([...invalidKeys, key]);
            }
            setKey((key = data.key));
            setParams({ key });
          }
          for (const key of ["appname", "appicon", "applogo", "flash_screen"]) {
            if (app[key + "_lock"]) {
              delete data[key];
            }
          }
          setApp((app = { ...app, ...data }));
          if (data.udid) {
            setUdid((udid = data.udid));
          }
          if (data.brand) {
            setParams((params = { ...params, brand: data.brand }));
          }
          if (
            params.appname ||
            params.appicon ||
            params.applogo ||
            params.flash_screen
          ) {
            const ts = [];
            for (const key of [
              "appname",
              "appicon",
              "applogo",
              "flash_screen",
            ]) {
              if (params[key]) {
                ts[key] = params[key];
                ts[key + "_lock"] = params[key];
              }
            }
            setApp((app = { ...app, ...ts }));
            setParams(
              (params = {
                ...params,
                appname: null,
                appicon: null,
                applogo: null,
                flash_screen: null,
              })
            );
          }
        })
        .catch((err) => Dialog.error("getKeyUdid " + err.message));
    }

    if (key) {
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["openid", key],
      });
    }
  });
  const urlLogin = () => {
    if (
      !localStorage.getItem("logout") &&
      params.userid &&
      params.username &&
      params.token
    ) {
      localStorage.setItem("user_id", params.userid);
      localStorage.setItem("username", params.username);
      localStorage.setItem("token", params.token);
    }
  };
  useMount(urlLogin);
  useEffect(() => {
    history.action !== "POP" && urlLogin();
  }, [params, history.action]);
  useEffect(() => {
    params.udid && setUdid(params.udid);
  }, [params.udid]);
  useEffect(() => {
    if (app.appname) {
      document.title = app.appname;
      $("meta[name=apple-mobile-web-app-title]").attr("content", app.appname);
    }
    if (app.appicon) {
      if (!app.brand || app.brand == "9917") {
        $("link[id=appleTouchIcon]").attr(
          "href",
          "https://filestorage.9917.cn/agent-material/bc8f/icon.png"
        );
      } else {
        $("link[id=appleTouchIcon]").attr("href", app.appicon);
      }
    }
    if (app.flash_screen) {
      console.log("app.flash_screen-------------------", app.flash_screen);
      [
        { width: 2048, height: 2732 },
        { width: 1668, height: 2388 },
        { width: 1536, height: 2048 },
        { width: 1668, height: 2224 },
        { width: 1620, height: 2160 },
        { width: 1284, height: 2778 },
        { width: 1170, height: 2532 },
        { width: 1125, height: 2436 },
        { width: 1242, height: 2688 },
        { width: 828, height: 1792 },
        { width: 1242, height: 2208 },
        { width: 750, height: 1334 },
        { width: 640, height: 1136 },
      ].forEach(({ width, height }) => {
        $('link[id="' + (width + "-" + height) + '"]').attr(
          "href",
          app.flash_screen +
            (width === 1170 && height === 2532
              ? "!iphone12pro"
              : "!iphone_" + width + "x" + height)
        );
      });
    }
  }, [app]);

  const [config, setConfig] = useActiveState("config");
  useMount(() => {
    general.axios
      .post("/base/common/getConfig")
      .then((res) => {
        let { status, data } = res.data;
        if (status.succeed) {
          setConfig({ ...config, ...data });
        }
      })
      .catch((error) => Dialog.error("getConfig " + error.message));
  });
  useEffect(() => {
    if (
      config.udid_usable !== undefined &&
      !config.udid_usable &&
      localStorage.getItem("udid_usable")
    ) {
      localStorage.setItem("udid_usable", "");
    }
  }, [config.udid_usable]);

  const [navs, setNavs] = useState([]);
  const [action, setAction] = useState([]);
  const handleNavs = () => {
    if (general.callbacking) {
      return;
    }
    let uri = URI(window.location.hash.substring(1));
    if (uri.pathname() === "/iframe") {
      let query = uri.query(true);
      if (query.url) {
        let iframeUri = URI(query.url);
        let iframeQuery = iframeUri.query(true);
        if (
          iframeQuery.username !== localStorage.getItem("username") ||
          iframeQuery.token !== localStorage.getItem("token")
        ) {
          history.replace(
            uri
              .setQuery(
                "url",
                iframeUri
                  .setQuery("username", localStorage.getItem("username"))
                  .setQuery("token", localStorage.getItem("token"))
                  .toString()
              )
              .toString()
          );
          uri = URI(window.location.hash.substring(1));
        }
      }
    }
    setNavs((_navs) => {
      let navs = [..._navs];
      let uri2 = URI(uri.search()).removeQuery("callback");
      if (
        uri.pathname().startsWith("/search") ||
        uri.pathname().startsWith("/gift/search")
      ) {
        uri2.removeQuery("kw");
      }
      let url = uri.pathname() + uri2.toString();
      let pathname = uri.pathname();
      // console.log(general.callbacking, general.callback);
      let router = navigationRouter.find((item) => item.path === pathname);
      if (router) {
        navs.reverse();
        let index = navs.findIndex((item) => item.url === url);
        navs.reverse();
        if (index > 0) {
          while (navs[navs.length - 1].url !== url) {
            navs.pop();
          }
        }
        if (index === -1) {
          navs.push({ ...router, query: uri.query(true), url });
        }
      } else {
        navs = [];
      }
      navs = navs.map((item, index, items) => {
        item.index = 700 + index + 1;
        item.active = items.length - 1 === index;
        item.action = items.length - 1 === index ? history.action : null;
        return item;
      });
      console.log("navs-------------------", [...navs]);
      return [...navs];
    });
  };
  useEffect(() => handleNavs(), [history, location]);

  useMount(() => {
    redux.subscribe(() => {
      if (redux.getState().url) {
        console.log("location", { ...location }, window.location.href);
        handleNavs();
      }
    });
    redux.subscribe(() => {
      let screen = redux.getState().screenRouter?.screen;
      if (screen) {
        history.replace(screen.replace("/", "/screen-"));
      }
    });
  });

  const encryptWithPublicKey = (message, publicKey) => {
    // 创建 JSEncrypt 实例
    const encrypt = new JSEncrypt();

    // 设置公钥
    encrypt.setPublicKey(publicKey);

    // 加密消息
    const encrypted = encrypt.encrypt(message);

    return encrypted;
  };

  useTimeout(() => {
    if (
      !active.time ||
      dayjs(active.time).format("YYYY-MM-DD") !== dayjs().format("YYYY-MM-DD")
    ) {
      console.log("--activate.time--");
      setActive((active) => ({ ...active, time: dayjs().valueOf() }));
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          { is_auto: false },
          {
            page: "index",
            isApp: !!window.navigator.standalone,
            agent,
          },
        ],
      });
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "enter",
          "enter",
          {
            isApp: !!window.navigator.standalone,
            agent,
          },
        ],
      });
    }

    if ((window.navigator.standalone || standalone) && !active.activate) {
      // if (window.navigator.standalone || standalone) {
      console.log("--activate.activate--");
      setActive((active) => ({ ...active, activate: dayjs().valueOf() }));

      // 进入盒子
      if (localStorage.getItem("bd_vid") && !localStorage.getItem("tgm_uuid")) {
        console.log("激活44444444444444");

        let time = new Date().getTime();
        let obj = {
          bd_vid: localStorage.getItem("bd_vid"),
          uuid: key,
          type: "ios",
          time,
        };
        let str = btoa(JSON.stringify(obj));

        // 示例公钥 (通常从服务器获取)
        const publicKey = `-----BEGIN PUBLIC KEY-----
        MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAu5phb2FQQiuZSlSxI09q
        4Mc3LIpuUTZP5+EBe480c9/zDkVgPmuIQcBMPbi5NC5yxpYOCUZkLjC8VDzMqDgS
        89c6hC3qY0btRBxON7gO9pk4ijUEa2xlvnefvYgXMpks13FT5To852iguQbW2k53
        DHD6Efsvbvh/Nl1kRXhYGPw3XWAQeDNS9xFESpx+9r4KWcm8D/R2fnv5w1P/UvcN
        rYVDMTGkEmOGnVl96WZBPcHuZRrRcXcbzTE3TMzabQAdqR+FDPRPWpK5F/WUuWWb
        KL+MS3dvREdOxYPgIfvyPm4ztLp0I+v06/fZvUOembo4Bokj0AusbYKqUkp3s6El
        11xmHyaRJULh9PYJ9xfte1oihnRpU+HenVUfHBQBy8XBzHkhUsVfKV+WGaARYRQ2
        efSsTojji8WBXpt63kkkltcgSZJvQ9gPcSTAz3xRkX6tRTEA61AsbT1ua+uJlFWa
        97P3xHd+SGcOYsOjSbnpB/WGxBMs7dX2FEuevaEEgyJr+d336jhzK/6Mc6eaIKlP
        PZyZfXUH/KK3YIllDSx2WbAHibTZfetp/76KL+QBLLv2QFGwjWZKxCZ/M2zRvRGL
        1wLLuw5DAr8LiodEt1YABpVO28YNwf87zpt4XZ7bv4ubNP8NYONSLAB7YqPUnG58
        UQ8I3Yvw2k3Nmos/Cs/vFasCAwEAAQ==
        -----END PUBLIC KEY-----`;

        // 调用加密函数
        const encryptedMessage = encryptWithPublicKey(str, publicKey);

        general.axios
          .post("/tgm/bind", {
            bd_vid: localStorage.getItem("bd_vid"),
            uuid: key,
            type: "ios",
            sign: encryptedMessage,
            time,
          })
          .then((res) => {
            console.log(res);

            localStorage.setItem("tgm_uuid", res?.data?.data?.uuid || "");
          })
          .catch((error) => console.log("media-point " + error));
      }

      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "activate",
          "activate",
          {
            agent,
          },
        ],
      });

      // general.axios.post("/home/ocpc/activation");
    }
  }, 1527);
  const [activity, setActivity] = useState(true);
  const [toSafariCopy, setToSafariCopy] = useState();

  const [safeArea, setSafeArea] = useState();
  useEffect(() => {
    setSafeArea(
      parseInt($(".safeareatest").css("padding-top")) > 20 ||
        parseInt($(".safeareatest").css("padding-bottom")) > 20
    );
  });

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEventListener("message", (e) => {
    console.log(e);
    if (e.data === "undefined" || !isJSONString(e.data)) {
      return;
    }

    const message = JSON.parse(e.data);
    if (message.type === "history") {
      let url = message.url;
      if (url === "back") {
        history.goBack();
        return;
      }
      if (url.startsWith("box9917://")) {
        url = url.replace("box9917://", "");
      }
      let uri = URI(url);
      console.log("message url scheme", uri.scheme());
      if (!uri.scheme()) {
        if (url.startsWith("/login")) {
          history.push(
            URI("/login")
              .addQuery(
                "callback",
                location.pathname + location.search + location.hash
              )
              .toString()
          );
        } else {
          history.push(url);
        }
      } else {
        console.log("message url", url);
        window.location.href = url;
      }
    } else if (message.type === "passBack") {
      console.log("H5页面行为回传---------------", message);
    } else if (message.type === "payUrl") {
      console.log("云挂机H5回传---------------", message);

      window.location.href = message.url;
    } else if (
      message.type == "exitCloudApp" ||
      message.type == "leaveHanging"
    ) {
      console.log(message);
    }
  });

  useEffect(
    function () {
      let url = window.location.hash.substring(1);
      let uri = URI(url);
      if (
        localStorage.getItem(`browse_${url}`) !==
        dayjs(active.time).format("YYYY-MM-DD")
      ) {
        localStorage.setItem(
          `browse_${url}`,
          dayjs(active.time).format("YYYY-MM-DD")
        );
        let data = {
          isApp: !!window.navigator.standalone,
          agent,
          prevPath: prev?.path,
          prevUrl: prev?.url,
          currPath: uri.pathname(),
          currUrl: url,
        };
        // console.log(data);
        aplus_queue.push({
          action: "aplus.record",
          arguments: ["browse", "browse", data],
        });
      }
      prev = {
        path: uri.pathname(),
        url: url,
      };
    },
    [location]
  );

  let [safeAreaInsetTop, setSafeAreaInsetTop] = useState();
  useMount(() => {
    if (params["safe-area-inset-top"]) {
      setSafeAreaInsetTop(
        (safeAreaInsetTop = params["safe-area-inset-top"] * 1)
      );
      if (safeAreaInsetTop) {
        $("head").append(
          "<style>:root { --safe-area-inset-top: " +
            safeAreaInsetTop +
            "px; }</style>"
        );
      }
    }
  });

  const [guide, setGuide] = useActiveState("guide");
  const [guided] = useActiveState("guided");
  useMount(() => {
    if (!guided && navigator.standalone) {
      setGuide("guide");
    }
  });

  const [tikShow, setTikShow] = useActiveState("tikShow");
  useMount(() => {
    if (navigator.userAgent == "uniapp") {
      let x;
      document.addEventListener("touchstart", (e) => {
        x = e.changedTouches[0].pageX;
      });
      document.addEventListener("touchend", (e) => {
        if (x !== undefined && x < 23 && e.changedTouches[0].pageX - x > 23) {
          // console.log('goBack!!!');
          history.goBack();
        }
        x = undefined;
      });
    }

    // 抖音审核
    general.axios
      .post("/base/apphome/agentDisplay")
      .then((res) => {
        let { status, data } = res.data;
        if (status.succeed) {
          console.log("抖音审核是否显示----", data);

          setTikShow(data);
        }
      })
      .catch((error) => Dialog.error("getConfig " + error.message));
  });

  return (
    <>
      <div
        className={
          "root" +
          (safeArea ? " root-hassafearea" : " root-nosafearea") +
          " theme-" +
          theme +
          (safeAreaInsetTop ? " var-safe-area" : "")
        }
      >
        <div className="safeareatest" />

        {/* 
        window.navigator.standalone 可以确定用户目前访问是否为 书签版访问
        iOS上的Safari浏览器可以让Web应用程序全屏显示，以取得类似本地应用的显示效果。但是这需要用户把Web应用程序的图标添加到主屏幕才可以。
        作为开发者，为了更好的显示效果，我们可能希望自己开发的Web应用程序在非全屏状态下运行时提示用户把Web应用程序的图标添加到主屏幕。

        要检测Web应用程序当前是否运行在全屏状态，只要检测window.navigator.standalone是否为true就可以了，如果这个属性为true则表示Web应用程序
        当前运行在全屏状态，否则运行在非全屏状态。检测到Web应用程序运行在非全屏状态时就可以提示用户把Web应用程序的图标添加到主屏幕 */}
        {/* 
        未加书签  生产环境  手机或者ipad  域名不为本地 
        有活动展示活动  
        没活动  若不是Safari浏览器---展示复制打开Safari弹窗  若是Safari---展示加书签步骤图
        */}
        {/* {!window.navigator.standalone &&
        prod &&
        (mobileDetect.is("iPhone") ||
          mobileDetect.is("iPad") ||
          navigator.userAgent == "app") &&
        window.location.hostname !== "localhost" &&
        !window.location.hostname.startsWith("192.168") &&
        navs.length === 0 &&
        !standalone ? (
          activity ? (
            <div className="activity-tips" onClick={() => spm("tips")}>
              {!/Safari\/[^\s]+$/i.test(navigator.userAgent) ? (
                <>
                  <CopyToClipboard
                    text={window.location.href}
                    onCopy={() => {
                      setActivity(false);
                      setToSafariCopy(true);
                    }}
                  >
                    <Image
                      src={`/resources/${
                        app.brand == "btwan"
                          ? "index/alert-btwan.png"
                          : "index/alert-btwan.png"
                      }`}
                    />
                  </CopyToClipboard>
                </>
              ) : (
                <>
                  <Image
                    src={`/resources/${
                      app.brand == "btwan"
                        ? "index/alert-btwan.png"
                        : "index/alert-btwan.png"
                    }`}
                    onClick={() => setActivity(false)}
                  />
                </>
              )}
            </div>
          ) : (
            <>
              {!/Safari\/[^\s]+$/i.test(navigator.userAgent) && toSafariCopy ? (
                <>
                  <div className="to-safari-copy">
                    <div className="container">
                      {app.brand == "btwan" ? (
                        <>
                          <Image
                            className="img"
                            src="/resources/to-safari-copy-btwan.png"
                          />
                        </>
                      ) : (
                        <>
                          {app.brand == "hiwan" ? (
                            <>
                              <Image
                                className="img"
                                src="/resources/to-safari-copy-hiwan.png"
                              />
                            </>
                          ) : (
                            <>
                              <Image
                                className="img"
                                src="/resources/to-safari-copy-9917.png"
                              />
                            </>
                          )}
                        </>
                      )}
                      <div
                        className="close"
                        onClick={() => {
                          setToSafariCopy(false);
                          setActivity(true);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {params.mc ? (
                    <div className="mobileconfig-tips">
                      <div className="container">
                        <img src="/resources/mobileconfig.png" alt="" />
                        <a
                          href={URI("//app.9917.cn/webbox.php?")
                            .addQuery("url", window.location.href)
                            .addQuery("name", app.appname)
                            .addQuery("icon", app.appicon)}
                          onClick={() => {
                            setTimeout(() => {
                              window.location.href =
                                "https://app.9917.cn/cert/embedded.mobileprovision";
                            }, 2500);
                          }}
                        >
                          点击安装
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="desktop-tips">
                      <div className="container">
                        <img
                          src={`/resources/desktop-tips-1${
                            desktopTipsBranch ? `-${desktopTipsBranch}` : ""
                          }.png`}
                          alt=""
                          className="img1"
                        />
                        <img
                          src="/resources/desktop-tips-2.png"
                          alt=""
                          className="img2"
                        />
                        <div className="body">
                          <div className="icon">
                            <Image src={app.appicon} alt="" />
                          </div>
                          <div className="info">
                            <div className="name">{app.appname}</div>
                            <div className="url">{window.location.href}</div>
                          </div>
                        </div>
                        <img
                          src="/resources/desktop-tips-3.png"
                          alt=""
                          className="img3"
                        />
                        <img
                          src="/resources/desktop-tips-4.png"
                          alt=""
                          className="img4"
                        />
                        <div className="text4">
                          回到手机桌面，点击【<span>{app.appname}</span>
                          】即可进入
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )
        ) : null} */}

        {!window.navigator.standalone &&
        prod &&
        (mobileDetect.is("iPhone") ||
          mobileDetect.is("iPad") ||
          navigator.userAgent == "app") &&
        window.location.hostname !== "localhost" &&
        !window.location.hostname.startsWith("192.168") &&
        navs.length === 0 &&
        !standalone ? (
          <>
            {!/Safari\/[^\s]+$/i.test(navigator.userAgent) && toSafariCopy ? (
              <>
                <div className="to-safari-copy">
                  <div className="container">
                    {app.brand == "btwan" ? (
                      <>
                        <Image
                          className="img"
                          src="/resources/to-safari-copy-btwan.png"
                        />
                      </>
                    ) : (
                      <>
                        {app.brand == "hiwan" ? (
                          <>
                            <Image
                              className="img"
                              src="/resources/to-safari-copy-hiwan.png"
                            />
                          </>
                        ) : (
                          <>
                            <Image
                              className="img"
                              src="/resources/to-safari-copy-9917.png"
                            />
                          </>
                        )}
                      </>
                    )}
                    <div
                      className="close"
                      onClick={() => {
                        setToSafariCopy(false);
                        setActivity(true);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {params.mc ? (
                  <div className="mobileconfig-tips">
                    <div className="container">
                      <img src="/resources/mobileconfig.png" alt="" />
                      <a
                        href={URI("//app.9917.cn/webbox.php?")
                          .addQuery("url", window.location.href)
                          .addQuery("name", app.appname)
                          .addQuery("icon", app.appicon)}
                        onClick={() => {
                          setTimeout(() => {
                            window.location.href =
                              "https://app.9917.cn/cert/embedded.mobileprovision";
                          }, 2500);
                        }}
                      >
                        点击安装
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="desktop-tips">
                    <div className="container">
                      <img
                        src={`/resources/desktop-tips-1${
                          desktopTipsBranch ? `-${desktopTipsBranch}` : ""
                        }.png`}
                        alt=""
                        className="img1"
                      />
                      <img
                        src="/resources/desktop-tips-2.png"
                        alt=""
                        className="img2"
                      />
                      <div className="body">
                        <div className="icon">
                          <Image src={app.appicon} alt="" />
                        </div>
                        <div className="info">
                          <div className="name">{app.appname}</div>
                          <div className="url">{window.location.href}</div>
                        </div>
                      </div>
                      <img
                        src="/resources/desktop-tips-3.png"
                        alt=""
                        className="img3"
                      />
                      <img
                        src="/resources/desktop-tips-4.png"
                        alt=""
                        className="img4"
                      />
                      <div className="text4">
                        回到手机桌面，点击【<span>{app.appname}</span>
                        】即可进入
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : null}

        <div className={"screen-index " + (navs.length === 0 ? "active" : "")}>
          <div className="screen-body">
            {screenRouter.map((item) => (
              <div
                className={
                  "screen-body-item " +
                  item.id +
                  " " +
                  (location.pathname === item.path ? "active" : "")
                }
                key={item.id}
              >
                <ActiveContext.Provider
                  value={navs.length === 0 && location.pathname === item.path}
                >
                  <item.component
                    active={
                      navs.length === 0 && location.pathname === item.path
                    }
                    action={action}
                  />
                </ActiveContext.Provider>
              </div>
            ))}
          </div>
          <div className="screen-menu width" key={location.pathname}>
            <div className="screen-menu-container">
              {screenRouter.map((item) => {
                let Tag = item.user ? NavLink : Link;
                return (
                  <Tag
                    key={item.id}
                    to={item.path}
                    replace
                    className={
                      "screen-menu-item " +
                      (location.pathname === item.path ? "active" : "")
                    }
                  >
                    <div
                      className="icon icon-dark"
                      style={{
                        backgroundImage:
                          "url(/resources/screen/" + item.id + "-dark.png)",
                      }}
                    />
                    <div
                      className="icon icon-highlight"
                      style={{
                        backgroundImage:
                          "url(/resources/screen/" +
                          item.id +
                          "-highlight.png)",
                      }}
                    />
                    <div
                      className="icon icon-white"
                      style={{
                        backgroundImage:
                          "url(/resources/screen/" + item.id + "-white.png)",
                      }}
                    />
                    <div className="text">{item.title}</div>
                  </Tag>
                );
              })}
            </div>
          </div>
        </div>
        {navs.map((nav, index) => (
          <NavView key={index} {...nav} />
        ))}
      </div>
      {/*{guide ? <Guide/> : null}*/}
      {/*<Getted/>*/}
    </>
  );
};
