import React, { Fragment, useEffect, useState } from "react";
import general from "../../../general";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import $ from "jquery";
import { useScroll } from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { PlayCircleOutlined } from "@ant-design/icons";

export default (props) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [gameGroups, setGameGroups] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/newGames", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        let tmp = parms.page === 1 || !parms.page ? [] : gameGroups;
        result.list.forEach((item) => {
          let index = tmp.findIndex((value) => value.text == item.text);
          if (index == -1) {
            index = tmp.length;
            tmp.push({ text: item.group, games: [] });
          }
          tmp[index].games = [...tmp[index].games, ...item.list];
        });
        setGameGroups(tmp);
        setMore(result.paginated.more);
      })
      .catch((error) => {
        Dialog.error(error.message);
      });
  };
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  return (
    <div>
      {gameGroups && gameGroups.length > 0 && (
        <div className="new-new">
          {gameGroups.map((gameGroup, index) => (
            <Fragment key={index}>
              <dl>
                <dt>
                  {new Map([
                    [dayjs().format("YYYY-MM-DD"), "今日首发"],
                    [dayjs().subtract(1, "d").format("YYYY-MM-DD"), "昨日首发"],
                  ]).get(gameGroup.text) || gameGroup.text}
                </dt>
                <dd>
                  {gameGroup.games.map((game) => (
                    <div key={game.game_id} className="new-new-item">
                      <Link className="data" to={"/game?id=" + game.game_id}>
                        <div className="icon">
                          <Image src={game.game_image?.thumb} />
                          {game.discount < 1 && (
                            <div className="discount">
                              {game.discount * 10}折
                            </div>
                          )}
                        </div>
                        <div className="info">
                          <div className="name">
                            <div className="name-left">
                              <span>
                                {game.game_name_main || game.game_name}
                              </span>
                              {game.game_name_branch ? (
                                <span className="versions">
                                  {game.game_name_branch}
                                </span>
                              ) : null}
                            </div>
                            <div className="opt">
                              <div className="time">{game.start_time}</div>
                            </div>
                          </div>
                          <div className="desc">
                            {game.game_classify_type}
                            &nbsp;·&nbsp;
                            {game.gama_size?.ios_size}
                          </div>
                          <div className="tags">
                            {game.game_desc?.split("+").map((item, index) => (
                              <div key={index} className="tag">
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Link>
                      <Link
                        to={
                          game.video_url
                            ? `/video?url=${game.video_url}`
                            : `/game?id=${game.game_id}`
                        }
                        className="media"
                      >
                        <div className="container">
                          <Image
                            src={
                              game.banner_url ||
                              game.game_ur_list[0] ||
                              game.video_img_url
                            }
                            alt=""
                          />
                          {game.video_url ? (
                            <PlayCircleOutlined className="play" />
                          ) : null}
                        </div>
                      </Link>
                    </div>
                  ))}
                </dd>
              </dl>
            </Fragment>
          ))}
        </div>
      )}

      {gameGroups.length > 0 && !more ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}
    </div>
  );
};
