import { useEffect, useState, useRef } from "react";
import { useLockFn, useScroll, useMount, useSize } from "ahooks";
import $ from "jquery";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { CSSTransition } from "react-transition-group";
import { RightOutlined } from "@ant-design/icons";
import { sysUrl } from "../../../config";
import { NavLink } from "../../../lib/Navigation";
import {
  QuestionCircleOutlined,
  FormOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import useActiveEffect from "../../../lib/useActiveEffect";
import { useHistory } from "react-router-dom";

export default (props) => {
  const [isShow, setIsShow] = useState(false);
  const [isNew, setIsNew] = useState(1);
  let [usableNum, setUsableNum] = useState(0);
  const [tipsShow, setTipsShow] = useState(false);

  const history = useHistory();

  // 是否为新人
  const [freeHour, setFreeHour] = useState(4);
  const getIsNew = () => {
    general.axios
      .post("/base/CloudHangingUp/checkCloudHangingUpNew")
      .then(({ data: resData }) => {
        console.log("是否为新人----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          setTimeout(() => {
            history.push("/screen-user");
          }, 2500);
          return;
        }

        setIsNew(data.is_new);
        setFreeHour(data.free_hour);

        // 若不是新人，再获取用户是否有可用设备
        if (!data.is_new) {
          getHaveEquipment();
        } else {
          setIsShow(true);
        }
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 是否有可用设备
  const getHaveEquipment = () => {
    Dialog.loading();

    general.axios
      .post("/base/CloudHangingUp/checkCloudHangingUpUsable")
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("是否有可用设备----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        usableNum = data.usable_num;
        setUsableNum(data.usable_num);

        // 若是有可用设备，再获取用户的设备列表
        if (usableNum > 0) {
          getMyEquipment();
        } else {
          setIsShow(true);
        }
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 获取我的设备列表
  let [equipmentList, setEquipmentList] = useState([]);
  let [equipmentInfo, setEquipmentInfo] = useState({});
  const updateData = (data) => {
    equipmentInfo = data;
    setEquipmentInfo(data);

    props.setOperate(
      <SelectEqu equipmentInfo={equipmentInfo} updateData={updateData} />
    );
  };
  const SelectEqu = ({ equipmentInfo, updateData }) => {
    // 选择设备后  获取该设备的详细信息
    const selEquipment = (item) => {
      $(".sel-equ").siblings(".all-equipment").slideUp();
      $(".sel-equ").removeClass("slideTogExchange");

      Dialog.loading();

      general.axios
        .post("/base/CloudHangingUp/getCloudHangingUpUsableInfo", {
          equipment_id: item.id,
        })
        .finally(() => {
          Dialog.close();
        })
        .then(({ data: resData }) => {
          console.log("获取设备详细信息----", resData);

          let { status, data } = resData;
          if (!status.succeed) {
            return Dialog.error(status.error_desc);
          }

          updateData(data);
        })
        .catch((err) => {
          //Dialog.error('getTradeList ' + err.message);
        });
    };

    // 下拉列表 ref
    const dropdownRef = useRef(null);

    useEffect(() => {
      //实现点击 本元素外的元素时，隐藏下拉列表（点击其他地方隐藏下拉列表）
      function handleOutsideClick(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          var hasClass = $(".sel-equ").hasClass("slideTogExchange");

          if (hasClass) {
            $(".sel-equ").siblings(".all-equipment").slideUp();
            $(".sel-equ").removeClass("slideTogExchange");
          }
        }
      }
      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

    return (
      <div className="my-equipment-list">
        <div className="sel-equ" onClick={unfoldEqu}>
          <div className="equ-name">{equipmentInfo.equipment_name}</div>

          <img src="/resources/hangup/caret-bottom.png" />
        </div>
        <div
          className="all-equipment"
          ref={dropdownRef}
          style={{ display: "none" }}
        >
          {equipmentList.map((item) => (
            <div
              key={item.id}
              className={
                "all-every " + (equipmentInfo.id == item.id ? "active" : "")
              }
              onClick={() => {
                selEquipment(item);
              }}
            >
              {item.equipment_name}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const getMyEquipment = () => {
    Dialog.loading();

    general.axios
      .post("/base/CloudHangingUp/getCloudHangingUp")
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("我的设备列表----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        setIsShow(true);
        equipmentList = data.list;
        setEquipmentList(data.list);
        equipmentInfo = data.userCloudHangingUpInfo;
        setEquipmentInfo(data.userCloudHangingUpInfo);

        console.log(equipmentInfo, equipmentList);
        // 右上角渲染设备列表
        props.setOperate(
          <SelectEqu equipmentInfo={equipmentInfo} updateData={updateData} />
        );
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 展开/收起下拉框
  const unfoldEqu = (event) => {
    event.stopPropagation(); // 阻止事件冒泡

    var hasClass = $(".sel-equ").hasClass("slideTogExchange");
    // console.log($(this));
    if (hasClass) {
      $(".sel-equ").siblings(".all-equipment").slideUp();
      $(".sel-equ").removeClass("slideTogExchange");
    } else {
      $(".sel-equ").siblings(".all-equipment").slideDown();
      $(".sel-equ").addClass("slideTogExchange");
    }
  };

  // 获取云挂机游戏列表
  const [loading, setLoading] = useState();
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  // 弹窗的高度 80vh
  const noGameRef = useRef(null);
  const sizeMain = useSize(noGameRef);
  const scroll = useScroll(noGameRef);

  // 弹窗 滚动的主体 高度是所有游戏列表渲染后的实、容器高度
  const scrollRef = useRef(null);
  const sizeNoHeight = useSize(scrollRef);
  let [scrollDir, setScrollDir] = useState(0);
  useEffect(() => {
    if (props.active && init) {
      if (scroll?.top + sizeMain?.height >= sizeNoHeight?.height && more) {
        console.log("1111111111111", scroll?.top, scrollDir);
        if (scroll?.top > scrollDir) {
          // alert(1111);
          getHangupGame({ page: page + 1 });
        }
      }
    }
  }, [scroll]);
  const notHeadRef = useRef(null);
  const sizeNotBody = useSize(notHeadRef);
  const [jurisdictionShow, setJurisdictionShow] = useState(false);
  let [keyword, setKeyword] = useState("");

  // 搜索框change事件 开始搜索
  const checkData = (num) => {
    noGameRef.current.scrollTop = 0;
    scroll.top = 0;
    setMore(true);
    scrollDir = 0;
    setScrollDir(0);
    setHangupGameList([]);

    if (num) {
      getHangupGame({ page: 1 });
    }
  };
  const [isEmpty, setIsEmpty] = useState(true);
  const [hangupGameList, setHangupGameList] = useState([]);
  const getHangupGame = useLockFn(async (parms = {}) => {
    // 在这里执行输入事件的处理逻辑
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      page,
      pageSize: 20,
      game_name: keyword,
    };
    if (parms.page) {
      tmp.page = parms.page;
    }

    console.log("keyword---------", keyword);

    await general.axios
      .post("/base/CloudHangingUp/getCloudHangingUpGames", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((res) => {
        let { status, data } = res.data;
        console.log("云挂机游戏列表", data);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);

        scrollDir = scroll?.top || 0;
        setScrollDir(scroll?.top || 0);

        if (parms.page) {
          setPage(parms.page);
        }
        let { list } = data;
        console.log("--------", list);
        setHangupGameList((datas) =>
          parms.page === 1 || !parms.page ? list : [...datas, ...list]
        );

        setMore(list.length > 0);
      })
      .catch((err) => {
        Dialog.error("unusableFlb " + err.message);
      });
    // }, 500);
  });

  useActiveEffect(() => {
    console.log("到我啦啦啦啦啦啦222222222222222");
    getIsNew();
  });

  // 新人确认使用
  const newUseSure = () => {
    general.axios
      .post("/base/CloudHangingUp/freeTrialCloudHangingUpNew")
      .finally(() => {
        setTipsShow(false);
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("是否有可用设备----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        // 获取可用设备
        getHaveEquipment();
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 获取设备详情
  let canClick = false;
  const getEquipmentDetail = () => {
    if (canClick) return;

    canClick = true;
    Dialog.loading();
    general.axios
      .post("/base/CloudHangingUp/getCloudHangingUpUsableInfo", {
        equipment_id: equipmentInfo.id,
      })
      .finally(() => {
        Dialog.close();
        canClick = false;
      })
      .then(({ data: resData }) => {
        console.log("获取设备详细信息----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        equipmentInfo = data;
        setEquipmentInfo(data);
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 修改设备名称
  const [updateName, setUpdateName] = useState(false);
  let [euqipmentName, setEuqipmentName] = useState("");
  useEffect(() => {
    euqipmentName = equipmentInfo.equipment_name;
    setEuqipmentName(equipmentInfo.equipment_name);
  }, [equipmentInfo]);
  const updateNameSure = () => {
    let regex = /^[\w\u4E00-\u9FA5&]{0,10}$/gi;

    if (!regex.test(euqipmentName)) {
      return Dialog.error("20个字符以内(不能含特殊字符)");
    }

    Dialog.loading();
    general.axios
      .post("/base/CloudHangingUp/editCloudHangingUpName", {
        equipment_id: equipmentInfo.id,
        equipment_name: euqipmentName,
      })
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("修改设备名称----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        setUpdateName(false);
        Dialog.success("设备名称修改成功");

        setTimeout(() => {
          getMyEquipment();
        }, 1500);
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  // 退出挂机
  const [quitShow, setQuitShow] = useState(false);
  const quitHangup = () => {
    Dialog.loading();

    general.axios
      .post("/base/CloudHangingUp/stopCloudHangingUp", {
        equipment_id: equipmentInfo.id,
        record_id: equipmentInfo.record_id,
      })
      .finally(() => {
        Dialog.close();
      })
      .then(({ data: resData }) => {
        console.log("退出挂机----", resData);

        let { status, data } = resData;
        if (!status.succeed) {
          return Dialog.error(status.error_desc);
        }

        setQuitShow(false);
        getMyEquipment();
      })
      .catch((err) => {
        //Dialog.error('getTradeList ' + err.message);
      });
  };

  return (
    <>
      {isShow && (
        <div className="game-hangup">
          {usableNum < 1 ? (
            <div className="no-unable-hangup">
              {/* 没有可用设备 */}
              <div
                onClick={() => {
                  setMore(true);
                  keyword = "";
                  setKeyword("");
                  scrollDir = 0;
                  setScrollDir(0);

                  setIsEmpty(true);
                  setHangupGameList([]);
                  getHangupGame({ page: 1 });
                  setJurisdictionShow(true);
                }}
              >
                <p className="title">云挂机可以持续不掉线</p>
                <p className="title">并支持多开哦</p>
                <p className="tips">点击此处查看支持云挂机的游戏</p>
                <div className="character">
                  <img src="/resources/hangup/title-left.png" alt="" />
                  <span>功能特性</span>
                  <img src="/resources/hangup/title-right.png" alt="" />
                </div>
                <div className="character-every">
                  <img src="/resources/hangup/hours.png" />
                  <div>
                    <p className="every-title">24小时在线</p>
                    <p className="every-tips">锁屏也能持续运行</p>
                  </div>
                </div>
                <div className="character-every">
                  <img src="/resources/hangup/interspace.png" />
                  <div>
                    <p className="every-title">不占手机空间</p>
                    <p className="every-tips">没有存储限制</p>
                  </div>
                </div>
              </div>

              {isNew ? (
                <div
                  className="hangup-btn"
                  onClick={() => {
                    setTipsShow(true);
                  }}
                >
                  新人免费使用 ({freeHour}小时)
                </div>
              ) : (
                <NavLink
                  className="hangup-btn"
                  style={{ display: "block" }}
                  user={true}
                  to={`${sysUrl}/home/actcloudhook/renew`}
                  iframe={{ title: "购买云挂机" }}
                >
                  购买设备
                </NavLink>
              )}

              <NavLink
                className="check-btn"
                style={{ display: "block" }}
                user={false}
                to={`${sysUrl}/home/actcloudhook/faq`}
                iframe={{ title: "云挂机FAQ" }}
              >
                查看教程 <RightOutlined />
              </NavLink>
            </div>
          ) : (
            <div className="unable-hangup">
              {/* 有可用设备 */}
              <div className="unable-container">
                <div
                  className="unable-main"
                  style={{
                    // 设备挂机中  无错误 有图片
                    backgroundImage:
                      equipmentInfo.status == 2 &&
                      !equipmentInfo.screenshot.error_code &&
                      equipmentInfo.screenshot.image
                        ? `url(${equipmentInfo.screenshot.image})`
                        : "none",
                  }}
                >
                  <div
                    className="main-top"
                    style={{
                      // 设备挂机中 无错误 有图片
                      background:
                        equipmentInfo.status == 2 &&
                        !equipmentInfo.screenshot.error_code &&
                        equipmentInfo.screenshot.image
                          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.5) 65%, rgba(0, 0, 0, 0.3), rgba(240, 235, 235, 0.12156862745098039))`
                          : "transparent",
                    }}
                  >
                    <div
                      className="main-equipment-info"
                      style={{
                        color: equipmentInfo.status == 1 ? "#333333" : "#fff",
                      }}
                    >
                      <div className="info-name">
                        <span>{equipmentInfo.equipment_name}</span>
                        <FormOutlined
                          style={{
                            color: equipmentInfo.status == 1 ? "#666" : "#fff",
                          }}
                          onClick={() => {
                            euqipmentName = equipmentInfo.equipment_name;
                            setEuqipmentName(equipmentInfo.equipment_name);
                            setUpdateName(true);
                          }}
                        />
                      </div>
                      <div className="time">
                        到期时间：
                        {equipmentInfo.expiration_time &&
                          dayjs
                            .unix(equipmentInfo.expiration_time)
                            .format("YYYY-MM-DD HH:mm")}
                      </div>

                      {equipmentInfo.status == 2 && (
                        <div className="time">
                          游戏名称：
                          {equipmentInfo?.game_info?.name?.split("-")[0]}
                          {equipmentInfo?.game_info?.name?.split("-")[1] &&
                            ` (${
                              equipmentInfo?.game_info?.name?.split("-")[1]
                            })`}
                        </div>
                      )}

                      <div
                        className={
                          "status " +
                          (equipmentInfo.status == 1 ? "green" : "red")
                        }
                      >
                        {equipmentInfo.status == 1
                          ? "闲置中"
                          : equipmentInfo.status == 2
                          ? "挂机中"
                          : ""}
                      </div>
                    </div>
                    <NavLink
                      user={false}
                      to={`${sysUrl}/home/actcloudhook/faq`}
                      iframe={{ title: "云挂机FAQ" }}
                    >
                      <QuestionCircleOutlined
                        className={
                          "faq " + (equipmentInfo.status == 2 && "white")
                        }
                      />
                    </NavLink>
                  </div>

                  {equipmentInfo.status == 1 ? (
                    <div
                      className="main-no-game"
                      onClick={() => {
                        setMore(true);
                        keyword = "";
                        setKeyword("");
                        scrollDir = 0;
                        setScrollDir(0);

                        setIsEmpty(true);
                        setHangupGameList([]);
                        getHangupGame({ page: 1 });
                        setJurisdictionShow(true);
                      }}
                    >
                      <img src="/resources/hangup/game.png" />
                      <p className="title">暂无启动游戏</p>
                      <p className="sel-btn">选择云挂机游戏</p>
                    </div>
                  ) : (
                    <NavLink
                      className="main-no-game"
                      user={true}
                      to={`${sysUrl}/home/actcloudhook?maiyouid=${equipmentInfo?.game_info?.maiyou_gameid}&equid=${equipmentInfo.id}&yid=${equipmentInfo?.game_info?.ys_id}&rid=${equipmentInfo.record_id}&origin=react`}
                      iframe={{ title: "云挂机" }}
                      // , topHidden: true
                    >
                      <div
                        onClick={() => {
                          setMore(true);
                          keyword = "";
                          setKeyword("");
                          scrollDir = 0;
                          setScrollDir(0);

                          setIsEmpty(true);
                          setHangupGameList([]);
                          getHangupGame({ page: 1 });
                          setJurisdictionShow(true);
                        }}
                      ></div>
                    </NavLink>
                  )}
                </div>

                <div className="unable-btns">
                  <div
                    className="btns-every"
                    onClick={() => {
                      getEquipmentDetail();
                    }}
                  >
                    <img src="/resources/hangup/refresh.png" />
                    刷新
                  </div>
                  <div className="line"></div>
                  <NavLink
                    user={true}
                    to={`${sysUrl}/home/actcloudhook/renew?equId=${equipmentInfo.id}`}
                    iframe={{ title: "续费云挂机" }}
                  >
                    <div className="btns-every">
                      <img src="/resources/hangup/renew.png" />
                      续费
                    </div>
                  </NavLink>

                  {equipmentInfo.status == 2 && (
                    <>
                      <div className="line"></div>
                      <div
                        className="btns-every"
                        onClick={() => {
                          setQuitShow(true);
                        }}
                      >
                        <img src="/resources/hangup/back.png" />
                        退出挂机
                      </div>
                    </>
                  )}
                </div>
              </div>

              <NavLink
                className="unable-big-btn hangup-btn"
                style={{ display: "block" }}
                user={true}
                to={`${sysUrl}/home/actcloudhook/renew`}
                iframe={{ title: "购买云挂机" }}
              >
                <PlusOutlined /> 购买设备
              </NavLink>
            </div>
          )}

          {/* 新人 */}
          <CSSTransition in={tipsShow} timeout={250} unmountOnExit={true}>
            <div className="dialog-common-content">
              <div
                className="dialog-mask"
                onClick={() => {
                  setTipsShow(false);
                }}
              />
              <div className="dialog-main">
                <div className="dialog-top">
                  <div className="down-title" style={{ paddingLeft: "46px" }}>
                    温馨提示
                  </div>
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src="/resources/game/close.png"
                    alt=""
                    onClick={() => {
                      setTipsShow(false);
                    }}
                  />
                </div>

                <div className="dialog-content">
                  <p>
                    新人可以免费试用{freeHour}
                    小时的云挂机功能，确认后即开始生效，不管是否使用，到时间后都会过期，是否确认开始?
                  </p>

                  <div className="hangup-btn" onClick={newUseSure}>
                    确认
                  </div>
                  <div
                    className="cancel-btn"
                    onClick={() => {
                      setTipsShow(false);
                    }}
                  >
                    取消
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>

          {/* 游戏列表 */}
          <CSSTransition
            in={jurisdictionShow}
            timeout={250}
            unmountOnExit={true}
          >
            <div className="flb-not-content">
              <div
                className="server-mask"
                onClick={() => {
                  setJurisdictionShow(false);
                }}
              />
              <div
                className="server-main"
                ref={noGameRef}
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "16px",
                    overflow: "auto",
                  }}
                  ref={scrollRef}
                >
                  <div
                    className="gift-top"
                    ref={notHeadRef}
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    <div className="down-title">
                      <span className="title">选择游戏</span>
                      <img
                        className="close"
                        src="/resources/game/close.png"
                        alt=""
                        onClick={() => {
                          setJurisdictionShow(false);
                        }}
                      />
                    </div>
                    <div className="not-game-input">
                      <div
                        className="flb-search-input"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <img
                          src="/resources/comment/search.png"
                          alt=""
                          className="icon"
                        />
                        <input
                          style={{ backgroundColor: "#fff" }}
                          type="text"
                          value={keyword}
                          placeholder={"请输入游戏名"}
                          onChange={(e) => {
                            keyword = e.target.value;
                            setKeyword(e.target.value);
                          }}
                          onInput={(e) => {
                            if (e.target.value == "" && !isEmpty) {
                              setIsEmpty(true);
                              keyword = "";
                              setKeyword("");
                              console.log("nonennnnnnnnnnnnnnnn");
                              checkData(1);
                            }
                          }}
                          onCompositionEnd={(e) => {
                            setIsEmpty(false);
                            console.log(
                              "end----dddddddddd-----",
                              e.target.value
                            );
                            keyword = e.target.value;
                            setKeyword(e.target.value);
                            console.log("kkkkkkkkkkkkkkkk----", keyword);
                            checkData(1);
                          }}
                        />
                        {keyword ? (
                          <CloseOutlined
                            className="clear"
                            onClick={() => {
                              keyword = "";
                              setKeyword("");
                              setIsEmpty(true);
                              checkData(1);
                            }}
                          />
                        ) : null}
                      </div>
                      <div
                        className="btn"
                        onClick={() => {
                          setIsEmpty(true);
                          checkData(1);
                        }}
                      >
                        搜索
                      </div>
                    </div>
                  </div>

                  {hangupGameList && hangupGameList.length > 0 ? (
                    <div style={{ paddingTop: sizeNotBody?.height || "111px" }}>
                      {hangupGameList.map((game) => (
                        <div
                          key={game.id}
                          className="game-list-global-type"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flex: 1,
                            }}
                          >
                            <div className="icon">
                              <Image src={game.logo} />
                              {game.discount && game.discount < 1 && (
                                <div
                                  className="discount"
                                  style={{
                                    width: "fit-content",
                                    height: "auto",
                                    lineHeight: "normal",
                                    padding: "0 3px",
                                    top: 0,
                                    borderRadius: "0px 8px 0px 10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {game.discount * 10}
                                  </span>
                                  <span style={{ fontSize: "0.16rem" }}>
                                    折
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="data">
                              <div className="name">
                                <div className="name-main">
                                  {game?.gamename}
                                </div>
                                {game?.game_name_branch && (
                                  <div className="branch">
                                    {game?.game_name_branch}
                                  </div>
                                )}
                              </div>
                              <div className="desc">{game.version}</div>
                            </div>
                          </div>

                          <NavLink
                            user={true}
                            onClick={(e) => {
                              if (usableNum < 1) {
                                Dialog.error("暂无设备");

                                e.preventDefault();
                                e.stopPropagation();
                                return;
                              } else {
                                Dialog.loading();
                                setTimeout(() => {
                                  setJurisdictionShow(false);
                                  Dialog.close();
                                }, 1000);
                              }
                            }}
                            to={`${sysUrl}/home/actcloudhook?maiyouid=${game.maiyou_gameid}&equid=${equipmentInfo.id}&yid=${game.ys_id}&rid=${equipmentInfo.record_id}&origin=react`}
                            iframe={{ title: "云挂机" }}
                            // , topHidden: true
                          >
                            <div className="hangup-small-btn">云挂机</div>
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingTop: sizeNotBody?.height + 99 || "211px",
                      }}
                      className="no-data"
                    >
                      未搜索到该游戏~
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>

          {/* 修改设备名称 */}
          <CSSTransition in={updateName} timeout={250} unmountOnExit={true}>
            <div className="dialog-common-content">
              <div
                className="dialog-mask"
                onClick={() => {
                  setUpdateName(false);
                }}
              />
              <div className="dialog-main">
                <div className="dialog-top">
                  <div className="down-title">修改设备名称</div>
                </div>

                <div className="dialog-content">
                  <div className="update-euq-name">
                    <input
                      placeholder="请输入设备名称"
                      maxLength={10}
                      value={euqipmentName}
                      onChange={(e) => {
                        console.log(e.target.value);

                        let value = e.target.value;

                        euqipmentName = value;
                        setEuqipmentName(value);
                      }}
                    />
                    <p>10个以内中文、英文和数字</p>
                  </div>

                  <div style={{ padding: "0 44px" }}>
                    <div className="hangup-btn" onClick={updateNameSure}>
                      确认
                    </div>
                    <div
                      className="cancel-btn"
                      onClick={() => {
                        setUpdateName(false);
                      }}
                    >
                      取消
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>

          {/* 退出挂机 */}
          <CSSTransition in={quitShow} timeout={250} unmountOnExit={true}>
            <div className="dialog-common-content">
              <div
                className="dialog-mask"
                onClick={() => {
                  setQuitShow(false);
                }}
              />
              <div className="dialog-main">
                <div className="dialog-top">
                  <div className="down-title" style={{ paddingLeft: "46px" }}>
                    温馨提示
                  </div>
                </div>

                <div className="dialog-content">
                  <p>
                    退出挂机会导致云手机中的游戏缓存数据清空，相关游戏挂机设置会重置，是否确认退出当前挂机游戏？
                  </p>

                  <div className="hangup-btn" onClick={quitHangup}>
                    确认
                  </div>
                  <div
                    className="cancel-btn"
                    onClick={() => {
                      setQuitShow(false);
                    }}
                  >
                    取消
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      )}
    </>
  );
};
