import useActiveState from "../../lib/useActiveState";
import { useEffect, useRef, useState } from "react";
import { sysUrl } from "../../config";

export default (props) => {
  const [app, setApp] = useActiveState("app");
  const [user, setUser] = useActiveState("user");
  const [load, setLoad] = useState();
  const ref = useRef(null);
  useEffect(() => {
    props.active && setLoad(true);
    if (props.active && ref.current) {
      ref.current.contentWindow.postMessage("onenter", "*");
    }
  }, [props.active]);

  return load ? (
    <div className="welfare-index">
      <iframe
        src={`${sysUrl}/home/welfare?username=${localStorage.getItem(
          "username"
        )}&token=${localStorage.getItem("token")}&brand=${app.brand}`}
        ref={ref}
      />
    </div>
  ) : null;
};
