import React, { useEffect, useState } from "react";
import general from "../../general";
import { CloseOutlined } from "@ant-design/icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useScroll, useThrottleEffect } from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";

// 搜索结果展示
export default ({ active, kw, refresh, gameList }) => {
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  let [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      game_species_type: 0,
      is_search: true,
      search_info: kw,
      pagination: { page: page },
    };
    if (parms.page) {
      tmp.pagination = { page: parms.page };
    }
    general.axios
      .post("/base/game/getGameList", tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then((response) => {
        setInit(true);
        let result = response.data.data;
        if (parms.page) {
          setPage(parms.page);
        }
        let datas = result.game_list || [];

        games = parms.page === 1 || !parms.page ? datas : [...games, ...datas];
        setGames((games) =>
          parms.page === 1 || !parms.page ? datas : [...games, ...datas]
        );
        if (games.length > 0) {
          haveResult = true;
          setHaveResult(true);
        } else {
          haveResult = false;
          setHaveResult(false);
        }
        setMore(result.paginated.more);
      })
      .catch((err) => {
        Dialog.error("getGameList " + err.message);
      });
  };
  useThrottleEffect(
    () => {
      if (kw) {
        load({ page: 1 });
      }
    },
    [kw, refresh],
    { wait: 500 }
  );

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll?.top < -80) {
        load({ page: 1 });
      }
      if (
        scroll?.top + window.innerHeight + 20 > $(document).height() &&
        more
      ) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, active]);

  let [applyName, setApplyName] = useState("");
  const [applyShow, setApplyShow] = useState(false);
  // 提交心仪游戏
  const submitLikeGame = () => {
    if (!applyName) {
      Dialog.error("请输入游戏名称");
      return;
    }

    general.axios
      .post("/base/game/searchAdd", {
        gamename: applyName,
      })
      .then((res) => {
        setApplyShow(false);

        let { status, data } = res.data;
        console.log("提交心仪游戏结果", data);
        if (!status.succeed) {
          return;
        }

        Dialog.success("提交成功，请随时关注游戏更新情况");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  let [haveResult, setHaveResult] = useState(false);

  return (
    <div className={"search-result " + (kw ? "active" : "")}>
      {games.map((game) => (
        <Link
          key={game.game_id}
          to={"/game?id=" + game.game_id}
          className="game-list-global-type"
        >
          {game.discount < 1 ? (
            <div className="discount">
              <span style={{ fontSize: "0.28rem", fontWeight: "bold" }}>
                {game.discount * 10}
              </span>
              折
            </div>
          ) : null}
          <div className="icon">
            <Image src={game.game_image?.thumb} />
          </div>
          <div className="data">
            <div className="name">
              <div className="name-main" style={{ flex: "none" }}>
                {game.game_name_main || game.game_name}
              </div>
              {game.game_name_branch ? (
                <div className="branch">{game.game_name_branch}</div>
              ) : null}
            </div>
            <div className="desc">
              {game.game_classify_type}
              <div className="line"></div>
              {game.gama_size?.ios_size}
              {/* {game.played_count && game.played_count > 0 ? (
                <div className="info" style={{ marginTop: 0 }}>
                  <div className="circle"></div>
                  {game.played_count}w人在玩
                </div>
              ) : null} */}
            </div>
            <div className="info">
              {game.game_desc
                ? game.game_desc?.split("+").map((item, index) => (
                    <div key={index} className="tag">
                      {item}
                    </div>
                  ))
                : game.introduction}
            </div>
          </div>
        </Link>
      ))}

      {games.length === 0 && init ? (
        <>
          <div className="search-empty">
            "{kw}"搜索无结果 ，推荐
            <br />
            试玩下方推荐游戏
          </div>
          <div>
            {gameList.length > 0 &&
              gameList.map((game) => (
                <Link
                  key={game.game_id}
                  to={"/game?id=" + game.game_id}
                  className="game-list-global-type"
                >
                  {game.discount < 1 ? (
                    <div className="discount">
                      <span style={{ fontSize: "0.28rem", fontWeight: "bold" }}>
                        {game.discount * 10}
                      </span>
                      <span className="discount-font">折</span>
                    </div>
                  ) : null}
                  <div className="icon">
                    <Image src={game?.game_image?.thumb} />
                  </div>
                  <div className="data">
                    <div className="name">
                      <div className="name-main" style={{ flex: "none" }}>
                        {game.game_name_main || game.game_name}
                      </div>
                      {game.game_name_branch ? (
                        <div className="branch">{game.game_name_branch}</div>
                      ) : null}
                    </div>
                    <div className="desc">
                      {game.game_classify_type}

                      <div className="line"></div>
                      {game.gama_size.ios_size}
                    </div>
                    <div className="info">
                      {game.game_desc
                        ? game.game_desc?.split("+").map((item, index) => (
                            <div key={index} className="tag">
                              {item}
                            </div>
                          ))
                        : game.introduction}
                    </div>
                  </div>
                </Link>
              ))}
          </div>

          <Link to="/?tab=top">
            <div className="ranking-font">
              <img
                src="/resources/classify/rankingList.png"
                alt=""
                className="icon"
              />
              <span>全网福利游戏排行榜&gt;</span>
            </div>
          </Link>
        </>
      ) : null}
      {games.length > 0 ? (
        <div className="view-end">别撩啦！已经到底啦~</div>
      ) : null}

      {kw && (
        <div
          className="apply-new-game"
          style={{
            position: haveResult ? "fixed" : "static",
            margin: haveResult ? "0 auto" : "0 auto 36px",
          }}
          onClick={() => {
            applyName = "";
            setApplyName("");
            setApplyShow(true);
          }}
        >
          <span className="font">申请新游戏</span>
          <img src="/resources/classify/right.png" alt="" className="icon" />
        </div>
      )}

      {applyShow && (
        <div className="apply-container">
          <div
            className="apply-mask"
            onClick={() => {
              setApplyShow(false);
            }}
          />

          <div className="apply-main">
            <div className="apply-title">告诉我们，你想要的</div>
            <div className="apply-game-dialog">
              <div className="label">游戏名称</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <input
                  className="apply-input"
                  type="text"
                  placeholder="请输入"
                  value={applyName}
                  onChange={(e) => {
                    applyName = e.target.value;
                    setApplyName(e.target.value);
                    console.log(applyName);
                  }}
                />

                {applyName && (
                  <CloseOutlined
                    style={{
                      fontSize: "16px",
                      color: "#999",
                      position: "absolute",
                      right: 0,
                      bottom: "3px",
                    }}
                    onClick={() => {
                      applyName = "";
                      setApplyName("");
                    }}
                  />
                )}
              </div>

              <div className="tips-font">
                输入心仪的游戏名称，我们会根据您的意见更新游戏
              </div>
            </div>
            <div className="apply-btn" onClick={submitLikeGame}>
              提交
            </div>
          </div>

          <div
            className="apply-close"
            onClick={() => {
              applyName = "";
              setApplyName("");
              setApplyShow(false);
            }}
          >
            <img src="/resources/game/close.png" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};
